<template>
    <div class="container">
        <RowDiv >
            <div class="col">
                <slot></slot>
            </div>
            <div class="col hidden-below-sm">
                <slot name="right"></slot>
            </div>
        </RowDiv>
    </div>
</template>
<style scoped>
:deep(h1) {
    margin-bottom: unset;
}

:deep(h3) {
    margin-top: .5rem;
}

:deep(.button) {
    width: max-content;
}

@media (max-width: 600px) {
    .hidden-below-sm {
        display: none;
    }
}
</style>