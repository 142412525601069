<template>
    <OnBoardingCard displayImage="/images/resized/image0.png" flexClass="flex" nextButtonName="Next" :stepIndex="1">
        <div class="h1 reg">
            Let's get to know each other!
        </div>
        <div class="h3 reg">
            We'd love to know stuff about you so we can set up your preferences
        </div>
        <RowDiv >
            <div class="col-12">
                <div class="b1 semi">Name</div>
                <div class="b2 reg">Enter your first and last name from your driver licence</div>
            </div>
            <div class="col">
                <InputComponent v-model="firstName" type="text" placeholder="First name" :validation="['required']"
                    @validation="handleKeyPress" />
            </div>
            <div class="col">
                <InputComponent v-model="lastName" type="text" placeholder="Last name" :validation="['required']"
                    @validation="handleKeyPress" />
            </div>
        </RowDiv>
        <RowDiv >
            <div class="col-12">
                <div class="b1 reg">
                    What would you like us to call you? (optional)
                </div>
            </div>
            <div class="col-12">
                <InputComponent icon-src="people/user" v-model="preferredName" type="text"
                    placeholder="Your preferred name" />
            </div>
        </RowDiv>
        <RowDiv >
            <div class="col-12">
                <div class="b1 semi">Phone number</div>
            </div>
            <div class="col-12">
                <PhoneNumberInput v-model="phoneNumber" drop="up" />
            </div>
        </RowDiv>
    </OnBoardingCard>
</template>
<script>
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            preferredName: '',
            phoneNumber: {
                country_code: '',
                phone_number: ''
            },
            preDialCodes: [],
        };
    },
    components: {
        OnBoardingCard, PhoneNumberInput
    },
    computed: {
        ...mapState(['onboarding'])
    },
    mounted() {
        this.firstName = this.onboarding?.first_name ?? '';
        this.lastName = this.onboarding?.last_name ?? '';
        this.preferredName = this.onboarding?.preferred_name ?? '';
        this.setNextButtonEnabled(this.firstName && this.lastName);
        this.$bus.on('onboarding-next', this.handleSubmit);
        this.phoneNumber = {
            country_code: this.onboarding?.country_code ?? '',
            phone_number: this.onboarding?.phone_number ?? '',
        }
    },
    watch: {
        phoneNumber: {
            handler(newVal) {
                this.setNextButtonEnabled(this.firstName && this.lastName && newVal.country_code && newVal.phone_number);
            },
            deep: true,
            immediate: true
        }
    },
    beforeRouteLeave() {
        this.$bus.off('onboarding-next', this.handleSubmit);
    },
    methods: {
        handleSubmit() {
            this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                first_name: this.firstName,
                last_name: this.lastName,
                preferred_name: this.preferredName,
                ...this.phoneNumber
            }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                this.$router.push('/onboarding/driver-details');
                this.setOnboarding({ key: 'first_name', value: this.firstName });
                this.setOnboarding({ key: 'last_name', value: this.lastName });
                if (this.preferredName) this.setOnboarding({ key: 'preferred_name', value: this.preferredName });
            }).catch(error => {
                console.error("An error occurred while logging in:", error);
            });
        },
        handleKeyPress(message) {
            if (message === '') {
                this.setNextButtonEnabled(this.firstName && this.lastName && this.phoneNumber.country_code && this.phoneNumber.phone_number);
            }
        },
        ...mapMutations(['setOnboarding', 'setNextButtonEnabled'])
    }
}
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css");
</style>
