<template>
    <HeroCard v-if="!isMobile">
        <div class="h0">Your Bookings</div>
        <div class="h3 reg">Experience sustainable travel</div>
        <template #right>
            <img src="/images/resized/Hero 1.png" alt="hero cars" />
        </template>
    </HeroCard>
    <!-- Upcoming booking -->
    <template v-for="upcoming in currentDetails" :key="upcoming">

        <UpcomingBooking :details="upcoming" @cancel="confirmCancel" @start="startBooking" />
    </template>
    <!-- Search Bar -->
    <SearchBar v-if="!isMobile" />
    <div class="container">
        <RowDiv v-if="isMobile">
            <div class="col">
                <BookingList :bookings="bookings" />
            </div>
            <div class="col-4" v-show="false" v-if="profile?.carpooling">
                <CarpoolList />
            </div>
        </RowDiv>
        <RowDiv v-else>
            <div class="col-4" v-if="profile?.carpooling">
                <CarpoolList />
            </div>
            <div class="col">
                <BookingList :bookings="bookings" />
            </div>
        </RowDiv>
    </div>
    <div v-show="popUpCancel" class="modal-overlay">
        <ConfirmModal @close="popUpCancel = false" class="popup" @confirm="cancelBooking">
            <CardDiv>
                <RowDiv>
                    <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                </RowDiv>
                <RowDiv>
                    <p class="b1 reg">Are you sure you want to cancel this booking?</p>
                </RowDiv>
            </CardDiv>
        </ConfirmModal>
    </div>
    <div v-show="endConfirm" class="modal-overlay">
        <ConfirmModal @close="endConfirm = false" class="popup" @confirm="endBooking">
            <CardDiv>
                <RowDiv>
                    <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                </RowDiv>
                <RowDiv>
                    <p class="b1 reg">Are you sure you want to end this booking?</p>
                </RowDiv>
            </CardDiv>
        </ConfirmModal>
    </div>
    <div v-show="isStarting" class="modal-overlay">
        <AlertModal @close="isStarting = false" class="popup">
            <CardDiv>
                <RowDiv>
                    <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                </RowDiv>
                <RowDiv>
                    <p class="b1 reg">Your booking has started.</p>
                    <p class="b3 reg">Scan the QR to manage your booking</p>
                </RowDiv>
            </CardDiv>
        </AlertModal>
    </div>
    <div v-show="errorContent" class="modal-overlay">
        <AlertModal @close="errorContent = null" class="popup">
            <CardDiv>
                <RowDiv>
                    <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                </RowDiv>
                <RowDiv v-html="errorContent"></RowDiv>
            </CardDiv>
        </AlertModal>
    </div>
    <div v-show="isLoading" class="modal-overlay">
        <LoadingModal />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import AlertModal from '@/components/AlertModal.vue';
import BookingList from '@/views/home/BookingList.vue';
import CarpoolList from '@/views/home/CarpoolList.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import HeroCard from '@/components/HeroCard.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import SearchBar from './booking/SearchBar.vue';
import UpcomingBooking from './home/UpcomingBooking.vue';
import { getAuth } from '@/utils/functions';

export default {
    components: {
        AlertModal,
        BookingList,
        CarpoolList,
        ConfirmModal,
        HeroCard,
        LoadingModal,
        SearchBar,
        UpcomingBooking
    },
    computed: {
        ...mapState(['isMobile', 'profile'])
    },
    data() {
        return {
            currentDetails: null,
            bookings: [],
            popUpCancel: false,
            isLoading: false,
            cancelledBooking: null,
            isStarting: false,
            endConfirm: false,
            errorContent: null,
        }
    },
    mounted() {
        this.fetchBookings()
    },
    methods: {
        fetchBookings() {
            this.$axios.get(`${this.$env.API_HOST}/user/bookings/upcoming`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(response => {
                if (response.status === 200 && response.data && typeof response.data !== 'string') {
                    const responseArray = Object.values(response.data);
                    this.bookings = responseArray.sort((a, b) => a.start - b.start);
                    this.currentDetails = responseArray.filter(booking => {
                        const today = new Date();
                        const start = new Date(+booking.start * 1000)
                        return ((today.toDateString() === start.toDateString()));
                    });
                }
            }).catch(() => {
                console.error('Could not fetch upcoming bookings')
            })
        },
        confirmCancel(bookingId) {
            this.cancelledBooking = this.bookings.find(booking => +booking.id === +bookingId);
            if (this.cancelledBooking) {
                if (this.cancelledBooking.state === 'approved') {
                    this.popUpCancel = true;
                } else {
                    this.endConfirm = true;
                }
            }
        },
        isUserIsDriver() {
            const myEmail = this.profile?.email_addresses.find(email => email.primary === 'true');
            const driver = this.cancelledBooking?.driver;
            return myEmail.email_address === driver.email_address;
        },
        cancelBooking() {
            this.popUpCancel = false;
            this.isLoading = true;
            this.$axios.put(`${this.$env.API_HOST}/booking/${this.isUserIsDriver ? 'cancel' : 'leave'}`,
                { booking_id: this.cancelledBooking.id },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                setTimeout(() => {
                    this.fetchBookings();
                    this.isLoading = false;
                }, 1500);
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'Booking was not cancelled.',
                    error: true,
                });
                console.error('Error cancelling your booking');
            });
        },
        endBooking() {
            this.endConfirm = false;
            this.isLoading = true;
            this.$axios.put(`${this.$env.API_HOST}/booking/cancel`,
                { booking_id: this.cancelledBooking.id },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                setTimeout(() => {
                    this.fetchBookings();
                    this.isLoading = false;
                }, 1500);
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'There was an error ending your booking.',
                    error: true,
                });
                console.error('Error cancelling your booking');
            });
        },
        startBooking(booking_id) {
            this.$axios.put(`${this.$env.API_HOST}/booking/start`,
                { booking_id },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                setTimeout(() => {
                    this.fetchBookings();
                }, 1500);
            }).catch(error => {
                console.error('Error starting your booking', error);
                if (error.response && error.response.headers['content-type'].includes('text/html')) {
                    const htmlContent = error.response.data;
                    const parser = new DOMParser();
                    const document = parser.parseFromString(htmlContent, 'text/html');
                    this.errorContent = document.querySelector('body').innerHTML;
                }
            });
        }
    }
}
</script>
