<template>
    <div class="container">
        <CardDiv>
            <div class="h3 reg">Quick book</div>
            <RowDiv>
                <div class="col">
                    <div class="b1 semi">Pick up</div>
                </div>
                <div class="static-on"></div>
                <div class="col-3">
                    <div class="b1 semi">Date</div>
                </div>
                <div class="col-2">
                    <div class="b1 semi">Start time</div>
                </div>
                <div class="col-2">
                    <div class="b1 semi">End time</div>
                </div>
                <div class="col-1">
                </div>
            </RowDiv>
            <RowDiv>
                <div class="col">
                    <HubSearchField :options="options" @change="handleHubChange" :is-disabled="false"
                        placeholder="Pick up hub" />
                </div>
                <div class="static-on">on</div>
                <div class="col-3">
                    <DatePicker v-model="bookingDate" format="dd/MM/yyyy" model-type="dd-MM-yyyy" :text-input="true"
                        :enable-time-picker="false" placeholder="Date" :range="{ partialRange: true }"
                        :min-date="minDate"
                        :action-row="{ showNow: true, showPreview: true, showSelect: true, showCancel: true }" />
                </div>
                <div class="col-2">
                    <TimePicker v-model="pickupTime" :offset="minPickTime" placeholder="Pickup time" />
                </div>
                <div class="col-2">
                    <TimePicker v-model="dropOffTime" :offset="minDropTime" placeholder="Drop off time" />
                </div>
                <div class="col-1">
                    <SelectableButton class="dark small" @click="handleSearch">
                        Search
                    </SelectableButton>
                </div>
            </RowDiv>
        </CardDiv>
    </div>
    <div v-if="alertMessage" class="modal-overlay">
        <AlertModal @click="alertMessage = ''">
            <div class="b1 reg">Please check your search criteria</div>
            <div class="b2 reg">{{ alertMessage }}</div>
        </AlertModal>
    </div>
</template>
<script>
import AlertModal from '@/components/AlertModal.vue';
import DatePicker from '@/components/DatePicker.vue';
import HubSearchField from '@/components/HubSearchField.vue';
import TimePicker from '@/components/TimePicker.vue';
import { getAuth, getLongLatDistance, getMinTime, parseDateTime } from '@/utils/functions';
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
        AlertModal,
        DatePicker,
        HubSearchField,
        TimePicker,
    },
    data() {
        return {
            bookingDate: null,
            pickupTime: '',
            dropOffTime: '',
            options: [],
            selectedHub: null,
            alertMessage: '',
        }
    },
    computed: {
        minDate() {
            const now = new Date();
            now.setMinutes(now.getMinutes() + 30);
            return now;
        },
        minPickTime() {
            if (!this.bookingDate) return 7;
            return getMinTime(this.bookingDate[0])
        },
        minDropTime() {
            if (!this.bookingDate) return 11;
            const minDate = this.bookingDate[1] || this.bookingDate[0];
            return getMinTime(minDate, { date: this.bookingDate[0], time: this.pickupTime });
        },
        ...mapState(['searchCriteria'])
    },
    async mounted() {
        try {
            const position = await this.getGeoLocation();
            this.positionCoords = {
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
            }
        } catch (error) {
            console.error(error);
            this.$bus.emit('show-toast-message', {text: 'System needs your location to show estimated distance to hubs'});
        }
        this.fetchData();
        this.setDefaultDate();
    },
    methods: {
        setDefaultDate() {
            if (this.bookingDate === null) {
                const date = `${this.minDate.getDate()}-${this.minDate.getMonth() + 1}-${this.minDate.getFullYear()}`;
                this.bookingDate = [date, date];
            }
        },
        getGeoLocation() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            });
        },
        fetchData() {
            this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(resp => {
                this.options = Object.values(resp.data).map((option) => {
                    if (this.positionCoords) {
                        const distanceInK = getLongLatDistance(option, this.positionCoords);
                        let dim = 'kms';
                        let distance = Math.floor(distanceInK);
                        if (distanceInK < 1) {
                            distance = distance * 1000;
                            dim = 'm'
                        }
                        option.distance = { distance, dim };
                    } else {
                        option.distance = { distance: 0, dim: 'kms' }
                    }
                    option.direction = 'return';
                    if (option.one_way_destinations.length > 0) option.direction = 'both-ways';
                    return option;
                });
            }).catch(() => {
                console.error('Could not fetch hub info')
            });
        },
        handleHubChange(option) {
            this.selectedHub = option;
        },
        handleSearch() {
            if (!this.selectedHub || !this.bookingDate || !this.pickupTime || !this.dropOffTime) {
                this.alertMessage = 'Missing criteria';
                return;
            }
            const [pickupDate, dropOffDate] = this.bookingDate;
            const diffTime = parseDateTime(dropOffDate ?? pickupDate, this.dropOffTime) - parseDateTime(pickupDate, this.pickupTime);
            if (diffTime < 0) {
                this.alertMessage = 'Pick up is later than Drop off'
                return;
            }
            this.setSearchCriteria({
                key: 'pickup', value: {
                    hub: this.selectedHub,
                    date: pickupDate,
                    time: this.pickupTime,
                }
            })
            this.setSearchCriteria({
                key: 'dropOff', value: {
                    hub: this.selectedHub,
                    date: dropOffDate ?? pickupDate,
                    time: this.dropOffTime
                }
            })
            sessionStorage.setItem('searchParams', JSON.stringify({
                pickup: {
                    hub: this.selectedHub,
                    date: pickupDate,
                    time: this.pickupTime,
                },
                dropOff: {
                    hub: this.selectedHub,
                    date: dropOffDate,
                    time: this.dropOffTime,
                },
            }));

            this.$router.push({ name: 'Search Booking' })
        },
        ...mapMutations(['setSearchCriteria'])
    }
}
</script>

<style scoped>
.card {
    background: rgb(255, 255, 255, 0.5);
    padding: 2.5rem 2.5rem 4.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card>.h3 {
    margin: auto -0.5rem auto;
}

.card .row {
    justify-content: space-evenly;
    align-items: center;
}

.card .row *[class^="col"] {
    margin-left: .5rem;
    margin-right: .5rem;
    margin-bottom: 0;
}

.static-on {
    font-size: 1.6rem;
    letter-spacing: -0.16px;
    font-weight: 600;
    margin: 0 .75rem;
}

:deep(.dp__input):hover:not(.dp__input_focus) {
    border-color: var(--input-hover-color);
}
</style>