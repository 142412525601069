<template>
    <div class="h2 reg">Security</div>
    <hr />
    <CardDiv>
        <RowDiv >
            <div class="col">
                <div class="b1 semi">New password</div>
                <div class="b2 reg">
                    Your password must include an uppercase letter, number and special character
                </div>
                <InputComponent icon-src="lock/locked" v-model="newPassword" type="password" className="white"
                    placeholder="Your password must include an uppercase letter, number and special character"
                    :validation="['required', 'password']" />
                <PasswordMeter :password="newPassword"/>
            </div>
        </RowDiv>
        <RowDiv >
            <div class="col">
                <div class="b1 semi">Confirm new password</div>
                <InputComponent icon-src="lock/locked" v-model="confirmPassword" type="password"
                    placeholder="Confirm your new password" :validation="['required']" className="white" />
            </div>
        </RowDiv>
        <RowDiv >
            <SelectableButton class="light borderless small" @click="savePassword">
                <div class="bu3-reg">Save new password</div>
            </SelectableButton>
        </RowDiv>
    </CardDiv>
    <div v-if="isModalOpen" class="modal-overlay">
        <div class="modal">
            <div class="modal-content">
                <CardDiv>
                    <header>Password Mismatch!</header>
                    <p>Passwords doesn't match, kindly check and try again</p>
                    <footer class="is-right">
                        <SelectableButton class="light borderless small" @click="this.isModalOpen = false">
                            <div class="bu3-reg">Close</div>
                        </SelectableButton>
                    </footer>
                </CardDiv>
            </div>
        </div>
    </div>
</template>
<script>
import { getAuth } from '@/utils/functions';
import PasswordMeter from 'vue-simple-password-meter';
export default {
    components: {
        PasswordMeter,
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            isModalOpen: false,
        }
    },
    methods: {
        savePassword() {
            if (!this.newPassword) return;
            if (this.newPassword !== this.confirmPassword) {
                this.isModalOpen = true;
                return false;
            }

            this.$axios.put(`${this.$env.API_HOST}/user/update`,
                { password: this.password },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                    this.newPassword = '';
                    this.confirmPassword = '';
            }).catch(error => {
                console.error("An error occurred while creating a password in:", error);
            });
        }
    },
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.card>.row>.col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
</style>