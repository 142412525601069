<template>
    <div class="h2 reg">Personal Details</div>
    <hr />
    <CardDiv>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">First name</div>
                <InputComponent v-model="firstName" type="text" placeholder="First name" className="white" />
            </div>
            <div class="col field">
                <div class="b1 semi">Last name</div>
                <InputComponent v-model="lastName" type="text" placeholder="Last name" className="white" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">Peferred name (optional)</div>
                <InputComponent v-model="name" type="text" placeholder="Preferred name" className="white" />
            </div>
        </RowDiv>
    </CardDiv>
    <CardDiv>
        <div class="h4 reg">Email</div>
        <RowDiv v-for="(email, i) in emailAddresses" :key="i">
            <div class="col">
                <InputComponent v-model="emailAddresses[i].email_address" type="text" placeholder="Email address"
                    className="white" @update:model-value="updateEmail(i)">
                    <template #extra>
                        <PillButton :class="['small', { 'primary': email.verified }]" @click="verifyEmail(i)">
                            <SvgIcon :name="email.verified ? 'feedback/tick-circle' : 'icon/refresh'" />
                            <div class="bu1 reg" v-if="!isMobile">{{ email.status ?? 'Pending' }}</div>
                        </PillButton>
                    </template>
                </InputComponent>
            </div>
            <div class="col-2">
                <InputComponent v-model="emailAddresses[i].type" type="text" placeholder="Type (Default 'Work')"
                    className="white" />
            </div>
            <div class="col-1">
                <SelectableButton :class="['borderless light small', { 'disabled': email.primary }]">
                    <SvgIcon name="icon/trash" class="icon-wide" />
                </SelectableButton>
            </div>
        </RowDiv>
    </CardDiv>
    <CardDiv v-if="phoneNumbers">
        <div class="h4 reg">Phone</div>
        <RowDiv v-for="(phone, i) in phoneNumbers" :key="i">
            <div class="col phone">
                <PhoneNumberInput v-model="phoneNumbers[i]" className="white">
                    <template #extra>
                        <PillButton :class="`small ${(phone.state === 'unverified') ? 'alert' : 'primary'}`" @click="verifyPhone(i)">
                            <SvgIcon :name="phone.verified ? 'feedback/tick-circle' : 'feedback/warning-outline'" />
                            <div class="bu1 reg" v-if="!isMobile">{{ (phone.state === 'unverified') ? 'Verify now' : 'Verified' }}</div>
                        </PillButton>
                    </template>
                </PhoneNumberInput>
            </div>
            <div class="col-2">
                <InputComponent v-model="phoneNumbers[i].type" type="text" placeholder="Phone type (Default 'Work')"
                    className="white" />
            </div>
            <div class="col-1">
                <SelectableButton :class="['borderless light small', { 'disabled': phone.primary }]">
                    <SvgIcon name="icon/trash" class="icon-wide" />
                </SelectableButton>
            </div>
        </RowDiv>
    </CardDiv>
    <CardDiv v-else>
        <div class="h4 reg">Phone</div>
        <RowDiv>
            <div class="col phone">
                <div class="b1 semi">Phone number</div>
                <PhoneNumberInput v-model="phoneNumbers[0]" className="white" />
            </div>
            <div class="col-2">
                <div class="b1 semi">Type</div>
                <InputComponent v-model="phoneNumbers[0].type" type="text" placeholder="Phone type (Default 'Work')"
                    className="white" />
            </div>
            <div class="col-1">
                <SelectableButton class="borderless light small disabled">
                    <SvgIcon name="icon/trash" class="icon-wide" />
                </SelectableButton>
            </div>
        </RowDiv>
    </CardDiv>
    <CardDiv>
        <div class="h4 reg">Address</div>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">Postal address</div>
                <AddressInput v-model="shippingAddress" placeholder="Postal address" className="white"
                    @update:model-value="setAddress('shipping', shippingAddress)" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">Home address</div>
                <AddressInput v-model="homeAddress" placeholder="Home address" className="white"
                    @update:model-value="setAddress('home', homeAddress)" />
            </div>
        </RowDiv>
    </CardDiv>

    <RowDiv>
        <SelectableButton class="col-2 small light" @click="saveChanges">
            <div class="bu3-reg">Save changes</div>
        </SelectableButton>
    </RowDiv>
    <div v-if="emailChangedVerify" class="modal-overlay">
        <PopUpModal @close="this.emailChangedVerify = false">
            <CardDiv>
                <div class="h3 reg">Save changes</div>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">Please verify your new email address by clicking on the link sent to your
                            email.
                        </p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col field">
                        <div class="b1 semi">New email address</div>
                        <InputComponent v-model="emailAddresses[emailIndex].email_address" type="text"
                            placeholder="First name" className="white" />
                    </div>
                </RowDiv>
                <RowDiv>
                    <SelectableButton className="light small" @click="sendEmail">
                        <div class="button-light">OK</div>
                    </SelectableButton>
                    <SelectableButton className="light small" @click="this.emailChangedVerify = false">
                        <div class="button-light">Cancel</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <div v-if="emailVerifyResend" class="modal-overlay">
        <PopUpModal @close="this.emailVerifyResend = false">
            <CardDiv>
                <div class="h3 reg">Resend code</div>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">Please verify your new email address by clicking on the link sent to your
                            email.
                        </p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col field">
                        <div class="b1 semi">Send to email address</div>
                        <InputComponent v-model="confirmEmailAddress" type="text" placeholder="First name"
                            className="white" />
                    </div>
                </RowDiv>
                <RowDiv>
                    <SelectableButton className="light small" @click="sendEmail">
                        <div class="button-light">Resend</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <div v-if="phoneVerify" class="modal-overlay">
        <PopUpModal @close="this.phoneVerify = false">
            <CardDiv>
                <div class="h3 reg">Resend code</div>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">We have sent you an SMS to the phone number <b>{{
                            phoneNumbers[phoneIndex].phone_number }}</b>
                        </p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col field">
                        <div class="b1 semi">Enter the SMS code you received</div>
                        <InputComponent v-model="phoneToken" type="text" placeholder="SMS code" className="white" />
                    </div>
                </RowDiv>
                <div v-show="phoneTokenError" class="card-alerts">
                    Please enter the code sent to your mobile number.
                </div>
                <RowDiv>
                    <SelectableButton className="light small" @click="verifyPhone(this.phoneIndex)">
                        <div class="button-light">Resend</div>
                    </SelectableButton>
                    <SelectableButton className="light small" @click="verifyPhoneToken">
                        <div class="button-light">Save</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
</template>
<script>
import AddressInput from '@/components/AddressInput.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import PopUpModal from '@/components/PopUpModal.vue';
import { getAuth } from '@/utils/functions';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        AddressInput,
        PhoneNumberInput,
        PopUpModal,
    },
    computed: {
        ...mapState(['profile', 'isMobile']),
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            name: '',
            emailAddresses: [],
            phoneNumbers: [],
            addresses: [],
            shippingAddress: '',
            homeAddress: '',
            billingAddress: '',
            billingAsPostal: false,
            homeAsPostal: false,
            emailType: 'Primary',
            phoneType: 'Primary',
            emailChanged: false,
            emailChangedVerify: false,
            emailVerifyResend: false,
            emailIndex: null,
            newEmailAddress: '',
            confirmEmailAddress: '',
            phoneVerify: false,
            phoneToken: '',
            phoneIndex: null,
            addressList: [],
            phoneTokenError: false,
        }
    },
    watch: {
        profile: {
            handler(newVal) {
                if (!newVal) return;
                this.emailAddresses = newVal.email_addresses.map((email) => {
                    return {
                        ...email,
                        type: email.primary ? 'Primary' : 'Other',
                        status: (email.state === 'unverified' ? 'Pending' : 'Verified'),
                        verified: email.state !== 'unverified'
                    }
                });
                this.name = newVal.preferred_name ?? '';
                this.firstName = newVal.first_name ?? '';
                this.lastName = newVal.last_name ?? '';
                this.phoneNumbers = newVal.phone_numbers.map((phone) => {
                    return {
                        ...phone,
                        type: phone.primary ? 'Primary' : 'Other',
                        status: (phone.state === 'unverified') ? 'Verify now' : 'Verified',
                        verified: phone.state !== 'unverified'
                    }
                });
                this.addresses = [...newVal.addresses];
                this.shippingAddress = this.stringifyAddress(this.addresses.find((address) => address.type === 'shipping')) ?? '';
                this.homeAddress = this.stringifyAddress(this.addresses.find((address) => address.type === 'home')) ?? '';
                this.billingAddress = this.stringifyAddress(this.addresses.find((address) => address.type === 'billing')) ?? '';
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        sendEmail() {
            this.$axios.post(`${this.$env.API_HOST}/user/email/verify`, {
                email_address: this.emailAddresses[this.emailIndex].email_address,
                url: `${window.location.host}/profile/verified/`
            }).then(resp => {
                if (resp.status === 200) {
                    this.emailAddresses[this.emailIndex].status = 'Verified';
                    this.emailAddresses[this.emailIndex].verified = true;
                    this.emailChanged = false;
                    this.emailVerifyResend = false;
                    const postData = {
                        email_addresses: this.emailAddresses.map(email => {
                            // eslint-disable-next-line
                            const { type, status, ...returnObj } = email;
                            return returnObj;
                        })
                    }
                    this.$axios.put(`${this.$env.API_HOST}/user/update`, postData,
                        { headers: { 'Authorization': `Bearer ${getAuth()}` } }
                    ).catch(error => {
                        console.error('Error saving the email address:', error);
                    });
                }
            }).catch(error => {
                console.error("Could not send an email:", error);
            });
        },
        verifyEmail(index) {
            this.confirmEmailAddress = this.emailAddresses[index].email_address;
            this.emailIndex = index;
            this.emailVerifyResend = true;
        },
        verifyPhone(index) {
            this.phoneIndex = index;
            this.phoneTokenError = false;
            this.$axios.post(`${this.$env.API_HOST}/user/phone/verify`,
                { ...this.phoneNumbers[index] },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                this.phoneVerify = true;
            }).catch(e => {
                console.error('Error is sending verification code:', e);
            });
        },
        verifyPhoneToken() {
            if (!this.phoneToken) {
                this.phoneTokenError = true;
                return;
            }
            const accessToken = sessionStorage.getItem('accToken')
            const headers = { Authorization: `Bearer ${accessToken}` }
            this.$axios.put(`${this.$env.API_HOST}/user/phone/verify`,
                { phone_token: this.phoneToken }, { headers }
            ).then(() => {
                this.phoneVerify = false;
                this.phoneNumbers[this.phoneIndex].verified = true;
                this.phoneNumbers[this.phoneIndex].state = 'verified';
                this.phoneNumbers[this.phoneIndex].status = 'Verified';
                const postData = {
                    phone_numbers: this.phoneNumbers.map(phone => {
                        // eslint-disable-next-line
                        const { type, status, ...returnObj } = phone;
                        return returnObj;
                    })
                }
                this.$axios.put(`${this.$env.API_HOST}/user/update`, postData, { headers }).catch(error => {
                    console.error('Error saving phone number:', error)
                });
            }).catch(e => {
                this.phoneTokenError = true;
                console.error('Could not verify token:', e);
            });
        },
        updateEmail(arg) {
            this.emailChanged = true;
            this.emailIndex = arg;
        },
        saveChanges() {
            if (this.emailChanged) {
                this.emailChangedVerify = true;
                return;
            }
            const accessToken = sessionStorage.getItem('accToken');
            const postData = {
                first_name: this.firstName,
                last_name: this.lastName,
                preferred_name: this.name,
                email_addresses: JSON.stringify(this.emailAddresses.map(email => {
                    // eslint-disable-next-line
                    const { type, status, ...returnObj } = email;
                    return returnObj;
                })),
                phone_numbers: JSON.stringify(this.phoneNumbers.map(phone => {
                    // eslint-disable-next-line
                    const { type, status, ...returnObj } = phone;
                    return returnObj;
                })),
                addresses: JSON.stringify(this.addresses.map(address => {
                    if (address.type === 'shipping') return { ...address, ...this.splitAddress(this.shippingAddress) };
                    if (address.type === 'home') return { ...address, ...this.splitAddress(this.homeAddress) };
                    if (address.type === 'billing') return { ...address, ...this.splitAddress(this.billingAddress) };
                })),
            }
            const headers = { Authorization: `Bearer ${accessToken}` }
            this.$axios.put(`${this.$env.API_HOST}/user/update`,
                postData, { headers }
            ).then(() => {
                this.loadProfile()
            }).catch(error => {
                console.error('Error saving profile:', error)
            });
        },
        stringifyAddress(address) {
            if (!address) return '';
            const addressString = (address.street2 || '') + ', ' + (address.street1 || '') + ', ' + (address.suburb || '') + ', ' + (address.city || '') + ' ' + (address.postcode || '');
            return addressString.replace(/(^,)|( ,){1,}|(,$)/, '').trim();
        },
        splitAddress(address) {
            const addressArray = address.split(',').map(item => item.trim());
            const addressObj = {};
            if (addressArray.length < 2) return { street1: address };
            [addressObj.city, addressObj.postcode] = addressArray.pop().split(' ');
            if (!addressObj.postcode) return { street1: address };
            if (addressArray.length < 2) return { ...addressObj, street: addressArray.join(' ') };
            addressObj.suburb = addressArray.pop();
            addressObj.street1 = addressArray.pop();
            addressObj.street2 = (addressArray.length > 0) ? addressArray.join(', ') : '';
            return addressObj;
        },
        setAddress(type, address) {
            const selectedAddress = this.addresses.find(addr => addr.type === type);
            const newAddress = this.splitAddress(address);
            if (this.addresses.length === 0 || !selectedAddress) {
                this.addresses.push({ ...newAddress, type });
            } else {
                this.addresses = this.addresses.map(addr => {
                    if (addr.type !== type) return addr
                    return { type: addr.type, ...newAddress };
                });
            }
        },
        ...mapActions(['loadProfile']),
    }
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.phone {
    margin-right: 0;
}

.card-alerts {
    background: var(--notification-colours-error-light, #FFECEC);
    border-radius: 0.8rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.dropdown {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown li {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}
</style>