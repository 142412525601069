<template>
    <OnBoardingCard displayImage="/images/resized/keys.png">
        <div class="h1 reg">Reset Password</div>
        <div class="forms">
            <div class="form-field">
                <div class="instructions">
                    <div class="form-field-label b1 bold">
                        New password
                    </div>
                    <div class="form-field-hint b2 reg">
                        Your password must include an uppercase letter, number and special character
                    </div>
                </div>
                <InputComponent icon-src="lock/locked" v-model="password" type="password"
                    placeholder="Create your password" :validation="['required', 'password']" />
                <PasswordMeter :password="password" />
            </div>
            <div class="form-field">
                <div class="instructions">
                    <div class="form-field-hint b2 reg">
                        Confirm password
                    </div>
                </div>
                <InputComponent icon-src="lock/locked" v-model="confirmPassword" type="password"
                    placeholder="Create your password" :validation="['required']" />
            </div>
        </div>
        <div class="buttons">
            <SelectableButton @click="createPassword">
                <div class="btn-anchor bu1-semi">Reset password</div>
            </SelectableButton>
        </div>
        <div v-if="isModalOpen" class="modal-overlay">
            <div class="modal">
                <div class="modal-content">
                    <CardDiv>
                        <header>Password Mismatch!</header>
                        <p>Passwords doesn't match, kindly check and try again</p>
                        <footer class="is-right">
                            <div class="btn btn-large btn-inverse">
                                <div class="button-light"><a @click="closeModal">Close</a></div>
                            </div>
                        </footer>
                    </CardDiv>
                </div>
            </div>
        </div>
    </OnBoardingCard>
</template>

<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import PasswordMeter from 'vue-simple-password-meter';
import { mapMutations } from 'vuex';
export default {
    components: { OnBoardingCard, PasswordMeter },
    data() {
        return {
            password: "",
            confirmPassword: "",
            fieldType: 'password',
            toggleText: 'show',
            isModalOpen: false,
            token: ''
        };
    },
    created() {
        this.token = this.$route.query.t;
    },
    methods: {
        createPassword() {
            if (this.password !== this.confirmPassword) {
                this.isModalOpen = true;
                return false;
            }
            this.$axios.put(`${this.$env.API_HOST}/user/update`, {
                password: this.password,
            }, { headers: { token: this.token } }).then(() => {
                this.$router.push({ name: 'Login' })
            }).catch(error => {
                this.$bus.on('show-toast-message', {
                    message: 'Password was not updated due to an internal error.',
                    error: true,
                });
                console.error("An error occurred while creating a password in:", error);
            });
        },
        togglePassword() {
            this.fieldType = (this.fieldType === 'password') ? 'text' : 'password';
            this.toggleText = (this.toggleText === 'show') ? 'hide' : 'show';
        },
        closeModal() {
            this.isModalOpen = false;
        },
        ...mapMutations(['setOnboarding', 'setTokenExpired']),
    }
};
</script>

<style scoped>
.forms-small-fields-default {
    background: var(--color-grey, #FAFAFA);
    border-radius: 0.5rem;
    padding: 0.625rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 2.25rem;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.toggle-password {
    color: var(--font-color, #042B2F);
    text-align: right;
    font-size: var(--input-fields-label-1-interaction-font-size, 1.2rem);
    font-weight: var(--input-fields-label-1-interaction-font-weight, 700);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.forms-field-label {
    font-size: var(--forms-instructions-body-2-font-size, 1.4rem);
}

.form-field-value {
    font-size: var(--input-fields-label-1-font-size, 1.2rem);
}

.button-default {
    font-weight: bolder;
}
</style>