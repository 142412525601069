<template>
    <div :class="['modal', classes]">
        <div class="modal-content">
            <slot></slot>
            <div class="footer row">
                <SelectableButton class="light small borderless" @click="this.$emit('close')">
                    <SvgIcon name="icon/close" />
                    <div class="bu3-reg">Cancel</div>
                </SelectableButton>
                <SelectableButton class="small borderless" @click="this.$emit('confirm')">
                    <SvgIcon name="feedback/tick-circle" />
                    <div class="bu3-reg">OK</div>
                </SelectableButton>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classes: {
            type: String,
            default: ''
        }
    }
}
</script>
<style scoped>
:deep(.card) {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
</style>