<template>
    <div class="button-container">
        <div :class="['button', className, { 'selected': isOn }]" @click="toggleOn">
            <slot name="left"></slot>
        </div>
        <div :class="['button', className, { 'selected': !isOn }]" @click="toggleOff">
            <slot name="right"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        className: {
            type: String,
            default: ''
        }
    },
    emits: ['change'],
    data() {
        return {
            isOn: true,
        }
    },
    methods: {
        toggleOn() {
            this.isOn = true;
            this.$emit('change', this.isOn);
        },
        toggleOff() {
            this.isOn = false;
            this.$emit('change', this.isOn);
        }
    }
}
</script>
<style scoped>
.button-container {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
}

.button {
    color: var(--font-color, #042B2F);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
    gap: 10px;
    background: var(--color-primary, #A4EA6E);
    border: 1px solid var(--color-primary, #A4EA6E);
}

.button:first-child {
    border-radius: unset;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.button:last-child {
    margin: 0px;
    border-radius: unset;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.button.small {
    padding: .875rem 1rem;
    height: fit-content;
}

.button.light {
    background: rgb(200, 200, 200, 0.3);
    border: 1px solid var(--Black-Carbn-Forest-Green, rgb(255, 255, 255, 0.3));
}

.button.dark {
    border: 1px solid var(--bg-secondary-color, #042B2F);
    background: var(--bg-secondary-color, #042B2F);
    color: var(--notification-colours-correct-light, #D6F6BC);
}

.button.dark:hover,
.button.light:hover {
    background: var(--notification-colours-correct-light, #D6F6BC);
    border: var(--notification-colours-correct-light, #D6F6BC);
    color: var(--font-color, #042B2F);
}

.button.borderless {
    border-color: transparent;
}

.button:hover {
    border: 1px solid var(--bg-secondary-color, #042B2F);
    opacity: 1;
}

.button.selected {
    background: var(--color-primary, #A4EA6E);
    border-color: var(--color-primary, #A4EA6E);
}
</style>