const GLOBAL_ENV = {
    API_HOST: 'https://api.zilch.ninja',
    STRIPE_API_KEY: 'pk_test_51PyOB2086eYsgmna4Y3tKlMjIux6ys4bco4jpiGd48lPha5cygcVXyFRvU4bxF94Q7spHICBbmCuvqdX22WyAFIt00uwcrxTDC',
    STRIPE_SECRET_KEY: 'sk_test_51PyOB2086eYsgmnaWn26eXkJLdmo9dvZRPjSWlT40pG0SRrWJG1xeNqJHpSlKlKJaAGgnG6rhlRtwn3aBL7V3f7h00xgG9XRco',
    ADDY_URL: 'https://api-nz.addysolutions.com/search/',
    ADDY_KEY: '0b77a3446d8b4ea186592c7598d99f9b',
    ADDY_SECRET: 'd1c36aecaec449fb9376c392c0082105',
}

const ENV_SANDBOX = {
    ...GLOBAL_ENV,
    API_HOST: 'https://api.zilch.services'
}

const ENV_DEV = {
    ...GLOBAL_ENV,
    API_HOST: 'https://api.zilch.services'
}

const ENV_PROD = {
    ...GLOBAL_ENV,
    STRIPE_API_KEY: 'pk_live_51PyKigP2iL7s3fWGUgsinTgDdZLP0FSyVuLCi1FZKJDxllITRDLtdz3FJwI46DfgQCg4x137JtVJZF5nfXsGg5uD00IlrioQl4',
    STRIPE_SECRET_KEY: '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',
    // ADDY_KEY: 'f5d76db6b99e4d13a3db499309d11a00',
}
 
let env = ENV_PROD;
if (process.env.NODE_ENV === 'development') {
    env = ENV_DEV;
} else if (process.env.NODE_ENV === 'sandbox') {
    env = ENV_SANDBOX;
} else {
    env = ENV_PROD;
}
const environment = env;

export default environment;