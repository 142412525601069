<template>
    <OnBoardingCard displayImage="/images/resized/keys.png">
        <div class="h1 reg">You are verified</div>
        <div class="forms">
            <div class="form-field">
                <div class="instructions">
                    <div class="form-field-label b1 bold">
                        Create password
                    </div>
                    <div class="form-field-hint b2 reg">
                        Your password must include an uppercase letter, number and special character
                    </div>
                </div>
                <InputComponent icon-src="lock/locked" v-model="password" type="password" @enter="createPassword"
                    placeholder="Create your password" :validation="['required', 'password']" />
                <PasswordMeter :password="password" />
            </div>
            <div class="form-field">
                <div class="instructions">
                    <div class="form-field-hint b2 reg">
                        Confirm password
                    </div>
                </div>
                <InputComponent icon-src="lock/locked" v-model="confirmPassword" type="password"
                    placeholder="Create your password" :validation="['required']" @enter="createPassword" />
            </div>
        </div>
        <div class="buttons">
            <SelectableButton @click="createPassword">
                <div class="btn-anchor bu1-semi">Sign up</div>
            </SelectableButton>
        </div>
        <div v-if="isModalOpen" class="modal-overlay">
            <div class="modal">
                <div class="modal-content">
                    <CardDiv>
                        <header>Password Mismatch!</header>
                        <p>Passwords doesn't match, kindly check and try again</p>
                        <footer class="is-right">
                            <div class="btn btn-large btn-inverse">
                                <div class="button-light"><a  @click="closeModal">Close</a></div>
                            </div>
                        </footer>
                    </CardDiv>
                </div>
            </div>
        </div>
    </OnBoardingCard>
</template>

<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import PasswordMeter from 'vue-simple-password-meter';
import { mapState, mapMutations } from 'vuex';
export default {
    components: { OnBoardingCard, PasswordMeter },
    data() {
        return {
            password: "",
            confirmPassword: "",
            fieldType: 'password',
            toggleText: 'show',
            isModalOpen: false,
            token: ''
        };
    },
    computed: {
        ...mapState(['onboarding'])
    },
    created() {
        this.token = this.$route.query.t;
    },
    mounted() {
        this.fetchData(this.token);
    },
    methods: {
        fetchData(token) {
            if (!token) {
                this.invalidateToken();
            }
            this.$axios.put(`${this.$env.API_HOST}/user/email/verify`, {
                email_token: token,
            }).then(() => {
                sessionStorage.setItem('onboardingToken', token);
                this.setOnboarding({ key: 'token', value: token });
                this.$axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                    headers: { token }
                }).then(response => {
                    const data = response.data;
                    for (const key in data) {
                        this.setOnboarding({ key, value: data[key] });
                    }
                }).catch(e => {
                    console.error('Error retrieving data from token:', e);
                    this.invalidateToken();
                })
            }).catch(error => {
                console.error('Error retreiving token, redirecting. API response:', error);
                this.invalidateToken();
            });
        },
        createPassword() {
            if (this.password !== this.confirmPassword) {
                this.isModalOpen = true;
                return false;
            }
            this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                password: this.password,
            }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                this.setOnboarding({ key: 'password', value: this.password });
                this.$router.push('/onboarding/welcome')
            }).catch(error => {
                console.error("An error occurred while creating a password in:", error);
            });
        },
        togglePassword() {
            this.fieldType = (this.fieldType === 'password') ? 'text' : 'password';
            this.toggleText = (this.toggleText === 'show') ? 'hide' : 'show';
        },
        closeModal() {
            this.isModalOpen = false;
        },
        invalidateToken() {
            sessionStorage.removeItem('onboardingToken');
            this.setTokenExpired(true);
            this.$router.replace('/onboarding/join');
        },
        ...mapMutations(['setOnboarding', 'setTokenExpired']),
    }
};
</script>

<style scoped>
.forms-small-fields-default {
    background: var(--color-grey, #FAFAFA);
    border-radius: 0.5rem;
    padding: 0.625rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 2.25rem;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.toggle-password {
    color: var(--font-color, #042B2F);
    text-align: right;
    font-size: var(--input-fields-label-1-interaction-font-size, 1.2rem);
    font-weight: var(--input-fields-label-1-interaction-font-weight, 700);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.forms-field-label {
    font-size: var(--forms-instructions-body-2-font-size, 1.4rem);
}

.form-field-value {
    font-size: var(--input-fields-label-1-font-size, 1.2rem);
}

.button-default {
    font-weight: bolder;
}
</style>