<template>
    <div :class="['header', { 'scrolled': isScrolled, 'logged-in': isLoggedIn }]">
        <div class="header-company-logos" @click="this.$router.push('/home')">
            <img alt="logo" class="zilch-logo-full-colour-copy-jpeg-1"
                src="/images/zilch-logo-full-colour-copy-jpeg-10.svg" />
        </div>
        <div class="header-buttons" v-if="isLoggedIn">
            <div class="frame-2561" v-if="isMobile">
                <SelectableButton class="light small borderless" @click="this.$router.push('/profile')">
                    <SvgIcon name="menu/profile" class="icon-wide" />
                </SelectableButton>
                <DropDownButton>
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/hamburger" class="icon-wide" />                            
                        </SelectableButton>
                    </template>
                    <template  v-if="isAdmin">
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/live-dashboard" class="icon-wide" />
                            <div class="my-rides">Live Dashboard</div>
                        </SelectableButton>
                        <SelectableButton class="light small borderless" v-if="isAdmin">
                            <SvgIcon name="menu/organisation" class="icon-wide" />
                            <div class="button-label">Organisation</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                        <SelectableButton class="light small borderless" @click="this.$router.push('/home')">
                            <SvgIcon name="menu/trips" class="icon-wide" />
                            <div class="button-label">My Bookings</div>
                        </SelectableButton>
                        <SelectableButton class="light small borderless" v-if="isAdmin">
                            <SvgIcon name="menu/reports" class="icon-wide" />
                            <div class="button-label">Reports</div>
                        </SelectableButton>
                        <SelectableButton class="light small borderless" @click="handleLogout">
                            <div class="button-label">Logout</div>
                        </SelectableButton>
                    </template>
                    <SelectableButton class="light small borderless"  @click="handleLogout">Logout
                    </SelectableButton>
                </DropDownButton>
            </div>
            <div class="frame-2561" v-else>
                <SelectableButton class="light small borderless" v-if="isAdmin">
                    <SvgIcon name="menu/live-dashboard" class="icon-wide" />
                    <div class="my-rides">Live Dashboard</div>
                </SelectableButton>
                <SelectableButton class="light small borderless" v-if="isAdmin">
                    <SvgIcon name="menu/organisation" class="icon-wide" />
                    <div class="button-label">Organisation</div>
                    <SvgIcon name="icon/down" />
                </SelectableButton>
                <SelectableButton class="light small borderless" @click="this.$router.push('/home')">
                    <SvgIcon name="menu/trips" class="icon-wide" />
                    <div class="button-label">My Bookings</div>
                </SelectableButton>
                <SelectableButton class="light small borderless" v-if="isAdmin">
                    <SvgIcon name="menu/reports" class="icon-wide" />
                    <div class="button-label">Reports</div>
                </SelectableButton>
                <DropDownButton>
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/profile" class="icon-wide" />
                            <div class="button-label">{{ this.profile.first_name }} {{ this.profile.last_name }}</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                    </template>
                    <SelectableButton class="light small borderless" @click="this.$router.push('/profile')">
                        <div class="bu1 reg">My account</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="handleLogout">
                        <div class="bu1 reg">Logout</div>
                    </SelectableButton>
                </DropDownButton>
            </div>
            <SelectableButton class="dark small" @click="this.$router.push('/search')">
                <SvgIcon name="car/car" class="icon-wide" color="white" />
                <div class="bu1 reg">Book car</div>
            </SelectableButton>
        </div>
    </div>
</template>
<script>
import DropDownButton from './DropDownButton.vue';
import { mapState, mapActions } from 'vuex';
export default {
    components: { DropDownButton },
    computed: {
        ...mapState(['isLoggedIn', 'isMobile', 'profile'])
    },
    data() {
        return {
            isAdmin: false,
            isScrolled: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 50;
        },
        handleLogout() {
            this.logout();
            this.$router.replace({name: 'Login'})
        },
        ...mapActions(['logout'])
    }
}
</script>
<style scoped>
.header {
    background: transparent;
    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    transition: background-color 0.3s ease;
}

.header.scrolled {
    background: var(--lite-gray-grey-1-90percent);
}

.header.logged-in {
    justify-content: space-between;
    width: -webkit-fill-available;
}

.header-company-logos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
}

.header-buttons {
    display: flex;
    gap: 2.5rem;
}

.zilch-logo-full-colour-copy-jpeg-1 {
    flex-shrink: 0;
    width: 6.8rem;
    height: 2.7rem;
    position: relative;
    overflow: visible;
}

.frame-2561 {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    position: relative;
}

.buttons-menu-buttons {
    border-radius: 0.8rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.button-label {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--buttons-body-1-font-size, 1.6rem);
    line-height: var(--buttons-body-1-line-height, 2.2rem);
    font-weight: var(--buttons-body-1-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.frame-16 {
    background: var(--bg-secondary-color, #042B2F);
    border-radius: 10rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

.buttons-dark-large {
    background: var(--bg-secondary-color, #042B2F);
    border-radius: 0.8rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

summary::-webkit-details-marker,
summary::marker {
    display: none;
}

summary {
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
}

details.dropdown>div {
    background-color: var(--notification-colours-correct-light, #D6F6BC);
}

.menu-buttons {
    margin-top: 2.5rem;
    border-radius: 8px;
}

.button+.button {
    margin: auto;
}

@media (max-width: 600px) {

    .frame-2561,
    .header-buttons {
        gap: 0.5rem;
    }
}
</style>