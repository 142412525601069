<template>
    <CardDiv>
        <div class="container">
            <RowDiv>
                <div class="h3 reg">Available options</div>
            </RowDiv>
            <RowDiv class=" car-list">
                <div class="col-12" v-if="alternativeSchedule">
                    <CarSelection :alternativeSchedule="alternativeSchedule" v-for="carOptions in internalList"
                        :key="carOptions" :car="carOptions" @book="handleCarSelect" @join="joinBooking" />
                </div>
                <div class="col-12" v-else>
                    <CarSelection v-for="car in internalList" :key="car" :car="car" @book="handleCarSelect"
                        @join="joinBooking" />
                </div>
                <div v-if="areCarsLoading" class="col-12">
                    <LoadingDots class="large" />
                </div>
            </RowDiv>
            <RowDiv v-show="!alternativeSchedule && !areCarsLoading">
                <div class="col">
                    <div class="container footer">
                        <RowDiv class=" is-center">
                            <div class="b1 reg">You have reached the end of the results for your search criteria.<br />
                                Try expanding your search criteria to find an alternate option.</div>
                        </RowDiv>
                        <RowDiv class=" is-center">
                            <SelectableButton class="light" @click="resetAll">
                                <div class="bu1-semi">New search</div>
                            </SelectableButton>
                            <SelectableButton class="light" @click="this.alternativeSchedule = true" v-show="false">
                                <div class="bu1-semi">Find alternate options</div>
                            </SelectableButton>
                        </RowDiv>
                    </div>
                </div>
            </RowDiv>
        </div>
    </CardDiv>
</template>
<script>
import CarSelection from './CarSelection.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import { getAuth, parseDateTime } from '@/utils/functions';
import { mapMutations, mapState } from 'vuex';

export default {
    props: {
        isLoading: Boolean,
        searchList: [Array, null, Boolean]
    },
    components: {
        CarSelection,
        LoadingDots,
    },
    computed: {
        ...mapState(['searchCriteria'])
    },
    data() {
        return {
            internalList: [],
            alternativeSchedule: false,
            areCarsLoading: true,
        }
    },
    watch: {
        async searchList(newVal) {
            this.internalList = [];
            this.alternativeSchedule = false;
            this.areCarsLoading = true;
            if (!newVal) return;
            let loadingCarpool = true;
            const start = parseDateTime(this.searchCriteria.pickup.date, this.searchCriteria.pickup.time).getTime() / 1000;
            const end = parseDateTime(this.searchCriteria.dropOff.date, this.searchCriteria.dropOff.time).getTime() / 1000;
            let doneCount = 0;
            this.$axios.get(`${this.$env.API_HOST}/booking/available/poolbookings/`, {
                params: {
                    hub: this.searchCriteria.pickup.hub.id,
                    start, end
                },
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(async response => {
                if (!response.data || (response.data.length === 0)) {
                    loadingCarpool = false;
                    return;
                }
                let poolCount = 0;
                const poolBookings = Object.values(response.data);
                for (const booking of poolBookings) {
                    const vehicle = await this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`, {
                        params: { id: booking.vehicle_type }
                    });
                    if (vehicle.status === 200 && vehicle.data && loadingCarpool) {
                        this.internalList.push({ ...vehicle.data[booking.vehicle_type], booking_id: booking.id, booking, isCarpool: true });
                    }
                    poolCount++;
                    if (poolCount === poolBookings.length) {
                        loadingCarpool = false;
                    }
                }
            }).catch(() => {
                console.error('Cannot load available carpool bookings');
            })
            for (const vehicleType of newVal) {
                try {
                    const response = await this.$axios.get(`${this.$env.API_HOST}/booking/available/vehicles/`, {
                        params: {
                            vehicle_type: vehicleType.id,
                            hub: this.searchCriteria.pickup.hub.id,
                            start, end
                        },
                        headers: { 'Authorization': `Bearer ${getAuth()}` }
                    })
                    if (response.status === 200 && (response.data?.vehicle_id)) {
                        this.internalList.push({ ...vehicleType, carId: response.data.vehicle_id, isCarpool: false });
                    }
                    doneCount++;
                    if ((doneCount === newVal.length) && !loadingCarpool) {
                        this.areCarsLoading = false;
                    }
                } catch (error) {
                    console.error(`Error fetching details for ${vehicleType.id}`, error);
                }
            }
        }
    },
    methods: {
        resetAll() {
            this.$bus.emit('reset-search');
        },
        selectBooking(car) {
            const searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
            this.setSearchCriteria({ key: 'selectedCar', value: car });
            sessionStorage.setItem('searchParams', JSON.stringify({ ...searchParams, selectedCar: car }));
        },
        joinBooking(value) {
            // if (car) return;
            const { booking, ...car } = value;
            if (booking) {
                const searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
                this.setSearchCriteria({ key: 'booking', value: booking });
                sessionStorage.setItem('searchParams', JSON.stringify({ ...searchParams, booking }));
            }
            this.selectBooking(car);
            this.$router.push(`/booking/join/${car.booking_id}`);
        },
        handleCarSelect(car) {
            this.setSearchCriteria({ key: 'booking', value: null });
            const searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
            delete searchParams.booking;
            sessionStorage.setItem('searchParams', JSON.stringify({ ...searchParams }));
            this.selectBooking(car);
            this.$router.push('/booking');
        },
        ...mapMutations(['setSearchCriteria',]),
    }
}
</script>
<style scoped>
.container {
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
}

.footer .row {
    margin-top: 2rem;
}

.car-list>div {
    margin-left: 0;
    margin-right: 0;
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
}
</style>