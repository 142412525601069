<template>
    <div class="time-picker">
        <select @change="onInput" v-model="hour">
            <option v-for="n in availableHours" :key="n">{{ n }}</option>
        </select>
        <div class="b2 semi">:</div>
        <select @change="onInput" v-model="minute">
            <option>00</option>
            <option>15</option>
            <option>30</option>
            <option>45</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        offset: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            hour: '00',
            minute: '00',
        };
    },
    computed: {
        availableHours() {
            return Array.from({ length: 24 }, (_, i) => i)
                .filter(h => h >= this.offset)
                .map(e => ((e < 10) ? '0' : '') + e);
        },
    },
    beforeMount() {
        this.initializeTime();
    },
    watch: {
        // eslint-disable-next-line
        modelValue(newValue) {
            this.initializeTime();
        },
        offset() {
            this.validateTime();
        }
    },
    methods: {
        initializeTime() {
            if (this.modelValue) {
                [this.hour, this.minute] = this.modelValue.split(':');
            }
            this.validateTime();
        },
        validateTime() {
            if (+this.hour < this.offset) {
                this.hour = this.availableHours[0];
                this.onInput();  // Trigger update if we correct the time
            }
        },
        onInput() {
            const value = this.hour + ':' + this.minute;
            this.$emit('update:modelValue', value);
        }
    }
};
</script>

<style scoped>
.time-picker {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: .5rem;
}

select {
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    background-color: var(--lite-gray-white, #FFFFFF);
    border-radius: 8px;
    border: 1px solid transparent;
}

select:hover {
    border-color: var(--input-hover-color);
}
</style>
