<template>
    <OnBoardingCard displayImage="/images/resized/image3.png" flexClass="flex" nextButtonName="Next" :stepIndex="3">

        <div class="h1 reg">Add Zilch for personal use</div>
        <div class="list">
            <div class="instructions">
                <div class="instructions-block">
                    To use Zilch Public Car Share please agree to the terms and
                    conditions
                </div>
            </div>
            <div class="labels-default">
                <SvgIcon name="document/document-text" />
                <div class="labels-text">Privacy policy</div>
                <SelectableButton class="small light" :is-selected="acceptedPersonalPolicy"
                    @click="openModal('Personal Use Policy', 'privacy')">
                    <div class="labels-text">{{ this.acceptedPersonalPolicy ? 'Agreed' : 'Read' }}</div>
                    <SvgIcon :name="acceptedPersonalPolicyIcon" />
                </SelectableButton>
            </div>
        </div>
        <PolicyModal v-if="isPolicyModalOpen" :title="modalTitle" name="acceptedPersonalPolicy" :source="modalSource"
            @close="closeModal" @accept="acceptModal"></PolicyModal>
        <div v-if="isModalOpen" class="modal-overlay">
            <div class="modal">
                <div class="modal-content">
                    <CardDiv>
                        <header>Policy Agreements</header>
                        <p>Please read and accept the policy before continuing. Thank you.</p>
                        <footer class="is-right">
                            <a class="button primary" @click="isModalOpen = false">Close</a>
                        </footer>
                    </CardDiv>
                </div>
            </div>
        </div>
    </OnBoardingCard>
</template>

<script>
import PolicyModal from '../../components/PolicyModal.vue';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        return {
            isModalOpen: false,
            isPolicyModalOpen: false,
            modalSource: '',
            modalTitle: '',
            acceptedPersonalPolicy: false,
            acceptedPersonalPolicyIcon: 'icon/export',
        };
    },
    computed: {
        ...mapState(['onboarding'])
    },
    mounted() {
        this.$bus.on('onboarding-next', this.handleSubmit);
    },
    beforeRouteLeave() {
        this.$bus.off('onboarding-next', this.handleSubmit);
    },
    methods: {
        openModal(title, file) {
            // Fetch the content of the source file and set it to modalSource
            this.$axios.get(`${this.$env.API_HOST}/referenceData/legal/${file}`)
                .then(response => {
                    this.modalTitle = title;
                    this.modalSource = response.data;
                    this.isPolicyModalOpen = true;
                }).catch(error => {
                    console.error('Error fetching source file:', error);
                });
        },
        acceptModal() {
            this.acceptedPersonalPolicyIcon = 'feedback/tick-circle';
            this.acceptedPersonalPolicy = true;
            this.isPolicyModalOpen = false;
            this.setNextButtonEnabled(this.acceptedPersonalPolicy);
        },
        closeModal() {
            this.isPolicyModalOpen = false;
            this.modalSource = '';
        },
        handleSubmit() {
            if (this.acceptedPersonalPolicy) {
                this.$router.push('/onboarding/add-access-card');
            } else {
                this.isModalOpen = true;
            }
        },
        ...mapMutations(['setNextButtonEnabled'])
    },
    components: {
        PolicyModal,
        OnBoardingCard
    }
};
</script>
