<template>
    <div :class="['card notice', classes]">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        classes: {
            type: String,
            default: ''
        }
    }
}
</script>
<style scoped>
div.card.notice.info {
    border-radius: 8px;
    display: flex;
    padding: 2.5rem;
    align-items: center;
    gap: 2.5rem;
    background: rgba(225, 243, 255, 0.50);
    margin-bottom: 2.5rem;
}
</style>