<template>
    <div class="h2 reg">Billing Options</div>
    <hr />
    <div class="b2 reg">Add credit cards from your work below</div>
    <br />
    <CardDiv v-if="noBilling">
        <RowDiv>
            <div class="col is-center">
                <div class="b1 reg">Agree to the terms and conditions to enable adding payment methods</div>
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col is-center">
                <SelectableButton class="dark" @click="openModal('terms')">
                    <div class="bu3-reg">Terms and conditions</div>
                </SelectableButton>
            </div>
        </RowDiv>
    </CardDiv>
    <div class="payments-card" v-else>
        <RowDiv v-for="payments in paymentMethods" :key="payments.id">
            <PaymentCard class="col">
                <template #image>
                    <img :src="`/images/${cardImage(payments.brand.toLowerCase())}.png`" alt="logo" />
                </template>
                <div class="h3 reg">{{ payments.name }}</div>
                <div class="b2 reg">{{ payments.brand }} ****{{ payments.last4 }}</div>
                <div class="b2 reg">Expires {{ payments.exp_month }}/{{ payments.exp_year }}</div>
                <template #buttons>
                    <SvgIcon name="icon/trash" class="icon-large" />
                    <SvgIcon name="icon/edit" class="icon-large" @click="openCardPopup(true)" />
                </template>
            </PaymentCard>
        </RowDiv>
        <RowDiv >
            <SelectableButton class="light " @click="openCardPopup(false)">
                <SvgIcon name="icon/add" />
                <div class="bu3-reg">Add new card</div>
            </SelectableButton>
        </RowDiv>
    </div>
    <div v-if="addCardPopup" class="modal-overlay">
        <PopUpModal @close="this.addCardPopup = false" class="billing-popup">
            <CardDiv>
                <RowDiv>
                    <div class="h3 reg" v-if="isEditMethod">Update credit card details</div>
                    <div class="h3 reg" v-else>Add credit card</div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <div class="b1 semi">Name this payment option</div>
                        <div class="b2 reg">Name this card to make it easier to pay later</div>
                        <InputComponent v-model="cardName" type="text" placeholder="My credit card" />
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <div class="b1 semi">Cardholder Name</div>
                        <div class="b2 reg">Enter the name on your card</div>
                        <InputComponent v-model="holderName" type="text" placeholder="John Doe" />
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <div class="b2 reg">Enter the details on your card</div>
                        <div id="card-element" class="b2 reg"></div>
                    </div>
                </RowDiv>
                <RowDiv>
                    <SelectableButton className="light borderless small" @click="this.addCardPopup = false">
                        <div class="button-light">Cancel</div>
                    </SelectableButton>
                    <SelectableButton className="light small" @click="saveCard">
                        <div class="button-light" v-if="isEditMethod">Update card details</div>
                        <div class="button-light" v-else>Save card details</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <PolicyModal v-if="isPolicyModalOpen" title="Terms and Conditions" name="acceptedPersonalPolicy"
        :source="modalSource" @close="closePolicy" @accept="acceptModal"></PolicyModal>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js';
import PaymentCard from '@/components/PaymentCard.vue';
import PolicyModal from '@/components/PolicyModal.vue';
import PopUpModal from '@/components/PopUpModal.vue';
import { mapState } from 'vuex';
import { getAuth } from '@/utils/functions';

export default {
    components: {
        PaymentCard,
        PolicyModal,
        PopUpModal,
    },
    computed: {
        ...mapState(['profile'])
    },
    data() {
        return {
            cardName: '',
            holderName: '',
            paymentMethods: [],
            noBilling: true,
            isPolicyModalOpen: false,
            addCardPopup: false,
            customerToken: ''
        }
    },
    async mounted() {
        try {
            const response = await this.$axios.get(`${this.$env.API_HOST}/user/paymentmethods`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            })
            if (response.data) {
                const privateMethods = response.data.find(item => item.paymentType === 'PERSONAL');
                this.paymentMethods = privateMethods ? [...privateMethods.paymentMethods] : [];
                if (this.paymentMethods.length > 0) this.noBilling = false;
            }
        } catch (error) {
            console.error(error);
        }
        this.customerToken = sessionStorage.getItem('tempCusToken');
    },
    methods: {
        cardImage(brand) { 
            return (brand === 'american express') ? 'amex' : brand;
        },
        async openCardPopup(mode) {
            this.isEditMethod = mode;
            this.addCardPopup = true;
            try {
                const stripeApiKey = this.$env.STRIPE_API_KEY;
                if (!stripeApiKey) {
                    throw new Error("Stripe API key is not set");
                }
                this.stripe = await loadStripe(stripeApiKey);
                if (!this.stripe) {
                    throw new Error("Stripe failed to initialize");
                }

                this.elements = this.stripe.elements();
                this.cardElement = this.elements.create('card', {
                    hidePostalCode: true,
                    disableLink: true,
                    style: {
                        base: {
                            fontFamily: 'DM Sans, sans-serif',
                            fontSize: '14px'
                        }
                    }
                });
                this.cardElement.on('change', ({ error }) => { console.error(error) })
                this.cardElement.mount('#card-element');

                console.log('Stripe initialized successfully');
                if (!this.customerToken && this.profile && (this.profile.stripeCustomer === undefined)) {
                    const email = this.profile.email_addresses.find(address => address.primary === 'true');
                    const customer = await this.$stripe.customers.create({
                        name: this.profile.first_name + ' ' + this.profile.last_name,
                        email: email.email_address,
                    });
                    console.log('Stripe customer created', customer);
                    sessionStorage.setItem('tempCusToken', customer.id);
                    this.customerToken = customer.id;
                }
            } catch (error) {
                console.error("Error initializing Stripe:", error);
            }
        },
        openModal(file) {
            // Fetch the content of the source file and set it to modalSource
            this.$axios.get(`${this.$env.API_HOST}/referenceData/legal/${file}`)
                .then(response => {
                    // Assuming the response is text, you can modify this according to your needs
                    this.modalSource = response.data;
                    this.isPolicyModalOpen = true;
                }).catch(error => {
                    console.error('Error fetching source file:', error);
                });
        },
        acceptModal() {
            this.isPolicyModalOpen = false;
            this.modalSource = '';
            this.noBilling = false;
        },
        closePolicy() {
            this.isPolicyModalOpen = false;
            this.modalSource = '';
        },
        async saveCard() {
            try {
                if (!this.cardName) {
                    console.error("Cardholder name is required");
                    return;
                }

                const { token, error } = await this.stripe.createToken(this.elements.getElement('card'), {
                    name: this.cardName,
                });

                if (error) {
                    console.error("An error occurred:", error);
                } else {
                    const payment = await this.$stripe.customers.createSource(
                        this.customerToken,
                        {
                            source: token.id,
                        }
                    );
                    this.$axios.put(`${this.$env.API_HOST}/user/update`, {
                        payment_customer_token: this.customerToken,
                        payment_method_token1: payment.id,
                        payment_method_tag1: 'business',
                    }, {
                        headers: { 'Authorization': `Bearer ${getAuth()}` }
                    }).then(() => {
                        // Push to array of available payment methods
                        this.paymentMethods.push(payment);
                        // Close pop up
                        this.addCardPopup = false;
                    }).catch(error => {
                        console.error("An error occurred while logging in:", error);
                    });
                }
            } catch (error) {
                console.error("An error occurred during submission:", error);
            }
        }
    }
}
</script>
<style scoped>
.payments-card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.modal-overlay .card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 0px;
}

.modal-overlay *[class^="col"] {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.modal-overlay .row>.col>.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;
}

.modal-overlay h3 {
    margin-bottom: auto;
}

.international-licence {
    width: 35%;
}

.billing-popup {
    width: 35%;
}

@media (max-width: 900px) {

    .international-licence,
    .billing-popup {
        width: auto;
    }
}


.billing-popup .card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#card-element {
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    background-color: #fafafa;
}

#card-element:hover {
    border-color: var(--color-primary);
}
</style>