<template>
    <div class="modal-overlay">
        <PopUpModal @close="closeModal">
            <div class="onboarding-stack print-body">
                <div class="list">
                    <div class="heading">
                        <SvgIcon name="icon/print" class="icon-small print-button" @click="handlePrint" />
                        <SvgIcon name="icon/export" class="icon-small print-button" />
                        <div class="title">{{ title }}</div>
                    </div>
                </div>
                <div class="list2">
                    <div class="contents"><div ref="scrollingDiv" @scroll="handleScroll"><p v-html="source"></p></div></div>
                </div>
            </div>
            <template #footer>
                <div class="modal-buttons is-right">
                    <SelectableButton :className="atBottom ? '' : 'light'" :disabled="!atBottom" @click="acceptPolicy">
                        <div class="bu1-semi">I agree to the {{ title }}</div>
                    </SelectableButton>
                </div>
            </template>
        </PopUpModal>
    </div>
</template>

<script>
import PopUpModal from './PopUpModal.vue';
import { mapMutations } from 'vuex';
export default {
    components: {
        PopUpModal,
    },
    props: {
        title: String,
        source: String,
        name: String,
    },
    data() { 
        return {
            atBottom: false,
        }
    },
    emits: ['accept', 'close'],
    methods: {
        handleScroll() { 
            const scrollingDiv = this.$refs.scrollingDiv;
            if (scrollingDiv.scrollTop + scrollingDiv.clientHeight >= scrollingDiv.scrollHeight) {
                this.atBottom = true;
            }
        },
        closeModal() {
            this.$emit('close');
        },
        acceptPolicy() {
            if (this.atBottom) this.$emit('accept', this.name);
        },
        handlePrint() {
            // Get the content of the onboarding-stack div
            const printContents = document.querySelector('.onboarding-stack.print-body').innerHTML;

            // Open a new window for printing
            const printWindow = window.open('', '', 'height=600,width=800');
            printWindow.document.write('<html><head><title>' + this.title + '</title>');
            // Optionally add your CSS here
            const globalStyles = Array.from(document.styleSheets)
                .map(sheet => {
                    try {
                        return Array.from(sheet.cssRules)
                            .map(rule => rule.cssText)
                            .join('\n');
                    } catch (err) {
                        return ''; // In case of CORS issues with external stylesheets
                    }
                })
                .join('\n');

            printWindow.document.write('<style>' + globalStyles + '</style>');

            printWindow.document.write(`
        <style>
            .list, .list2 {
                border-style: solid;
                border-color: var(--lite-gray-grey-5, #dfdfdf);
                border-width: 0.1rem 0rem 0rem 0rem;
            }
            .list2 {
                padding: 2.5rem 0rem 2.5rem 0rem;
                border-bottom-width: 0.1rem;
            }
            .heading {
                display: flex;
                flex-direction: row;
                gap: 0.625rem;
                align-items: center;
                justify-content: flex-start;
                align-self: stretch;
                flex-shrink: 0;
                position: relative;
            }
            .title {
                color: var(--font-color, #042B2F);
                text-align: left;
                font-size: var(--headings-heading-2-font-size, 2.4rem);
                line-height: var(--headings-heading-2-line-height, 3.1rem);
                font-weight: var(--headings-heading-2-font-weight, 400);
                position: relative;
                flex: 1;
            }
            .contents {
                background: var(--lite-gray-grey-2, #edefef);
                white-space: pre-wrap;
                color: var(--font-color, #042B2F);
                font-size: var(--default-font-size, 1.6rem);
                height: max-content !important;
                overflow: unset !important;

            }
            @media print {
                div.icon.print, div.icon.print-button {
                    display: none;
                }
            }
        </style>
    `);

            printWindow.document.write('</head><body>');
            printWindow.document.write(printContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },
        ...mapMutations(['setOnboarding'])
    }
};
</script>

<style scoped>
.list,
.list2 {
    border-style: solid;
    border-color: var(--lite-gray-grey-5, #dfdfdf);
    border-width: 0.1rem 0rem 0rem 0rem;
}

.list2 {
    padding: 2.5rem 0rem 2.5rem 0rem;
    border-bottom-width: 0.1rem;
}

.modal-content .onboarding-stack {
    gap: 1rem;
}

.cards-top-bar-navigation {
    padding: 5.5rem 4.5rem 3.3rem 4.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 100%;
}

.close {
    color: var(--font-color, #042B2F);
    text-align: right;
    font-size: var(--links-link-2-font-size, 1.6rem);
    font-weight: var(--links-link-2-font-weight, 400);
    position: relative;
    flex: 1;
}

.heading {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.title {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--headings-heading-2-font-size, 2.4rem);
    line-height: var(--headings-heading-2-line-height, 3.1rem);
    font-weight: var(--headings-heading-2-font-weight, 400);
    position: relative;
    flex: 1;
}

.contents {
    background: var(--lite-gray-grey-2, #edefef);
    align-self: stretch;
    flex: 1;
    position: relative;
    white-space: pre-wrap;
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--default-font-size, 1.6rem);
    overflow-y: auto;
    height: 45vh;
    padding: 2.5rem;
    padding-right: 0.5rem;
}

.contents > div {
    max-height: 100%;
    overflow-y: auto;
    padding-right: 2.5rem;
}

.buttons-large-primary-16 {
    border-radius: 0.8rem;
    border-style: solid;
    border-color: var(--bg-secondary-color, #042B2F);
    border-width: 0.1rem;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    float: right;
}

.print-button {
    padding-right: 1.625rem;
}

.buttons-default {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--buttons-button-2-font-size, 1.6rem);
    font-weight: var(--buttons-button-2-font-weight, 400);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.modal-buttons {
    margin-top: 2.5rem;
}

@media print {
    div.icon.print {
        display: none;
    }

    div.icon.print-button {
        display: none;
    }

}
</style>