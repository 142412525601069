<template>

    <div class="flex-container" v-if="isMobile">
        <div class="mobile card-container">
            <OnBoardingMobile :className="className" :stepIndex="stepIndex" :displayImage="displayImage"
                :nextButtonName="nextButtonName" :flexClass="flexClass">
                <slot></slot>
            </OnBoardingMobile>
        </div>
    </div>
    <div class="flex-container" v-else>
        <StepperView v-if="(this.stepIndex)" :stepIndex="stepIndex" />
        <div class="card-container">
            <OnBoardingView :className="className" :stepIndex="stepIndex" :displayImage="displayImage"
                :nextButtonName="nextButtonName" :flexClass="flexClass">
                <slot></slot>
                <template v-if="this.$slots.footer" v-slot:footer>
                    <slot name="footer"></slot>
                </template>
            </OnBoardingView>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import OnBoardingView from './OnBoardingView.vue';
import OnBoardingMobile from './OnBoardingMobile.vue';
import { mapState, mapMutations } from 'vuex';
import StepperView from './StepperView.vue';
export default {
    components: {
        OnBoardingView,
        OnBoardingMobile,
        StepperView
    },
    props: {
        className: String,
        nextButtonName: String,
        displayImage: {
            type: String,
            required: true,
        },
        flexClass: String,
        stepIndex: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapState(['onboarding', 'isMobile']),
    },
    async mounted() {
        if ((this.$route.path === '/onboarding/verify-email') ||
            (this.$route.path === '/login') || (this.$route.path.includes('reset')) ||
            (this.$route.path.includes('/onboarding/verified'))) return;
        if (!this.onboarding?.token) {
            await this.fetchData();
        }
    },
    methods: {
        async fetchData() {
            const sessionToken = sessionStorage.getItem('onboardingToken');
            if ((!this.onboarding || !this.onboarding.token) && !sessionToken) {
                this.$router.replace('/onboarding/join');
                return;
            }
            await axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                headers: { token: this.onboarding?.token || sessionToken }
            }).then(response => {
                const data = response.data;
                if (!data) {
                    sessionStorage.removeItem('onboardingToken');
                    this.setTokenExpired(true);
                    this.$router.replace('/onboarding/join');
                }
                for (const key in data) {
                    this.setOnboarding({ key, value: data[key] });
                }
            }).catch(error => {
                console.error("An error occurred while logging in:", error);
                this.setTokenExpired(true);
                this.$router.replace('/onboarding/join');
            });
        },
        ...mapMutations(['setOnboarding', 'setTokenExpired'])
    }
}
</script>
<style scoped>
.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: inherit;
}

.card-container:deep(.onboarding-stack) {
    padding: 0rem 4.5rem 6.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    position: relative;
}

:deep(.block-text) {
    color: var(--font-color, #042B2F);
    text-align: left;
    position: relative;
    flex: 1;
}

:deep(.instructions) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    padding-bottom: 1rem;
}

:deep(.instructions-block) {
    color: var(--font-color, #042B2F);
    text-align: left;
    line-height: var(--forms-instructions-body-1-line-height, 2.2rem);
    position: relative;
    flex: 1;
}

:deep(.labels-default) {
    padding: 1rem 0rem 1rem 0rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

:deep(.labels-text) {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--small-font-size, 1.4rem);
    font-weight: var(--forms-instructions-body-2-font-weight, 400);
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

:deep(.buttons) {
    display: flex;
    flex-direction: row;
    gap: 1.5625rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.buttons-large-primary-16 {
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--bg-secondary-color, #042B2F);
    border-width: 0.0625rem;
    padding: 0.9375rem 1.5625rem 0.9375rem 1.5625rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.buttons-small-secondary-outline-14 {
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--bg-secondary-color, #042B2F);
    border-width: 0.0625rem;
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.buttons-large-primary-16.inverse-border {
    border-color: var(--color-primary, #A4EA6E);
}

.buttons-large-primary-16.inverse,
.buttons-small-secondary-outline-14.inverse {
    border-color: var(--color-primary, #A4EA6E);
    background: var(--color-primary, #A4EA6E);
}

:deep(.forms) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    align-self: stretch;
}

:deep(.form-field) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 41.1rem;
    position: relative;
}

.mobile :deep(.form-field) {
    width: -webkit-fill-available;
}

.form-field-hint {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--small-font-size, 1.4rem);
    font-weight: var(--forms-instructions-body-2-font-weight, 400);
    position: relative;
    align-self: stretch;
}


:deep(.list) {
    padding: 1.5rem 0rem 1.5rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.card-container:deep(.display-panel) {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex: 1;
    position: relative;
    width: 53.9rem;
}

.buttons-cards-onboard {
    background: var(--lite-gray-grey-2, #edefef);
    padding: 3.125rem 2.8125rem 2.8125rem 2.8125rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.cards-footnote {
    padding: 0rem 4.5rem 5.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.line-1 {
    border-style: solid;
    border-color: var(--lite-gray-grey-2, #edefef);
    border-width: 0.1rem 0 0 0;
    align-self: stretch;
    flex-shrink: 0;
    height: 0rem;
    position: relative;
}

.footnote {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: left;
    font-size: var(--footers-footnote-1-font-size, 1.4rem);
    font-weight: var(--footers-footnote-1-font-weight, 300);
    position: relative;
    align-self: stretch;
}

.card-container {
    border-radius: 0.8rem;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 103.9rem;
    height: 73.8rem;
    position: relative;
    overflow: hidden;
}

.mobile.card-container {
    max-width: 60rem;
    min-width: 30rem;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
    width: auto;
}

.mobile,
.mobile:deep(.onboarding-stack) {
    background: var(--lite-gray-white, #FFFFFF);
}

.card-container:deep(.text-card) {
    background: var(--lite-gray-white, #FFFFFF);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    width: 50rem;
    position: relative;
}

.mobile:deep(.display-panel) {
    max-height: 33.2rem;
    align-items: center;
    justify-content: center;
    width: auto;
}
</style>