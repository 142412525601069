<template>
    <OnBoardingCard displayImage="/images/resized/image4.png" nextButtonName="Next" :stepIndex="4">
        <div class="h1 reg">
            No keys required to get moving
        </div>
        <div class="block-text b2 reg">
            Your access card allows you to unlock any vehicle you&#039;ve booked.
        </div>
        <div class="form-field">
            <div class="instructions">
                <div class="form-field-label b1 reg">Access card number</div>
                <div class="form-field-hint b2 reg">
                    Input the number on the back of the card into your profile
                </div>
            </div>
            <InputComponent icon-src="payment/card" v-model="accessCardNumber" type="text"
                placeholder="0000 0000" :validation="['required']" @validation="handleKeyPress" />
        </div>
        <div class="buttons">
            <SelectableButton className="light" @click="handleSkipButton">
                <div class="btn-anchor bu1-semi">I don't have an access card</div>
            </SelectableButton>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy" target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            accessCardNumber: '',
            validAccessCardNumber: true,
        }
    },
    components: {
        OnBoardingCard,
    },
    mounted() {
        this.$bus.on('onboarding-next', this.handleSubmit)
    },
    beforeRouteLeave() {
        this.$bus.off('onboarding-next', this.handleSubmit);
    },
    computed: {
        ...mapState(['onboarding']),
    },
    methods: {
        handleSubmit() {
            // Save Preferred Name
            this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {

                rfid: this.accessCardNumber,
            }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                    this.$router.push('/onboarding/all-done');
            }).catch(error => {
                console.error("An error occurred while logging in:", error);
            });
        },
        handleKeyPress(message) {
            if (message === '') {
                this.setNextButtonEnabled(true);
            }
        },
        handleSkipButton() {
            if (this.onboarding.hasPersonalUse) {
                this.$router.push('/onboarding/send-access-card');
            } else {
                this.$router.push('/onboarding/all-done');
            }
        },
        ...mapMutations(['setNextButtonEnabled'])
    }
}
</script>