<template>
    <CardDiv>
        <div class="h3 reg">Upcoming carpools</div>
        <hr />
        <div class="content">
            <template v-if="poolList">
                <RowDiv v-for="hub in Object.keys(poolList)" :key="hub">
                    <RowDiv>
                        <ColDiv class="hub">
                            <div class="b1 semi"> {{ this.hubs && this.hubs[hub]?.name }}</div>
                            <SelectableButton class="light small borderless" @click="openMap(hub)">
                                <SvgIcon name="location/gps" />
                            </SelectableButton>
                        </ColDiv>
                    </RowDiv>
                    <UpcomingCarpool v-for="pool in poolList[hub]" :key="pool.id" :details="getBooking(pool)" />
                </RowDiv>
            </template>
            <RowDiv v-else class="no-data">
                <div class="h4 reg" >No upcoming carpools</div>
            </RowDiv>
        </div>
        <hr v-show="false" />
        <RowDiv class="spread" v-show="false">
            <SelectableButton class="light small">
                <div class="b1 reg">+ Add hub</div>
            </SelectableButton>
            <SelectableButton class="light small borderless">
                <div class="b2 reg">See all carpools</div>
            </SelectableButton>
        </RowDiv>
    </CardDiv>
</template>
<script>
import UpcomingCarpool from './UpcomingCarpool.vue';
import { getAuth, getDateTimeStringFromUnixTime, openMap } from '@/utils/functions';

export default {
    components: {
        UpcomingCarpool
    },
    data() {
        return {
            poolList: null,
            hubs: null,
            vehicles: null,
        }
    },
    beforeMount() {
        this.fetchAvailableCarpools()
    },
    methods: {
        openMap(hub) {
            openMap(this.hubs[hub]);
        },
        getBooking(pool) {
            if (!pool || !this.hubs || !this.vehicles) return null;
            return {
                booking: { ...pool },
                pickup: {
                    hub: { ...this.hubs[pool.pickup] },
                    ...getDateTimeStringFromUnixTime(pool.start)
                },
                dropOff: {
                    hub: { ...this.hubs[pool.drop_off] },
                    ...getDateTimeStringFromUnixTime(pool.end)
                },
                selectedCar: {
                    ...this.vehicles[pool.vehicle_type]
                }
            }
        },
        fetchAvailableCarpools() {
            this.$axios.get(`${this.$env.API_HOST}/booking/available/poolbookings`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(response => {
                const results = Object.values(response.data)
                const availableHubs = results.map(el => { return el.pickup })
                availableHubs.forEach(hub => {
                    if (!this.poolList) this.poolList = {};
                    this.poolList[hub] = results.filter(pool => +pool.pickup === +hub)
                });
            }).catch((e) => {
                console.error('Could not fetch carpool', e)
            });
            this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(hubs => {
                this.hubs = hubs.data;
            }).catch(() => { console.error('Failed fetching hub data') });
            this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`).then(carData => {
                this.vehicles = carData.data
            }).catch((e) => { console.error("Can't fetch vehicle data", e) });
        }
    },
}
</script>
<style scoped>
hr {
    background-color: #dfdfdf;
    margin-bottom: 2rem;
}

.spread {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.content {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.content>.row {
    flex-direction: column;
    width: -webkit-fill-available;
    margin: auto;
}

.no-data {
    align-items: center;
}

.hub {
    align-items: center;
    display: flex;
    gap: .5rem;
}
</style>