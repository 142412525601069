<template>
    <HeroCard>
        <div>
            <SelectableButton :class="[{ 'mobile': isMobile }]" className="borderless light" @click="goBack">
                <SvgIcon name="trip/arrow-left" />
                <div class="bu1 semi">Back</div>
            </SelectableButton>
        </div>
        <div class="h0 reg">Book Trip</div>
        <div class="h3 reg">Choose the best option for your journey</div>
        <template #right>
            <img src="/images/resized/Hero 1.png" alt="hero cars" />
        </template>
    </HeroCard>
    <div class="container">
        <RowDiv >
            <ColDiv v-if="isMobile && isSearching">
                <AccordionCard class="criteria-accordion" >
                    <template #summary>Search criteria</template>
                    <SearchSummary :isSearching="isSearching" />
                </AccordionCard>
            </ColDiv>
            <div class="col-12 col-7-md">
                <CarList v-show="isSearching" :isLoading="isLoading" :searchList="searchResults" />
                <SearchCard @notify="handleNotice" v-show="!isSearching" />
            </div>
            <div class="col-5" v-if="!isMobile">
                <SearchSummary :isSearching="isSearching" />
            </div>
        </RowDiv>
    </div>
    <FooterAlert v-show="!isMobile" ref="toast" :icon="noticeIcon">
        {{ notice }}
    </FooterAlert>
    <div v-if="dateTimePopup" class="modal-overlay">
        <div class="modal">
            <div class="modal-content">
                <CardDiv>
                    <div class="h4 reg">The Pick up Date and Time is set <b>after or the same as</b> Drop off Date and Time
                    </div>
                    <RowDiv >
                        <SelectableButton className="col-3 light small" @click="dateTimePopup = false">
                            <div class="bu3 reg">Close</div>
                        </SelectableButton>
                    </RowDiv>
                </CardDiv>
            </div>
        </div>
    </div>
</template>
<script>
import AccordionCard from '@/components/AccordionCard.vue';
import CarList from './booking/CarList.vue';
import FooterAlert from '@/components/FooterAlert.vue';
import HeroCard from '@/components/HeroCard.vue';
import SearchCard from '@/views/booking/SearchCard.vue';
import SearchSummary from './booking/SearchSummary.vue';
import { mapMutations, mapState } from 'vuex';
import { parseDateTime } from '@/utils/functions';

export default {
    components: {
        AccordionCard,
        CarList,
        HeroCard,
        FooterAlert,
        SearchCard,
        SearchSummary,
    },
    computed: {
        ...mapState(['searchCriteria', 'isMobile'])
    },
    data() {
        return {
            notice: '',
            noticeIcon: '',
            searchResults: null,
            isLoading: false,
            isSearching: false,
            dateTimePopup: false,
        }
    },
    beforeMount() {
        this.$bus.on('search-now', this.fetchData);
        this.$bus.on('reset-search', this.resetSearch);
    },
    mounted() {
        if (this.searchCriteria?.pickup?.date && this.searchCriteria?.pickup?.time &&
            this.searchCriteria?.dropOff?.date && this.searchCriteria?.dropOff?.time) {
            this.fetchData();
        }
    },
    methods: {
        goBack() { 
            if (this.isSearching) {
                this.isSearching = false;
                return;
            }
            this.clearSearchCriteria();
            this.$router.go(-1);
        },
        handleNotice(arg) {
            if (arg === 'return-only') {
                this.notice = 'The Hub you have selected only does return trips';
                this.noticeIcon = 'trip/return';
                this.$refs.toast.showToast()
            }
        },
        resetSearch() {
            this.isSearching = false;
            this.searchResults = null;
        },
        fetchData() {
            const pickUp = parseDateTime(this.searchCriteria?.pickup?.date, this.searchCriteria?.pickup?.time);
            const dropOff = parseDateTime(this.searchCriteria?.dropOff?.date, this.searchCriteria?.dropOff?.time);
            if (pickUp >= dropOff) {
                this.dateTimePopup = true;
            } else {
                this.isLoading = true;
                this.isSearching = true;
                this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`).then(resp => {
                    if (resp.status === 200) {
                        this.searchResults = Object.values(resp.data)
                        this.isLoading = false;
                    }
                }).catch(() => {
                    console.error('No vehicles available');
                });
            }
        },
        ...mapMutations(['clearSearchCriteria'])
    }
}
</script>
<style scoped>
.criteria-accordion {
    padding: 1rem;
}

.modal-content .card {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
}
</style>