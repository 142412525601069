<template>
    <div v-if="visible" class="footer-alerts">
        <SvgIcon :name="icon" class="icon-large" v-if="icon" />
        <div class="bu1 med">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        showToast() {
            this.visible = true;
            setTimeout(this.hideToast, 9000);
        },
        hideToast() {
            this.visible = false;
        },
    },
};
</script>
<style scoped>
.footer-alerts {
    border-radius: 100px;
    background: var(--bg-secondary-color, #042B2F);
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: var(--lite-gray-white, #FFFFFF);
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}
</style>