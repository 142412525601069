<template>
    <div :class="['pill-button', className]">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        className: {
            type: String,
            default: '',
        }
    },
}
</script>
<style scoped>
.pill-button {
    display: inline-flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: lightgray;
    cursor: default;
}

.pill-button.small {
    padding: 5px 15px;
} 

.pill-button.smaller {
    padding: 5px 10px;
} 

.light {
    background: var(--Company-Settings-Fav-Light, #FBF5DC);
}

.secondary {
    background: var(--zilch-public-fav-5, #E1F3FF);
}

.primary {
    background: var(--color-primary, #A4EA6E);
}

.alert {
    background: var(--notification-colours-error-light, #FFECEC);
}
</style>