<template>
    <div v-if="messages.length > 0" class="group-notification">
        <div v-for="(message, i) in messages" :key="i" :class="['notification', { 'error': message.error }]">
            <p>{{ message.text }}</p>
            <p v-if="message.error">Please contact support.</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            messages: [],
            timeOutId: null,
        };
    },
    mounted() {
        this.$bus.on('show-toast-message', this.showNotification);
    },
    methods: {
        showNotification({ text = '', error = false } = {}) {
            this.messages.push({ text, error });
            this.visible = true;
            setTimeout(this.hideToast, 3000);
        },
        hideToast() {
            this.messages.shift();
        }
    }
};
</script>
<style scoped>
.group-notification {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.notification {
    background: #0AAF6C;
    color: var(--lite-gray-white, #FFFFFF);
    text-align: center;
    padding: 1.5rem 2.5rem;
    border-radius: 10rem;
}

p {
    margin: auto;
}

.error {
    background-color: #CA4D4D;
}
</style>