<template>
    <div class="container">
        <CardDiv>
            <RowDiv >
                <div class="col hubs">
                    <RowDiv >
                        <SvgIcon name="trip/arrow-right" class="icon-wide" />
                        <div class="h3 reg">{{ this.pickup?.name }}</div>
                    </RowDiv>
                    <RowDiv >
                        <SvgIcon name="trip/arrow-left" class="icon-wide" />
                        <div class="h3 reg">{{ this.dropOff?.name }}</div>
                    </RowDiv>
                </div>
                <div class="col-2" v-if="isToday">
                    <PillButton>
                        Today
                    </PillButton>
                </div>
            </RowDiv>
            <RowDiv >
                <div class="col-4 detail">
                    <PillButton className="smaller">
                        <span class="b2 reg pointer">
                            Pick up
                        </span>
                    </PillButton>
                    <div>
                        <div class="h3 reg">{{ this.start?.time }}</div>
                        <div class="b1 reg">{{ this.start?.date }} {{ this.start?.day }} {{ this.start?.month }} {{
                            this.start?.year }}</div>
                    </div>
                </div>
                <div class="col-4 detail">
                    <PillButton className="smaller">
                        <span class="b2 reg pointer">
                            Drop off
                        </span>
                    </PillButton>
                    <div>
                        <div class="h3 reg">{{ this.end?.time }}</div>
                        <div class="b1 reg">{{ this.end?.date }} {{ this.end?.day }} {{ this.end?.month }} {{
                            this.end?.year }}</div>
                    </div>
                </div>
                <div class="col-4">
                    <div><img :src="this.vehicle?.image" alt="car" /></div>
                </div>
            </RowDiv>
            <RowDiv class=" footer">
                <div class="b1 semi">{{ this.vehicle?.brand }} {{ this.vehicle?.model }}</div>
                <div class="b1 reg mobile-hidden" v-show="isCarpool">{{(this.booking?.passenger_count || '0')}} passengers</div>
                <div >
                    <PillButton className="small" v-show="false">
                        <span class="b2 reg pointer">Passenger</span>
                    </PillButton>
                </div>
            </RowDiv>
        </CardDiv>
    </div>
</template>
<script>
import { getAuth } from '@/utils/functions';

export default {
    props: {
        booking: {}
    },
    data() {
        return {
            pickup: '',
            dropOff: '',
            start: null,
            end: null,
            vehicle: null,
            isToday: false,
            isCarpool: true,
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`, {
                params: { id: this.booking.vehicle_type }
            }).then(carData => {
                this.vehicle = carData.data[this.booking.vehicle_type];
            }).catch(() => { console.error("Can't fetch vehicle data") });
            this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(hubs => {
                this.pickup = hubs.data[+this.booking.pickup];
                this.dropOff = hubs.data[+this.booking.drop_off];
            }).catch(() => { console.error('Failed fetching hub data') });

            this.start = this.parseDates(this.booking.start);
            this.end = this.parseDates(this.booking.end);
            const todayString = new Date().toDateString();
            const startString = new Date(this.booking.start * 1000).toDateString();
            this.isToday = (todayString === startString);
        },
        parseDates(timestamp) {
            const parseDate = new Date(timestamp * 1000);
            const hour = parseDate.getHours();
            const minute = parseDate.getMinutes();
            const time = ((hour < 10) ? '0' : '') + hour + ':' + ((minute < 10) ? '0' : '') + minute;
            const day = parseDate.toLocaleString('default', { weekday: 'long' })
            const date = parseDate.getDate();
            const month = parseDate.toLocaleString('default', { month: 'long' });
            const mo = parseDate.getMonth();
            const year = parseDate.getFullYear();

            return { time, day, date, month, mo, year };
        },
    },
}
</script>
<style scoped>
.footer {
    justify-content: space-between;
}

.card {
    background-color: #fafafa;
    border-radius: 8px;
}

.container {
    padding: unset;
    margin: auto;
    cursor: pointer;
}

.hubs {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.hubs .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 600px) {
    .detail {
        flex-direction: row;
        display: flex;
        gap: 1rem;
    }

    .mobile-hidden {
        display: none;
    }
}
</style>