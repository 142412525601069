<template>
    <div :class="['card', 'detail-card', { 'light': !isSearch }]">
        <RowDiv >
            <div class="col">
                <PillButton className="smaller">
                    <span class="b2 reg pointer">
                        <slot name="pill-name"></slot>
                    </span>
                </PillButton>
            </div>
            <div class="col-9" v-if="isSearch">
                <RowDiv  v-if="data?.date || data?.time">
                    <div class="col-6 time-detail">
                        <div class="h2 reg" v-if="data?.time">{{ data?.time }}</div>
                        <div class="h2 reg" v-else>&nbsp;</div>
                    </div>
                    <div class="col-6 date-detail">
                        <div class="h3 reg">{{ date }} {{ day }}</div>
                        <div class="b2 reg">{{ month }} {{ year }}</div>
                    </div>
                </RowDiv>
                <hr />
                <RowDiv >
                    <div class="col hub-name">
                        <div class="h3 reg">{{ data?.hub?.name }}</div>
                    </div>
                </RowDiv>
                <RowDiv class=" open-maps">
                    <div class="col">
                        <div class="b2 reg">{{ data?.hub?.address }}</div>
                    </div>
                    <div class="col-2">
                        <SelectableButton class="light small borderless" @click="openMap(data?.hub)">
                            <SvgIcon name="icon/export" />
                        </SelectableButton>
                    </div>
                </RowDiv>
            </div>
            <div class="col-8" v-else>
                <RowDiv >
                    <div class="col hub-name">
                        <div class="h3 reg">{{ data?.hub?.name }}</div>
                    </div>
                </RowDiv>
                <RowDiv >
                    <div class="col time-detail">
                        <div class="h2 reg" v-if="data?.time">{{ data?.time }}</div>
                        <div class="h2 reg" v-else>&nbsp;</div>
                    </div>
                </RowDiv>
                <RowDiv >
                    <div class="col date-detail">
                        <div class="b2 reg">{{ date }} {{ day }} {{ month }} {{ year }}</div>
                    </div>
                </RowDiv>
            </div>
        </RowDiv>
    </div>
</template>
<script>
import { openMap, parseDate } from '@/utils/functions';

export default {
    props: {
        data: [Object, null],
        isSearch: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            day: '',
            date: '',
            month: '',
            year: ''
        }
    },
    mounted() {
        if (this.data?.date) {
            const date = parseDate(this.data.date);
            this.day = date.toLocaleString('default', { weekday: 'long' })
            this.date = date.getDate();
            this.month = date.toLocaleString('default', { month: 'long' });
            this.year = date.getFullYear();
        }
    },
    watch: {
        data: {
            handler(newVal) {
                if (newVal.date) {
                    const date = parseDate(newVal.date)
                    this.day = date.toLocaleString('default', { weekday: 'long' })
                    this.date = date.getDate();
                    this.month = date.toLocaleString('default', { month: 'long' });
                    this.year = date.getFullYear();
                }
            }, deep: true
        }
    },
    methods: {
        openMap(hub) {
            openMap(hub);
        },
    }
}
</script>
<style scoped>
.card.detail-card {
    padding: 2.5rem 1rem;
    background-color: var(--lite-gray-white, #FFFFFF);
}

h2,
h4,
h3,
.hub-name {
    margin-bottom: unset;
}

.hub-name {
    margin-left: .5rem;
    margin-right: .5rem;
}

.date-detail *:first-child {
    margin-bottom: 1rem;
}

.light {
    background-color: rgb(255, 255, 255, 0.6);
}

.open-maps>div[class^="col"] {
    justify-content: center;
    align-content: center;
    margin: 0.5rem;
}
</style>