<template>
    <VueDatepicker v-bind="$props" select-text="Done" cancel-text="Close" now-button-label="Today"
        :config="{ keepActionRow: true }" :prevent-min-max-navigation="true">
        <template #input-icon>
            <SvgIcon name="calendar/calendar" />
        </template>
    </VueDatepicker>
</template>

<script>
import VueDatepicker from '@vuepic/vue-datepicker';

export default {
    name: 'DatePicker',
    components: {
        VueDatepicker
    },
}
</script>
<style scoped>
.icon {
    top: .25rem;
    left: .8rem;
}

.dp__theme_dark,
:deep(.dp__theme_light) {
    --dp-font-size: 1.4rem;
    --dp-preview-font-size: 1.4rem;
    --dp-action-button-height: 3rem;
    --dp-primary-color: var(--color-primary) !important;
    --dp-font-family: 'DM Sans', sans-serif;
    --dp-button-height: 3rem;
    --dp-month-year-row-height: 3rem;
    --dp-month-year-row-button-size: 3rem;
    --dp-button-icon-height: 2rem;
    --dp-cell-size: 3rem;
    --dp-cell-padding: 0.5rem;
    --dp-input-icon-padding: 3rem;
    --dp-input-padding: 6px 30px;
    --dp-menu-min-width: 26rem;
    --dp-action-buttons-padding: 2px 5px;
    --dp-time-inc-dec-button-size: 3rem;
    --dp-range-between-dates-background-color: var(--notification-colours-correct-light, #D6F6BC);
    --dp-hover-color: var(--notification-colours-correct-light, #D6F6BC);
}

:deep(.dp__action_select),
:deep(.dp__range_start),
:deep(.dp__range_end),
:deep(.dp__active_date) {
    color: var(--font-color, #042B2F);
}

:deep(.dp__range_start),
:deep(.dp__range_end),
:deep(.dp__active_date) {
    border-color: var(--bg-secondary-color, #042B2F);
}

:deep(.dp__input) {
    padding-left: 30px;
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    border-radius: .8rem;
}

:deep(.dp__input):hover:not(.dp__input_focus) {
    border-color: var(--input-hover-color);
}

:deep(::placeholder) {
    color: rgba(92, 97, 99, 0.5);
}
</style>