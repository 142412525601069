<template>
    <div class="combobox">
        <div @click="setFocus" :class="['input-wrapper flex flex-wrap border rounded padding relative',
            { 'disabled': isDisabled }, { 'mobile': isMobile }]">
            <PillButton className="secondary small" v-for="item in selectedItems" :key="item.name"
                v-show="!showDropdown">
                <span class="b2 reg pointer" @click="removeItem(item, $event)">{{ item.name }}</span>
            </PillButton>
            <input v-if="!isDisabled" class="input-field" type="text" v-model="inputValue" @input="onInput"
                v-show="showDropdown || (selectedItems.length === 0)" @blur="hideDropdown" @keydown.down="highlightNext"
                @keydown.up="highlightPrevious" ref="inputField" :disabled="isDisabled"
                :placeholder="(selectedItems.length > 0 ? '' : placeholder)" @keydown.enter="selectHighlighted" />
            <div v-else class="b2 semi return-only">Return trips only</div>
            <SvgIcon :name="'icon/' + (showDropdown ? 'up' : 'down')" v-show="!isDisabled" class="dropdown-icon" />
        </div>
        <ul v-show="showDropdown && (displayOptions.length > 0)" class="dropdown background-light border rounded">
            <li v-for="(option, index) in displayOptions" :key="option"
                :class="['pointer', 'options', option.type, { highlighted: index === highlightedIndex }]"
                @mousedown="selectOption(option)">
                <SvgIcon :name="this.icons[option.type]" class="col-1 icon-wide" v-if="!isMobile && option.type" />
                <div class="col">
                    <div class="b1 semi">{{ option.name }}</div>
                    <div class="b2 reg">{{ option.address }}</div>
                </div>
                <div class="col-2" v-if="!isMobile && option?.distance">
                    <div class="h4">{{ option.distance.distance }} {{ option.distance.dim }}</div>
                </div>
                <div class="col-1 is-right" v-if="!isMobile">
                    <SvgIcon v-if="option.direction" :name="'trip/' + (option.direction)" class="icon-wide padded" />
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        multiSelect: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Select a hub'
        },
        options: {
            type: Array,
            required: true
        },
        selected: [Object, null],
        isDisabled: Boolean
    },
    data() {
        return {
            inputValue: "",
            filteredOptions: [],
            internalOptions: [],
            selectedItems: [],
            showDropdown: false,
            highlightedIndex: -1,
            icons: { public: 'buildings/offices', company: 'buildings/office', private: 'buildings/home' }
        };
    },
    computed: {
        availableOptions() {
            return this.options.filter(option => !this.selectedItems.some(selected => selected.name === option.name));
        },
        displayOptions() {
            const filtered = this.availableOptions.filter(option =>
                option.name.toLowerCase().includes(this.inputValue.toLowerCase()) ||
                option.address.toLowerCase().includes(this.inputValue.toLowerCase())
            );
            filtered.sort((a, b) => (a.distance?.distance - b.distance?.distance));
            if (this.inputValue.length >= 5) {
                return filtered.slice(0, 10);
            }
            if (filtered.length <= 10) {
                return filtered;
            }
            return filtered.slice(0, 10);
        },
        ...mapState(['isMobile']),
    },
    mounted() {
        this.internalOptions = this.options;
        if (this.filteredOptions.length === 0) {
            this.filteredOptions = this.options;
        }
        if (this.selected) {
            this.selectedItems = [this.selected];
        }
    },
    methods: {
        setFocus() {
            this.showDropdown = !this.showDropdown;
            this.$nextTick(() => {
                if (this.showDropdown) this.$refs.inputField.focus();
            });
        },
        onInput() {
            this.highlightedIndex = -1;
        },
        selectOption(option) {
            if (this.multiSelect) {
                if (!this.selectedItems.some(item => item.name === option.name)) {
                    this.selectedItems.push(option);
                }
            } else {
                this.selectedItems = [option];
            }
            this.inputValue = "";
            this.showDropdown = false;
            this.$emit('change', option);
        },
        removeItem(item, event) {
            event.stopPropagation();
            event.preventDefault();
            if (this.isDisabled) return;
            this.selectedItems = this.selectedItems.filter(selected => selected.name !== item.name);
            this.$emit('change', null);
        },
        clearSelection() {
            this.selectedItems = [];
        },
        hideDropdown() {
            this.$nextTick(() => {
                this.showDropdown = false;
            });
        },
        highlightNext() {
            if (this.highlightedIndex < this.displayOptions.length - 1) {
                this.highlightedIndex++;
            }
        },
        highlightPrevious() {
            if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
            }
        },
        selectHighlighted() {
            if (this.highlightedIndex >= 0 && this.highlightedIndex < this.displayOptions.length) {
                this.selectOption(this.displayOptions[this.highlightedIndex]);
            }
        },
    },
    watch: {
        inputValue() {
            this.highlightedIndex = -1;
        },
        options: {
            handler(newValue) {
                this.internalOptions = newValue;
            },
            deep: true,
            immediate: true
        },
        selected(newVal) {
            if (newVal) {
                this.selectedItems = [newVal];
            } else {
                this.clearSelection();
            }
        }
    },
};
</script>

<style scoped>
.combobox {
    position: relative;
}

.input-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: .25rem 0px .25rem 2rem;
    position: relative;
    width: 100%;
    border-radius: 8px;
    background-color: var(--lite-gray-white, #FFFFFF);
    border: 1px solid var(--lite-gray-white, #FFFFFF);
    justify-content: space-between;
}

.input-wrapper.disabled {
    background-color: transparent;
    border: 1px solid transparent;
    justify-content: flex-start;
}

.input-wrapper:hover:not(.disabled) {
    border-color: var(--input-hover-color);
}

.input-field {
    border: none;
    flex-grow: 1;
    position: relative;
    padding: 4px;
    box-sizing: border-box;
    width: auto;
    background-color: transparent;
    font-size: 1.6rem;
    min-width: 27.5rem;
}

.pill {
    display: flex;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.icon.dropdown-icon {
    margin: .75rem;
    top: 0;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid transparent;
    z-index: 1000;
    margin: auto;
    padding-inline-start: unset;
    border-radius: .8rem;
    background: var(--lite-gray-white, #FFFFFF);
    box-shadow: 0px 6px 15px 0px rgba(4, 43, 47, 0.10);
}

.dropdown li:hover,
.dropdown li.highlighted {
    background-color: #A4EA6E;
}

.options {
    display: flex;
    padding: 1rem 1rem 1rem 1.5rem;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
}

.icon.padded {
    margin: .75rem;
    left: -1rem;
}

.icon {
    top: .5rem;
    object-fit: cover;
    object-position: center;
}

.mobile.input-wrapper {
    padding: .5rem;
}

.return-only {
    display: flex;
    align-items: center;
    padding: 0 2rem;
}
</style>