<template>
    <div class="loading-container">
        <div class="dot" v-for="n in 4" :key="n"></div>
    </div>
</template>

<style scoped>
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    height: 1rem;
}

.loading-container.large {
    height: 18rem;
    gap: 1.5rem;
}

.dot {
    width: 1rem;
    height: 1rem;
    background-color: #9cccf8;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
}

.dark .dot {
    background-color: #06454B;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

.dot:nth-child(3) {
    animation-delay: 0s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
</style>