<template>
    <OnBoardingCard displayImage="/images/resized/car0.png">
        <div class="h1 reg">
            Welcome to Zilch
        </div>
        <RowDiv>
            <div class="col">
                <div class="b1 reg">
                    Don't have an account?
                    <a href="/onboarding/join">Sign up</a>
                </div>
            </div>
        </RowDiv>
        <div class="form-field">
            <div class="form-field-label b1 reg">Email address</div>
            <InputComponent icon-src="email/email" v-model="emailValue" type="text" auto-fill="email"
                placeholder="Enter your registered email" :validation="['required', 'email']" @enter="doLogin"
                @validation="(message) => handleKeyPress('email', message)" />
        </div>
        <div class="form-field">
            <div class="form-field-label b1 reg">Password</div>
            <InputComponent icon-src="lock/locked" v-model="password" auto-fill="password" placeholder=""
                :validation="['required']" type="password" @enter="doLogin"
                @validation="(message) => handleKeyPress('password', message)" />
        </div>
        <div class="form-field ">
            <div v-show="showAlert" class="card-alerts">
                Your password or email is incorrect.
            </div>
        </div>
        <div class="buttons">
            <SelectableButton @click="doLogin" :isLoading="isLoading">
                <div class="btn-anchor bu1-semi">Login</div>
            </SelectableButton>
        </div>
        <RowDiv>
            <div class="col">
                <a href="#" class="b2 reg" @click="forgotPassword = true">Forgot password</a>
            </div>
        </RowDiv>
    </OnBoardingCard>
    <div v-show="forgotPassword" class="modal-overlay">
        <PopUpModal @close="forgotPassword = false" class="forgot-password">
            <CardDiv>
                <div class="h3 reg">Forgot password</div>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">If you have an account we will send you an email to reset your password.</p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col field">
                        <div class="b1 semi">Email address</div>
                        <InputComponent v-model="emailValue" type="text" placeholder="Email address"
                            className="white" />
                    </div>
                </RowDiv>
                <RowDiv>
                    <ColDiv cols="4">
                        <SelectableButton className="light small" :isLoading="isSending" @click="sendEmail">
                            <div class="button-light">Reset password</div>
                        </SelectableButton>
                    </ColDiv>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <div v-show="emailSent" class="modal-overlay">
        <AlertModal @close="emailSent = false" class="feedback" text="Close">
            <div class="h3 reg">Password reset</div>
            <div class="b1 reg">We have sent you an email to reset your password if you have an account.</div>
        </AlertModal>
    </div>
</template>

<script>
import AlertModal from '@/components/AlertModal.vue';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import PopUpModal from '@/components/PopUpModal.vue';
import { mapMutations, mapActions } from 'vuex';
export default {
    data() {
        return {
            emailValue: "",
            password: '',
            showAlert: false,
            isValid: {
                email: false,
                password: false,
            },
            isLoading: false,
            forgotPassword: false,
            emailSent: false,
            isSending: false,
        };
    },
    components: {
        AlertModal,
        OnBoardingCard,
        PopUpModal,
    },
    methods: {
        doLogin() {
            this.showAlert = false;
            if (this.isValid.email && this.isValid.password && !this.isLoading) {
                this.isLoading = true;
                this.login({ email_address: this.emailValue, password: this.password }).then(() => {
                    this.isLoading = false;
                    this.$router.push({ name: 'Home' });
                }).catch(() => {
                    this.isLoading = false;
                    this.showAlert = true;
                });
            } else {
                this.showAlert = true;
            }
        },
        sendEmail() {
            this.isSending = true;
            this.$axios.post(`${this.$env.API_HOST}/auth/resetPassword`, {
                email_address: this.emailValue,
                url: `${window.location.host}/reset/`
            }).then(() => {
                this.isSending = false;
                this.forgotPassword = false;
                this.emailSent = true;
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'There was an error sending your reset password link.',
                    error: true,
                });
                console.error('Could not send your reset email...')
            });
        },
        handleKeyPress(key, message) {
            this.isValid[key] = !message;
        },
        ...mapMutations(['setOnboarding']),
        ...mapActions(['login'])
    },
};
</script>

<style scoped>
.join-1-b-100-300-sign-up {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.onboarding-cards-log-in {
    background: var(--lite-gray-white, #FFFFFF);
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 46.125rem;
    position: relative;
    overflow: hidden;
}

.card-alerts {
    background: var(--notification-colours-error-light, #FFECEC);
    border-radius: 0.8rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.cancelled-booking {
    color: var(--notification-colours-dark-error, #872121);
    text-align: left;
    font-size: var(--buttons-body-1-font-size, 1.6rem);
    line-height: var(--buttons-body-1-line-height, 2.2rem);
    font-weight: var(--buttons-body-1-font-weight, 400);
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.forgot-password {
    width: 55%;
}

.forgot-password :deep(.modal-content .card) {
    gap: 3rem;
    display: flex;
    flex-direction: column;
}
</style>