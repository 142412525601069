<template>
    <div :class="['container', { 'expiring': expiring }]" v-show="!hideThis">
        <RowDiv class=" header">
            <div class="col timer-column">
                <RowDiv>
                    <div class="col">
                        <RowDiv v-if="(expiring)">
                            <SvgIcon name="feedback/error" class="icon-wide" />
                            <div class="b1 reg">This booking will expire in {{ moment(expireTime) }} </div>
                        </RowDiv>
                        <RowDiv v-else>
                            <SvgIcon name="car/car" color="white" class="icon-wide" />
                            <div class="b1 reg" v-if="this.details?.state === 'approved'">
                                Your booking starts in {{ moment(startTime) }}
                            </div>
                            <div class="b1 reg" v-else>Your active booking</div>
                        </RowDiv>
                    </div>
                </RowDiv>
            </div>
            <div class="col-2" v-if="isUserIsDriver">
                <SelectableButton class="small" v-show="this.details?.state === 'approved'" @click="startBooking">
                    <div class="bu1-reg">Start booking</div>
                    <SvgIcon name="trip/arrow-right" />
                </SelectableButton>
            </div>
            <div class="col-1 is-right " v-if="!isMobile" @click="hideThis = true">
                <SvgIcon name="icon/close" class="icon-wide pointer" />
            </div>
        </RowDiv>
        <RowDiv class=" contents">
            <RowDiv v-if="isMobile">
                <div class="col hub-details">
                    <RowDiv>
                        <SvgIcon name="trip/arrow-right" class="icon-wide" />
                        <div class="h2 reg">{{ this.pickup?.name }}</div>
                    </RowDiv>
                    <RowDiv>
                        <SvgIcon name="trip/arrow-left" class="icon-wide" />
                        <div class="h2 reg">{{ this.dropOff?.name }}</div>
                    </RowDiv>
                    <RowDiv>
                        <CardDiv class="container find-my-car">
                            <SvgIcon name="location/gps" />
                            <div class="b2 reg">Find vehicle at</div>
                            <div class="b2 reg pointer" @click="openMap(this.pickup)"> {{ this.pickup?.name }}</div>
                        </CardDiv>
                    </RowDiv>
                </div>
                <div class="col-3-md car-details">
                    <RowDiv>
                        <div class="b1 reg">{{ this.vehicle?.name }}</div>
                        <div class="plate-number">
                            <div class="h3 semi">{{ this.vehicle?.rego }}</div>
                        </div>
                    </RowDiv>
                    <div class="image"><img :src="this.vehicle?.image" alt="car" /></div>
                </div>
            </RowDiv>
            <RowDiv v-else>
                <div class="col-2 qr-code" v-if="this.vehicle?.qr_link">
                    <div>
                        <QRImage :text="this.vehicle?.qr_link" />
                    </div>
                    <div class="b2 reg">Scan to manage your booking</div>
                </div>
                <div class="col hub-details">
                    <RowDiv>
                        <SvgIcon name="trip/arrow-right" class="icon-wide" />
                        <div class="h2 reg"> {{ this.pickup?.name }}</div>
                    </RowDiv>
                    <RowDiv>
                        <SvgIcon name="trip/arrow-left" class="icon-wide" />
                        <div class="h2 reg">{{ this.dropOff?.name }}</div>
                    </RowDiv>
                    <RowDiv>
                        <PillButton class="light">
                            <SvgIcon name="location/gps" />
                            <span class="b2 reg pointer" @click="openMap(this.pickup)">
                                Find vehicle at {{ this.pickup?.name }}
                            </span>
                        </PillButton>
                    </RowDiv>
                </div>
                <div class="col-3 car-details">
                    <div class="image"><img :src="this.vehicle?.image" alt="car" /></div>
                    <RowDiv>
                        <div class="b1 reg">{{ this.vehicle?.name }}</div>
                        <div class="plate-number">
                            <div class="h3 semi">{{ this.vehicle?.rego }}</div>
                        </div>
                    </RowDiv>
                </div>
            </RowDiv>
            <BookingAccordion :details="bookingDetails" :class="['maxed', { 'mobile': isMobile }]">
                <template #buttons v-if="!isMobile && isUserIsDriver">
                    <div class="buttons is-right">
                        <SelectableButton class="light borderless small" @click="cancelBooking">
                            <SvgIcon name="icon/trash" />
                            <div class="bu3 reg">{{ this.details?.state === 'approved' ? 'Cancel' : 'End' }} booking
                            </div>
                        </SelectableButton>
                        <SelectableButton class="light borderless small" v-show="false">
                            <SvgIcon name="calendar/clock" />
                            <div class="bu3 reg">Change start time</div>
                        </SelectableButton>
                    </div>
                </template>
            </BookingAccordion>
            <RowDiv v-if="isMobile && isUserIsDriver">
                <div class="buttons is-right mobile">
                    <SelectableButton class="light borderless small" @click="cancelBooking">
                        <SvgIcon name="icon/trash" />
                        <div class="bu3 reg">{{ this.details?.state === 'approved' ? 'Cancel' : 'End' }} booking</div>
                    </SelectableButton>
                    <SelectableButton class="light borderless small" v-show="false">
                        <SvgIcon name="calendar/clock" />
                        <div class="bu3 reg">Change start time</div>
                    </SelectableButton>
                </div>
            </RowDiv>
        </RowDiv>
    </div>
</template>
<script>
import BookingAccordion from '../booking/BookingAccordion.vue';
import QRImage from '@/components/QRImage.vue';
import { getAuth, openMap } from '@/utils/functions';
import { mapState } from 'vuex';

export default {
    components: {
        BookingAccordion,
        QRImage,
    },
    props: {
        details: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            pickup: null,
            dropOff: null,
            start: null,
            end: null,
            bookingDetails: null,
            vehicle: null,
            hideThis: false,
        }
    },
    computed: {
        expiring() {
            if (!this.details) return false;
            if (this.details.state === 'inuse') return false;
            return ((+this.details.start) * 1000) <= Date.now();
        },
        startTime() {
            return Math.floor(((+this.details.start * 1000) - Date.now()) / (1000 * 60));
        },
        expireTime() {
            if (!this.details || !this.details.expiry_time) return this.startTime + 15;
            return Math.floor(((+this.details.expiry_time * 1000) - Date.now()) / (1000 * 60));
        },
        isUserIsDriver() {
            const myEmail = this.profile?.email_addresses.find(email => email.primary === 'true');
            const driver = this.details?.driver;
            return myEmail?.email_address === driver.email;
        },
        ...mapState(['isMobile', 'profile'])
    },
    emits: ['cancel'],
    beforeMount() {
        this.fetchData();
    },
    methods: {
        openMap(hub) { 
            openMap(hub)
        },
        fetchData() {
            this.start = this.parseDates(this.details.start);
            this.end = this.parseDates(this.details.end);
            this.bookingDetails = {
                booking: { ...this.details },
                pickup: {
                    date: `${this.start.date}-${this.start.mo + 1}-${this.start.year}`,
                    time: this.start.time
                },
                dropOff: {
                    date: `${this.end.date}-${this.end.mo + 1}-${this.end.year}`,
                    time: this.end.time
                }
            }
            this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`, {
                params: { id: this.details.vehicle_type }
            }).then(carData => {
                this.vehicle = {
                    ...carData.data[this.details.vehicle_type],
                    name: this.details.vehicle_name,
                    rego: this.details.vehicle_registration,
                    qr_link: this.details.qr_link,
                };
                this.bookingDetails.selectedCar = this.vehicle;
            }).catch((e) => { console.error("Can't fetch vehicle data", e) });
            this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(hubs => {
                this.pickup = hubs.data[+this.details.pickup];
                this.dropOff = hubs.data[+this.details.drop_off];
                this.bookingDetails.pickup.hub = this.pickup;
                this.bookingDetails.dropOff.hub = this.dropOff;
            }).catch(() => { console.error('Failed fetching hub data') });
        },
        startBooking(event) {
            event.stopPropagation();
            event.preventDefault();
            this.$emit('start', this.details.id);
        },
        cancelBooking(event) {
            event.stopPropagation();
            event.preventDefault();
            this.$emit('cancel', this.details.id);
        },
        parseDates(timestamp) {
            const parseDate = new Date(timestamp * 1000);
            const hour = parseDate.getHours();
            const minute = parseDate.getMinutes();
            const time = ((hour < 10) ? '0' : '') + hour + ':' + ((minute < 10) ? '0' : '') + minute;
            const day = parseDate.toLocaleString('default', { weekday: 'long' })
            const date = parseDate.getDate();
            const month = parseDate.toLocaleString('default', { month: 'long' });
            const mo = parseDate.getMonth();
            const year = parseDate.getFullYear();

            return { time, day, date, month, mo, year };
        },
        moment(minutes) {
            const hours = Math.floor(minutes / 60);
            const mins = Math.floor(minutes % 60);
            return (hours ? `${hours} hours and ` : '') + `${mins} minutes`;
        }
    },
}
</script>
<style scoped>
.container {
    padding: 0 6rem;
}

.container.expiring .header {
    background: var(--notification-colours-error, #CA4D4D);
}

.container.expiring .contents {
    background: var(--notification-colours-error-10, rgba(255, 100, 100, 0.15));
}

.container.expiring :deep(summary) {
    background: var(--notification-colours-error-10, rgba(255, 100, 100, 0.15));
}

.header {
    padding: 2.5rem 4.5rem;
    background-color: #042B2F;
    color: var(--lite-gray-white, #FFFFFF);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin: auto;
    align-items: center;
}

.row.header div[class^="col"] {
    margin-bottom: 0;
}

.timer-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contents {
    padding: 4.5rem;
    background: var(--lite-gray-grey-1-50percent);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin: auto;
    gap: 2.5rem;
}

.contents .row {
    align-items: center;
    gap: 1rem;
    width: -webkit-fill-available;
}

.hub-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.hub-details div.row:last-child {
    margin-top: 1.5rem;
}

.car-details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.maxed {
    width: -webkit-fill-available;
    padding: 0;
    margin: 0 -4.4rem -4.4rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.buttons.mobile {
    margin-top: 4.5rem;
    margin-bottom: -2.5rem;
}

:deep(.full-width .card),
:deep(summary) {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.maxed.mobile,
.maxed.mobile :deep(summary),
:deep(details[open] summary) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

summary>.icon,
summary>.b1.semi {
    margin-right: 1rem;
}

.buttons.is-right {
    margin-left: auto;
    display: flex;
    gap: 1rem;
}

.car-details .row {
    justify-content: space-between;
    align-items: center
}

.car-details div.image {
    height: 13rem;
    display: grid;
    justify-content: center;
}

.car-details img {
    align-self: stretch;
    flex: 1;
    position: relative;
    object-fit: contain;
    max-height: 13rem;
}

.plate-number {
    border: 1px solid #042B2F;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;
}

.qr-code :deep(img) {
    width: 19rem;
}

.qr-code {
    display: grid;
    justify-items: center;
    gap: 0.5rem;
}

.timer-column .row div[class^='col'] .row {
    gap: 1rem;
}

.card.container.find-my-car {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
}

.pointer {
    cursor: pointer;
}
</style>