export function getLongLatDistance(coords1, coords2) {
    const earthRadius = 6371;
    const dLat = toRad(+coords2.latitude - +coords1.latitude);
    const dLong = toRad(+coords1.longitude - +coords2.longitude);
    const lat1 = toRad(+coords1.latitude);
    const lat2 = toRad(+coords2.latitude);

    const x = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));
    return earthRadius * y;
}

export function parseDate(date) {
    const [day, month, year] = date.split('-');
    return new Date(`${year}-${month}-${day}`);
}

export function parseDateTime(date, time) {
    const [day, month, year] = date.split('-');
    return new Date(`${year}-${month}-${day} ${time}`);
}

export function getDateTimeStringFromUnixTime(unixtime) {
    const date = new Date(unixtime * 1000);
    const hours = ((date.getHours() < 10) ? '0' : '') + date.getHours();
    const minutes = ((date.getMinutes() < 10) ? '0' : '') + date.getMinutes();
    return {
        date: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`,
        time: `${hours}:${minutes}`,
    }
}

export function getMinTime(refDate, offset = null) {
    const minDate = parseDate(refDate);
    const today = new Date();
    if (offset) {
        const pickup = parseDateTime(offset.date, offset.time);
        if (pickup.getHours() === 23) return 0;
        if (pickup.toDateString() === minDate.toDateString()) return pickup.getHours() + 1;
        if (today.toDateString() === minDate.toDateString()) return today.getHours() + 1;
    }
    if (today.toDateString() === minDate.toDateString()) return today.getHours();
    return 0;
}

export function openMap(hub) {
    if (!hub) return;
    window.open(`https://www.google.com/maps/search/?api=1&query=${hub.latitude},${hub.longitude}`);
}

export function setAuth(token) { 
    sessionStorage.setItem('accToken', token.access_token);
    sessionStorage.setItem('refToken', token.refresh_token);
    localStorage.setItem('accToken', token.access_token);
}

export function getAuth() { 
    return sessionStorage.getItem('accToken') ?? localStorage.getItem('accToken');
}

function toRad(value) {
    return +value * (Math.PI / 180);
}