<template>
    <div v-if="isLoading">
        <LoadingDots class="large" />
    </div>
    <div v-else-if="isValid" :class="['card car-selection', {'carpool': internalCar.isCarpool}]">
        <RowDiv  @click="handleClick">
            <div class="col-4 hero" v-if="internalCar.isCarpool">
                <div class="hero-image">
                    <img :src="internalCar.image" alt="car hero" />
                </div>
                <div class="hover-overlay">
                    <SvgIcon name="people/group" />
                    <div>Join carpool</div>
                </div>
            </div>
            <div class="col-4 hero" v-else>
                <div class="hero-image">
                    <img :src="internalCar.image" alt="car hero" />
                </div>
                <div class="hover-overlay">
                    <SvgIcon name="car/car" />
                    <div>Book vehicle</div>
                </div>
            </div>
            <div class="col-8">
                <RowDiv >
                    <div class="col-8">
                        <div class="h3 reg">{{ internalCar.brand }} {{ internalCar.model }}</div>
                    </div>
                    <div class="col-4 is-right" v-if="internalCar.isCarpool">
                        <PillButton className="primary">
                            <SvgIcon name="people/group" />
                            <div>Passenger</div>
                        </PillButton>
                    </div>
                    <div class="col-4 is-right" v-else>
                        <PillButton>
                            <SvgIcon name="car/car" />
                            <div>Vehicle</div>
                        </PillButton>
                    </div>
                </RowDiv>
                <div v-if="false" class="card blue"> <!-- not shown yet -->
                    <div class="b2 semi">Available at</div>
                    <RowDiv >
                        <div class="col">
                            <div class="h3 reg">13:00</div>
                            <div class="b1 reg">Thursday 21st</div>
                            <div class="b2 reg">August 2024</div>
                        </div>
                        <div class="trip-direction">
                            <SvgIcon name="trip/arrow-right" class="icon-wide" />
                        </div>
                        <div class="col">
                            <div class="h3 reg">18:00</div>
                            <div class="b1 reg">Friday 22nd</div>
                            <div class="b2 reg">August 2024</div>
                        </div>
                    </RowDiv>
                </div>
                <div class="b1 semi highlight">Range at full charge</div>
                <RowDiv >
                    <div class="col list">
                        <SvgIcon name="menu/trips" />
                        <div class="b1 reg">{{ internalCar.range }} kms</div>
                    </div>
                    <div class="col list" v-show="false">
                        <SvgIcon name="battery/charge" />
                        <div class="b1 reg">28kWh</div>
                    </div>
                </RowDiv>
                <div class="b1 semi highlight">Fits</div>
                <RowDiv >
                    <div class="col list">
                        <SvgIcon name="people/passengers" />
                        <div class="b1 reg">{{ internalCar.passengers }} passengers</div>
                    </div>
                    <div class="col list">
                        <SvgIcon name="car/suitcase" />
                        <div class="b1 reg">{{ internalCar.luggage }} suitcases</div>
                    </div>
                </RowDiv>
            </div>
        </RowDiv>
    </div>
</template>
<script>
import LoadingDots from '@/components/LoadingDots.vue';
import { mapState } from 'vuex';

export default {
    props: {
        car: {
            type: Object,
            default: () => { },
        },
        alternativeSchedule: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        LoadingDots,
    },
    computed: {
        ...mapState(['searchCriteria']),
    },
    data() {
        return {
            isLoading: false,
            internalCar: {},
            isValid: true,
        }
    },
    mounted() {
        this.loadCar();
    },
    emits: ['book','join'],
    methods: {
        handleClick() { 
            if (this.car.isCarpool) {
                this.$emit('join', this.internalCar)
            } else {
                this.$emit('book', this.internalCar)
            }
        },
        loadCar() {
            this.internalCar = this.car;
        }
    }
}
</script>
<style scoped>
.card.blue {
    padding: 1.5rem;
    background: var(--Colour-Teal-1-O, rgba(186, 240, 224, 0.50));
    margin-bottom: 1.5rem;
    margin-right: -2rem;
}

.card.blue h3 {
    margin: auto;
}

.card.blue .trip-direction {
    display: flex;
    align-items: center;
}

.car-selection {
    padding: 2rem;
    width: -webkit-fill-available;
    border: 2px solid transparent;
    cursor: pointer;
}

.list {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.card {
    border-radius: 8px;
}

.hero {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-image {
    display: contents;
    flex: 1;
    min-height: 25rem;
}

.carpool .hero {
    background: linear-gradient(324deg, #C8F4A621.07%, rgba(228, 240, 236, 0.50)78.93%);
}

.hover-overlay {
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
    transition: all 0.2s ease;
    border-radius: 5px;
    padding: .75rem;
    width: max-content;
    gap: .5rem
}

.car-selection:hover .hover-overlay {
    bottom: 50%;
    transform: translate(-50%, 50%);
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    backdrop-filter: blur(2px);
    background: rgba(237, 248, 255, 0.70);
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    gap: 1.5rem;
}

.carpool:hover .hover-overlay {
    background: linear-gradient(324deg, #C8F4A621.07%, rgba(228, 240, 236, 0.50)78.93%);
}

.car-selection:hover {
    border: 2px solid var(--bg-secondary-color, #042B2F);
    background: rgba(237, 248, 255, 0.70);
}

.carpool {
    border-radius: 8px;
}

.carpool:hover {
    border-radius: 8px;
    background: var(--Primary-Colour-Set-Carpool-Gradient, linear-gradient(324deg, #C8F4A6 21.07%, #E4F0EC 78.93%));
}

.car-selection:hover .hover-overlay .icon,
.car-selection:hover .hover-overlay :deep(.icon > .svg-container),
.car-selection:hover .hover-overlay :deep(.icon > .svg-container > svg) {
    width: 5.8rem;
    height: 5.8rem;
}

@media (max-width: 600px) {
    .col.list {
        margin-bottom: auto;
    }

    .list .h3 {
        font-size: 1.4rem;
    }

    .hero {
        min-height: 20rem;
    }
}
</style>