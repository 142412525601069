<template>
    <div class="display-panel">
        <img :class="['image', imgExtraClasses]" :src="imgSrc" alt="Display" />
        <CardBottomNavigation :stepIndex="stepIndex" :buttonText="buttonText" :iconName="iconName" />
    </div>
</template>

<script>
import CardBottomNavigation from './CardBottomNavigation.vue';
export default {
    components: {
        CardBottomNavigation,
    },
    props: {
        imgSrc: String,
        imgExtraClasses: String,
        buttonText: String,
        iconName: String,
        stepIndex: Number,
    },
};
</script>

<style scoped>
.display-panel {
    background: var(--lite-gray-grey-5, #dfdfdf);
}

.image {
    align-self: stretch;
    flex: 1;
    position: relative;
    object-fit: cover;
}

.frame-2 {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0rem;
    top: 0rem;
}

img.flex {
    flex: 1;
    position: absolute;
    object-fit: cover;
}
</style>