<template>
    <div class="container">
        <CardDiv class="booking">
            <div class="booking-header">
                <RowDiv>
                    <SelectableButton class="light borderless small" @click="this.$router.push({ name: 'Home' })">
                        <SvgIcon name="trip/arrow-left" class="icon-wide" />
                        <div class="bu1 semi">Back</div>
                    </SelectableButton>
                    <div class="col-12">
                        <div class="h3 reg">
                            <SvgIcon name="car/car" class="icon-wide" />
                            <div>Your booking</div>
                        </div>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <DetailCard :isSearch="false" :data="pickup">
                            <template #pill-name>
                                <div class="b3 semi">Pick-up</div>
                            </template>
                        </DetailCard>
                    </div>
                    <div class="col">
                        <DetailCard :isSearch="false" :data="dropOff">
                            <template #pill-name>
                                <div class="b3 semi">Drop-off</div>
                            </template>
                        </DetailCard>
                    </div>
                    <div class="col-3 car-preview">
                        <div><img :src="selectedCar?.image" alt="car" /></div>
                        <div class="b2 semi row">
                            <div>{{ selectedCar?.brand }} {{ selectedCar?.model }}</div>
                            <PillButton>
                                <span class="b2 reg pointer">
                                    <SvgIcon name="calendar/clock" />
                                    {{ tripDuration }} hours
                                </span>
                            </PillButton>
                        </div>
                    </div>
                </RowDiv>
            </div>
            <BookingAccordion :details="{ booking: internalData, pickup, dropOff, selectedCar }" :open="true" />
            <CardDiv class="buttons">
                <RowDiv>
                    <div class="col is-center">
                        <SelectableButton class="light" v-show="false">
                            <div class="b1 reg">Edit booking</div>
                        </SelectableButton>
                        <SelectableButton class="dark" @click="this.popUpCancel = true">
                            <div class="b1 reg">{{ isUserIsDriver ? 'Cancel' : 'Leave' }} booking</div>
                        </SelectableButton>
                    </div>
                </RowDiv>
            </CardDiv>
        </CardDiv>
    </div>
    <div v-show="popUpCancel" class="modal-overlay">
        <ConfirmModal @close="popUpCancel = false" class="popup" @confirm="cancelBooking">
            <CardDiv>
                <RowDiv>
                    <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                </RowDiv>
                <RowDiv>
                    <p class="b1 reg">Are you sure you want to cancel this booking?</p>
                </RowDiv>
            </CardDiv>
        </ConfirmModal>
    </div>
</template>
<script>
import BookingAccordion from './BookingAccordion.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import DetailCard from '@/components/DetailCard.vue';
import { getAuth, getDateTimeStringFromUnixTime, openMap, parseDate, parseDateTime } from '@/utils/functions';
import { mapState } from 'vuex';

export default {
    components: {
        BookingAccordion,
        ConfirmModal,
        DetailCard,
    },
    props: ['id'],
    data() {
        return {
            pickup: null,
            dropOff: null,
            selectedCar: null,
            internalData: null,
            popUpCancel: false,
            booking: null,
        }
    },
    computed: {
        tripDuration() {
            if (!this.pickup && !this.dropOff) return 0;
            const start = parseDateTime(this.pickup?.date, this.pickup?.time).getTime();
            const end = parseDateTime(this.dropOff?.date, this.dropOff?.time).getTime();
            const diffTime = Math.abs(end - start);
            return Math.floor(diffTime / (1000 * 60 * 60));
        },
        isUserIsDriver() {
            const myEmail = this.profile?.email_addresses.find(email => email.primary === 'true')
            const driverEmail = this.internalData?.driver?.email
            return myEmail?.email_address === driverEmail;
        },
        ...mapState(['profile'])
    },
    async beforeMount() {
        const currentBooking = JSON.parse(sessionStorage.getItem('currentBooking'));
        this.pickup = { ...getDateTimeStringFromUnixTime(currentBooking.start) };
        this.dropOff = { ...getDateTimeStringFromUnixTime(currentBooking.end) };
        try {
            const hubs = await this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            });
            this.pickup = {
                ...this.pickup,
                hub: hubs.data[currentBooking.pickup]
            };
            this.dropOff = {
                ...this.dropOff,
                hub: hubs.data[currentBooking.drop_off]
            };
            const vehicleTypes = await this.$axios.get(`${this.$env.API_HOST}/referenceData/vehicleTypes`);
            this.selectedCar = {
                ...vehicleTypes.data[currentBooking.vehicle_type],
                name: currentBooking.vehicle_name,
                rego: currentBooking.vehicle_registration,
            }
        } catch (error) {
            console.error(`Error fetching booking details (${currentBooking.id})`, error)
        }
        this.internalData = currentBooking;
    },
    methods: {
        openMap(hub) {
            openMap(hub)
        },
        cancelBooking() {
            this.$axios.put(`${this.$env.API_HOST}/booking/${this.isUserIsDriver ? 'cancel' : 'leave'}`,
                { booking_id: this.id },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                this.$router.replace({ name: 'Home' })
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'An error was encountered while leaving/canceling your booking.',
                    error: true,
                });
                console.error('Error cancelling/leaving your booking');
            });
        },
        formatDate(dateString) {
            if (!dateString) return;
            const date = parseDate(dateString);
            const day = date.toLocaleString('default', { weekday: 'long' })
            const month = date.toLocaleString('default', { month: 'long' });
            return day + ' ' + this.getOrdinal(date.getDate()) + ' ' + month + ' ' + date.getFullYear();
        },
        getOrdinal(date) {
            let j = date % 10,
                k = date % 100;
            if (j === 1 && k !== 11) {
                return date + "st";
            }
            if (j === 2 && k !== 12) {
                return date + "nd";
            }
            if (j === 3 && k !== 13) {
                return date + "rd";
            }
            return date + "th";
        }

    }
}
</script>
<style scoped>
.booking.card {
    background-color: rgb(255, 255, 255, 0.5);
    padding: 0;
    padding-top: 1rem;
}

.booking-header {
    margin: 1rem 2rem 1rem;
    padding: 1rem;
    justify-content: center;
    align-content: center;
}

.booking-header>.row {
    display: flex;
    justify-content: space-between;
}

.booking-header>.row:first-child {
    padding: 0rem 1.5rem 1rem 1.5rem;
    gap: 1.5rem;
}

.booking-header>.row:first-child div.h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
}

h4 {
    display: flex;
    gap: 1.5rem;
}

.edit-button {
    width: fit-content
}

details {
    background-color: var(--lite-gray-white, #FFFFFF);
    width: -webkit-fill-available;
}

:deep(.card.detail-card) {
    padding: 3rem 2rem;
    border-radius: .8rem;
    background: var(--lite-gray-grey-1-50percent);
}

:deep(summary) {
    padding: 1.5rem;
    text-decoration: none;
}

:deep(details div:not(:first-child)) {
    background-color: rgb(255, 255, 255, .1);
}

.address-details {
    display: flex;
    flex-direction: column;
    gap: .75rem
}

.street-address {
    color: rgb(51, 51, 51, .8);
}

.vehicle-details .row .col {
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    gap: .75rem;
}

.car-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.car-preview .row {
    align-items: center;
    justify-content: space-evenly;
}

.pill-button span {
    display: contents;
}

@media (max-width: 600px) {
    .booking-header {
        margin: auto;
        padding: .5rem;
    }
}

:deep(.card.detail-card) {
    padding: 3rem 2rem;
}

.full-width {
    margin: 0rem;
}

:deep(summary) {
    padding: 1.5rem;
    text-decoration: none;
}

:deep(details div:not(:first-child)) {
    background-color: rgb(255, 255, 255, .1);
}

.car-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.car-preview .row {
    align-items: center;
    justify-content: space-evenly;
}

.pill-button span {
    display: contents;
}

.open-maps {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.open-maps>div[class^="col"] {
    justify-content: center;
    margin: 0.5rem;
}
</style>