<template>
    <details class="dropdown" :id="detailId" @mouseleave="onBlur">
        <summary>
            <slot name="summary"></slot>
        </summary>
        <div class="details" @click="handleClick">
            <slot></slot>
        </div>
    </details>
</template>
<script>
import { useId } from 'vue';

export default {
    data() {
        return {
            detailId: useId()
        }
    },
    mounted() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClick() {
            const detail = document.getElementById(this.detailId);
            if (detail) detail.removeAttribute('open');
        },
        onBlur() {
            const detail = document.getElementById(this.detailId);
            if (detail) detail.removeAttribute('open');
        },
    }
}
</script>
<style scoped>
summary::-webkit-details-marker,
summary::marker {
    display: none;
}

summary {
    display: flex;
    /* gap: 2rem; */
    align-items: center;
    cursor: pointer;
}

details.dropdown>div {
    background-color: var(--notification-colours-correct-light, #D6F6BC);
    min-width: -webkit-fill-available;
    border-radius: .8rem;
}
</style>