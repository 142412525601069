<template>
    <div class="root" ref="addressInput">
        <div :class="['forms-small-fields-default', className]">
            <input :class="['form-field-value', 'b2 reg', { 'typing': isTyping }]" type="text" v-model="internalModel"
                v-bind="$attrs" @focus="handleFocus" @blur="handleBlur" @input="handleInput" :placeholder="placeholder"
                :autocomplete="`do-not-auto-fill`" ref="addressField">
        </div>
        <ul v-if="addressList.length" class="dropdown" ref="addressList">
            <li v-for="address in addressList" :key="address.id" @click="handleSelect(address.a)">
                {{ address.a }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            internalModel: '',
            addressList: [],
            timeoutId: null,
            focused: false,
            timeoutOnBlur: null,
            isTyping: false,
            externalClass: ''
        }
    },
    beforeMount() {
        this.internalModel = this.modelValue;
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() { 
        document.removeEventListener('click', this.handleClickOutside)
    },
    watch: {
        modelValue(newValue) {
            this.internalModel = newValue;
        }
    },
    methods: {
        handleFocus() {
            this.isTyping = true;
            if (this.internalModel.trim()) {
                this.searchAddy(this.internalModel.trim());
            }
        },
        handleSelect(address) {
            this.addressList = [];
            this.internalModel = address;
            this.$emit('update:modelValue', this.internalModel);
        },
        handleBlur() {
            this.$emit('update:modelValue', this.internalModel);
        },
        handleClickOutside(event) {
            if (!this.$refs.addressInput.contains(event.target)) {
                this.addressList = [];
                this.$emit('update:modelValue', this.internalModel);
            }
        },
        handleInput() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                if (this.internalModel.trim()) {
                    this.searchAddy(this.internalModel.trim());
                } else {
                    this.addressList = []
                }
            }, 500)
        },
        searchAddy(query) {
            if (query.length < 3) return;
            const headers = {
                'Accept': 'application/json',
                'addy-api-key': this.$env.ADDY_KEY,
            }
            const params = { s: query }
            this.$axios.get(this.$env.ADDY_URL, { headers, params }).then(response => {
                this.addressList = response.data.addresses;
            }).catch(error => {
                console.error('Error fetching addresses:', error);
                this.addressList = [];
            })
        },
    },
}
</script>
<style scoped>
.root {
    display: flex;
    flex-direction: column;
    position: relative;
}

.dropdown {
    position: absolute;
    top:100%;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown li {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}

.forms-small-fields-default {
    background: var(--color-grey, #FAFAFA);
    border-radius: 0.8rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 3.6rem;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    align-self: stretch;
    border: 1px solid transparent;
}

.forms-small-fields-default:hover {
    border-color: var(--input-hover-color);
}

.form-field-value,
input.form-field-value {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: left;
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    align-self: stretch;
}

.form-field-value.typing,
input.form-field-value.typing {
    color: var(--font-color, #042B2F);
}

.forms-small-fields-default.white {
    background-color: var(--lite-gray-white, #FFFFFF);
}
</style>