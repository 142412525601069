<template>
    <div class="h2 reg">Feedback</div>
    <hr />
    <form ref="feedback">
        <CardDiv>
            <RowDiv>
                <div class="col">
                    <div class="b1 reg">Hello! We want to make Zilch your go-to for sustainable mobility. To do this, we
                        need your help.</div>
                </div>
            </RowDiv>
            <RowDiv>
                <div class="col">
                    <CardDiv>
                        <RowDiv>
                            <div class="col">
                                <div class="b1 reg">What do you want to tell us?</div>
                            </div>
                        </RowDiv>
                        <RowDiv>
                            <SelectableButton :class="suggestionClass" @click="type = 'suggestion'">
                                <div class="bu1 semi">Suggestion</div>
                            </SelectableButton>
                            <SelectableButton :class="issueClass" @click="type = 'issue'">
                                <div class="bu1 semi">Report a Bug</div>
                            </SelectableButton>
                            <SelectableButton :class="complimentClass" @click="type = 'compliment'">
                                <div class="bu1 semi">Feedback</div>
                            </SelectableButton>
                        </RowDiv>
                    </CardDiv>
                </div>
            </RowDiv>
            <RowDiv class="messages" v-show="type">
                <div class="col">
                    <CardDiv>
                        <RowDiv>
                            <ColDiv>
                                <div class="b1 reg" v-if="type === 'suggestion'">Type your suggestion below</div>
                                <div class="b1 reg" v-if="type === 'issue'">Oh no! We are sorry to hear that. Please
                                    explain the issue you are having.
                                </div>
                                <div class="b1 reg" v-if="type === 'compliment'">Your positive feedback makes our day!
                                    Share
                                    your compliment wih us below:</div>
                                <textarea class="b1 reg" rows="15" v-model="message" />
                            </ColDiv>
                        </RowDiv>
                        <RowDiv v-if="type === 'issue'">
                            <ColDiv>
                                <div class="b1 reg">What browser were you using?</div>
                                <select class="b1 reg" v-model="browser">
                                    <option>Chrome</option>
                                    <option>Firefox</option>
                                    <option>Safari</option>
                                    <option>Edge</option>
                                </select>
                            </ColDiv>
                        </RowDiv>
                        <RowDiv v-if="type === 'issue'">
                            <ColDiv>
                                <div class="b1 reg">Could you still complete your task with the issue present?</div>
                                <ToggleButton className="light small" @change="handleToggle">
                                    <template #left>Yes</template>
                                    <template #right>No</template>
                                </ToggleButton>
                            </ColDiv>
                        </RowDiv>
                    </CardDiv>
                </div>
            </RowDiv>
            <RowDiv v-show="type && message">
                <SelectableButton class="small" :isLoading="isSendingFeedback" @click="sendMessage">
                    <div class="bu3-reg">Send feedback</div>
                </SelectableButton>
            </RowDiv>
        </CardDiv>
    </form>
    <div v-show="confirmSubmit" class="modal-overlay">
        <AlertModal @close="confirmSubmit = false" class="feedback" text="Close">
            <div class="h3 reg">Thank you!</div>
            <div class="b1 reg">Thank you for sharing your feedback. Your input is valuable to the continuing
                development of Zilch.</div>
        </AlertModal>
    </div>
</template>
<script>
import AlertModal from '@/components/AlertModal.vue';
import ToggleButton from '@/components/ToggleButton.vue';
import { getAuth } from '@/utils/functions';
export default {
    components: {
        AlertModal,
        ToggleButton
    },
    data() {
        return {
            subject: '',
            message: '',
            confirmSubmit: false,
            defaultClass: 'light borderless',
            suggestionClass: 'light borderless',
            issueClass: 'light borderless',
            complimentClass: 'light borderless',
            type: '',
            browser: '',
            showstopper: false,
            isSendingFeedback: false,
        }
    },
    watch: {
        type(newVal) {
            switch (newVal) {
                case 'suggestion':
                    this.suggestionClass = '';
                    this.issueClass = this.defaultClass;
                    this.complimentClass = this.defaultClass;
                    this.subject = newVal.charAt(0).toUpperCase() + newVal.slice(1);
                    break;
                case 'compliment':
                    this.suggestionClass = this.defaultClass;
                    this.issueClass = this.defaultClass;
                    this.complimentClass = '';
                    this.subject = newVal.charAt(0).toUpperCase() + newVal.slice(1);
                    break;
                case 'issue':
                    this.suggestionClass = this.defaultClass;
                    this.issueClass = '';
                    this.complimentClass = this.defaultClass;
                    this.subject = 'Something not quite right';
                    break;
            }
        }
    },
    methods: {
        handleToggle(value) {
            this.showstopper = value;
        },
        sendMessage() {
            this.isSendingFeedback = true;
            const { type, message, showstopper, browser, subject } = this;
            this.$axios.post(`${this.$env.API_HOST}/user/feedback`, {
                subject, type, message, browser, showstopper
            }, {
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(() => {
                this.$refs.feedback.reset();
                this.message = '';
                this.type = null;
                this.confirmSubmit = true;
                this.isSendingFeedback = false;
            })
        },
    },
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

:deep(.modal-content) {
    gap: 3rem;
}

.feedback {
    width: 55rem;
}
</style>