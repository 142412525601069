<template>
    <div class="modal">
        <div class="modal-content">
            <DefaultLoading />
        </div>
    </div>
</template>
<script>
import DefaultLoading from './DefaultLoading.vue';
export default {
    components: {
        DefaultLoading
    }
}
</script>
