<template>
    <CardDiv :class="['search-card', { 'mobile': isMobile }]">
        <RowDiv >
            <div class="col">
                <div class="h3 reg">Search</div>
                <CardDiv>
                    <div class="b1 semi">Pick up</div>
                    <HubSearchField :options="options" @change="handlePickUpChange" :selected="selectedPickup"
                        :is-disabled="false" />
                </CardDiv>
                <CardDiv v-if="showDropOff">
                    <div class="b1 semi">Drop off</div>
                    <HubSearchField @change="handleDropOffChange" :options="droptOffOptions" :selected="selectedDropOff"
                        :is-disabled="sameDropOff" />
                </CardDiv>
                <CardDiv v-if="showDropOff">
                    <RowDiv >
                        <div class="col">
                            <div class="b1 semi">Pick up on</div>
                            <DatePicker v-model="pickupDate" format="dd/MM/yyyy" model-type="dd-MM-yyyy"
                                :enable-time-picker="false" placeholder="Date" :auto-apply="true" :min-date="new Date()"
                                @update:model-value="handlePickUpDateChange" :text-input="!isMobile"
                                :action-row="{ showNow: true, showPreview: true, showSelect: false, showCancel: false }" />
                        </div>
                        <div class="col-4">
                            <div class="b1 semi">Time</div>
                            <TimePicker v-model="pickupTime" :offset="minPickTime"
                                @update:modelValue="handlePickUpTimeChange" />
                        </div>
                    </RowDiv>
                </CardDiv>
                <CardDiv v-if="showDropOff">
                    <RowDiv >
                        <div class="col">
                            <div class="b1 semi">Return at</div>
                            <DatePicker v-model="dropOffDate" format="dd/MM/yyyy" model-type="dd-MM-yyyy"
                                :enable-time-picker="false" placeholder="Date" :auto-apply="true"
                                @update:model-value="handleDropOffDateChange" :text-input="!isMobile"
                                :min-date="minDropOffDate"
                                :action-row="{ showNow: true, showPreview: true, showSelect: false, showCancel: false }" />
                        </div>
                        <div class="col-4">
                            <div class="b1 semi">Time</div>
                            <TimePicker v-model="dropOffTime" :offset="minDropTime"
                                @update:modelValue="handleDropOffTimeChange" />
                        </div>
                    </RowDiv>
                </CardDiv>
            </div>
        </RowDiv>
        <AccordionCard v-show="false">
            <template #summary>Advanced search</template>
            <CardDiv>
                <div class="b1 semi">Type of vehicle</div>
                <RowDiv >
                    <div class="col-6">
                        <InputComponent v-model="type" type="combo" placeholder="Vehicle type" icon-src="car/car"
                            className="white" />
                    </div>
                    <div class="col-6">
                        <InputComponent v-model="range" type="combo" placeholder="EV Range" icon-src="location/route"
                            className="white" />
                    </div>
                </RowDiv>
            </CardDiv>
            <CardDiv>
                <div class="b1 semi">Space in vehicle</div>
                <RowDiv >
                    <div class="col-6">
                        <InputComponent v-model="passengers" type="number" placeholder="Passengers" className="white"
                            icon-src="people/group" />
                    </div>
                    <div class="col-6">
                        <InputComponent v-model="suitcases" type="number" placeholder="Suitcases" className="white"
                            icon-src="car/suitcase" />
                    </div>
                </RowDiv>
            </CardDiv>
        </AccordionCard>
        <div class="col-6" v-show="displayFindButton">
            <SelectableButton @click="handleClick">
                <div class="btn-anchor bu1-semi">Find available options</div>
            </SelectableButton>
        </div>
    </CardDiv>
</template>
<script>
import AccordionCard from '@/components/AccordionCard.vue';
import DatePicker from '@/components/DatePicker.vue';
import HubSearchField from '@/components/HubSearchField.vue';
import TimePicker from '@/components/TimePicker.vue';
import { mapState, mapMutations } from 'vuex';
import { getAuth, getLongLatDistance, getMinTime, parseDate } from '@/utils/functions';
export default {
    components: {
        AccordionCard,
        DatePicker,
        HubSearchField,
        TimePicker,
    },
    data() {
        return {
            positionCoords: null,
            pickupDate: null,
            dropOffDate: null,
            pickupTime: '',
            dropOffTime: '',
            options: [],
            droptOffOptions: [],
            selectedPickup: null,
            selectedDropOff: null,
            sameDropOff: true,
            showDropOff: false,
            type: '',
            range: '',
            passengers: '',
            suitcases: '',
            dateTimePopup: false,
        }
    },
    computed: {
        displayFindButton() {
            return this.searchCriteria?.pickup?.date && this.searchCriteria?.pickup?.time &&
                this.searchCriteria?.dropOff?.date && this.searchCriteria?.dropOff?.time;
        },
        minPickTime() {
            return getMinTime(this.pickupDate);
        },
        minDropTime() {
            return getMinTime(this.dropOffDate, { date: this.pickupDate, time: this.pickupTime });
        },
        minDropOffDate() {
            return parseDate(this.pickupDate);
        },
        ...mapState(['searchCriteria', 'isMobile']),
    },
    async mounted() {
        try {
            const position = await this.getGeoLocation();
            this.positionCoords = {
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
            }
        } catch (error) {
            console.error(error);
            this.$bus.emit('show-toast-message', {text: 'System needs your location to show estimated distance to hubs'});
        }

        const sessionCriteria = this.searchCriteria || JSON.parse(sessionStorage.getItem('searchParams'));
        this.$axios.get(`${this.$env.API_HOST}/user/hubs`, {
            headers: { 'Authorization': `Bearer ${getAuth()}` }
        }).then(resp => {
            this.options = Object.values(resp.data).map((option) => {
                if (this.positionCoords) {
                    const distanceInK = getLongLatDistance(option, this.positionCoords);
                    let dim = 'km';
                    let distance = Math.floor(distanceInK);
                    if (distanceInK < 1) {
                        distance = distance * 1000;
                        dim = 'm'
                    }
                    option.distance = { distance, dim };
                } else {
                    option.distance = { distance: 0, dim: 'm' }
                }
                option.direction = 'return';
                if (option.one_way_destinations.length > 0) option.direction = 'both-ways';
                return option;
            });
            this.droptOffOptions = [...this.options];
            if (this.searchCriteria) {
                this.selectedPickup = this.searchCriteria.pickup.hub;
                this.selectedDropOff = this.searchCriteria.dropOff.hub;
            } else if (sessionCriteria && (JSON.stringify(sessionCriteria) !== '{}')) {
                this.selectedPickup = sessionCriteria.pickup.hub;
                this.pickupDate = sessionCriteria.pickup.date;
                this.pickupTime = sessionCriteria.pickup.time;
                this.dropOffDate = sessionCriteria.dropOff.date;
                this.dropOffTime = sessionCriteria.dropOff.time;
                this.handlePickUpChange(sessionCriteria.pickup.hub);
            }
        });
        if (this.options && (sessionCriteria || this.searchCriteria)) {
            if (sessionCriteria && (JSON.stringify(sessionCriteria) !== '{}')) {
                this.selectedPickup = sessionCriteria.pickup.hub;
                this.pickupDate = sessionCriteria.pickup.date;
                this.pickupTime = sessionCriteria.pickup.time;
                this.dropOffDate = sessionCriteria.dropOff.date;
                this.dropOffTime = sessionCriteria.dropOff.time;
                this.handlePickUpChange(sessionCriteria.pickup.hub);
            }
        }
    },
    emits: ['notify'],
    methods: {
        getGeoLocation() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            });
        },
        handlePickUpChange(option) {
            if (option === null) {
                this.setCriteria('pickup', { hub: null });
                this.setCriteria('dropOff', { hub: null });
                this.showDropOff = false;
                return;
            }
            this.showDropOff = true;
            this.setCriteria('pickup', { hub: option });
            if (option.direction === 'both-ways') {
                this.setCriteria('dropOff', { hub: null });
                this.selectedDropOff = null;
                this.droptOffOptions = this.options.filter(
                    (element) =>
                        (element.one_way_origins.includes(+option.id) || element.one_way_destinations.includes(+option.id) || (+element.id === +option.id))
                );
                this.sameDropOff = false;
            } else {
                this.sameDropOff = true;
                this.selectedDropOff = { ...option };
                this.setCriteria('dropOff', { hub: option });
                this.$emit('notify', 'return-only');
            }
            this.setDefaults();
        },
        handleDropOffChange(option) {
            this.setCriteria('dropOff', { hub: option });
        },
        handlePickUpDateChange() {
            this.setCriteria('pickup', { date: this.pickupDate });
            if (parseDate(this.pickupDate) > parseDate(this.dropOffDate)) {
                this.dropOffDate = this.pickupDate;
                this.setCriteria('dropOff', { date: this.dropOffDate });
            }
        },
        handlePickUpTimeChange() {
            this.setCriteria('pickup', { time: this.pickupTime });
        },
        handleDropOffDateChange() {
            this.setCriteria('dropOff', { date: this.dropOffDate });
        },
        handleDropOffTimeChange() {
            this.setCriteria('dropOff', { time: this.dropOffTime });
        },
        setCriteria(key, value) {
            const criteria = this.searchCriteria && { ...this.searchCriteria[key] };
            this.setSearchCriteria({ key, value: { ...criteria, ...value } });
        },
        handleClick() {
            sessionStorage.setItem('searchParams', JSON.stringify(this.searchCriteria));
            this.$bus.emit('search-now');
        },
        setDefaults() {
            const currentDate = new Date();
            let pickup = {};
            let dropOff = {};
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const date = currentDate.getDate();
            const hours = currentDate.getHours() + ((currentDate.getHours() < 23) ? 1 : 0);
            const minutes = (currentDate.getMinutes() < 30) ? '00' : '30';

            if (!this.pickupDate) {
                this.pickupDate = ((date < 10) ? '0' : '') + date + '-' + ((month < 10) ? '0' : '') + month + '-' + year;
            }
            if (!this.pickupTime) {
                this.pickupTime = ((hours < 10) ? '0' : '') + hours + ':' + minutes;
            }
            pickup = {
                date: this.pickupDate,
                time: this.pickupTime
            }
            this.setCriteria('pickup', pickup);
            if (!this.dropOffDate) {
                this.dropOffDate = ((date < 10) ? '0' : '') + date + '-' + ((month < 10) ? '0' : '') + month + '-' + year;
            }
            if (!this.dropOffTime) {
                const dropOffHour = hours + ((hours < 23) ? 1 : 0);
                this.dropOffTime = ((dropOffHour < 10) ? '0' : '') + dropOffHour + ':' + minutes;
            }
            dropOff = {
                date: this.dropOffDate,
                time: this.dropOffTime
            }
            if (dropOff.date || dropOff.time) {
                this.setCriteria('dropOff', dropOff);
            }
        },
        ...mapMutations(['setSearchCriteria'])
    }
}
</script>
<style scoped>
.search-card {
    padding: 4.5rem !important;
}

.card {
    display: flex;
    padding: 15px 25px;
    flex-direction: column;
    align-self: stretch;
    border-radius: 8px;
    background: var(--lite-gray-white, #FFFFFF);
    gap: 1rem;
}

.card .card {
    background: var(--color-grey, #FAFAFA);
}

.card:not(.search-card)>.row>*[class^="col"] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mobile .card,
.mobile .card .card {
    padding: 1.5rem 1rem;
}

.mobile .col {
    margin-left: .5rem;
    margin-right: .5rem;
}

.mobile .input-wrapper {
    padding: .5rem;
}

h3 {
    margin-top: 0;
}

@media (max-width: 600px) {
    .search-card {
        padding: 4.5rem 1.5rem !important;
    }
}
</style>