<template>
    <CardDiv class="booking">
        <div class="booking-header">
            <RowDiv>
                <div class="h3 reg">
                    <SvgIcon name="car/car" class="icon-wide" />
                    <div>Your booking</div>
                </div>
                <SelectableButton className="light edit-button" @click="handleEditBooking"
                    v-if="!details?.booking?.car_pooling">
                    <div class="bu1-reg">Edit<span v-if="!isMobile"> booking</span></div>
                </SelectableButton>
            </RowDiv>
            <RowDiv>
                <div class="col">
                    <DetailCard :isSearch="false" :data="details?.pickup">
                        <template #pill-name>
                            <div class="b3 semi">Pick-up</div>
                        </template>
                    </DetailCard>
                </div>
                <div class="col">
                    <DetailCard :isSearch="false" :data="details?.dropOff">
                        <template #pill-name>
                            <div class="b3 semi">Drop-off</div>
                        </template>
                    </DetailCard>
                </div>
                <div class="col-3 car-preview">
                    <div><img :src="details?.selectedCar?.image" alt="car" /></div>
                    <div class="b2 semi row">
                        <div>{{ details?.selectedCar?.brand }} {{ details?.selectedCar?.model }}</div>
                        <PillButton>
                            <span class="b2 reg pointer">
                                <SvgIcon name="calendar/clock" />
                                {{ tripDuration }} hours
                            </span>
                        </PillButton>
                    </div>
                </div>
            </RowDiv>
        </div>
        <BookingAccordion :details="details" />
    </CardDiv>
</template>
<script>
import BookingAccordion from './BookingAccordion.vue';
import DetailCard from '@/components/DetailCard.vue';
import { mapState } from 'vuex';
import { parseDateTime } from '@/utils/functions';

export default {
    props: {
        details: {
            type: Object,
            default: null
        }
    },
    components: {
        BookingAccordion,
        DetailCard,
    },
    computed: {
        tripDuration() {
            if (!this.details || !this.details.pickup || !this.details.dropOff) return 0;
            const start = parseDateTime(this.details?.pickup.date, this.details?.pickup.time).getTime();
            const end = parseDateTime(this.details?.dropOff.date, this.details?.dropOff.time).getTime();
            const diffTime = Math.abs(end - start);
            return Math.floor(diffTime / (1000 * 60 * 60));
        },
        ...mapState(['searchCriteria', 'isMobile'])
    },
    emits: ['edit'],
    methods: {
        handleEditBooking() {
            this.$emit('edit');
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.toLocaleString('default', { weekday: 'long' })
            const month = date.toLocaleString('default', { month: 'long' });
            return day + ' ' + this.getOrdinal(date.getDate()) + ' ' + month + ' ' + date.getFullYear();
        },
        getOrdinal(date) {
            let j = date % 10,
                k = date % 100;
            if (j === 1 && k !== 11) {
                return date + "st";
            }
            if (j === 2 && k !== 12) {
                return date + "nd";
            }
            if (j === 3 && k !== 13) {
                return date + "rd";
            }
            return date + "th";
        }
    }
}
</script>
<style scoped>
.booking.card {
    background-color: rgb(255, 255, 255, 0.5);
    padding: 1rem 1rem 0 1rem;
}

.booking-header {
    margin: 1rem 2rem 1rem;
    padding: 1rem;
    justify-content: center;
    align-content: center;
}

.booking-header>.row {
    display: flex;
    justify-content: space-between;
}

.booking-header>.row:first-child {
    padding: 0rem 1.5rem 1rem 1.5rem;
}

.booking-header>.row:first-child>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h4 {
    display: flex;
    gap: 1.5rem;
}

.edit-button {
    width: fit-content
}

details {
    background-color: var(--lite-gray-white, #FFFFFF);
    width: -webkit-fill-available;
}

.car-preview {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2rem;
}

.car-preview .row {
    align-items: center;
    justify-content: space-evenly;
}

.pill-button span {
    display: contents;
}

@media (max-width: 600px) {
    .booking-header {
        margin: auto;
        padding: .5rem;
    }
}
</style>