<template>
    <HeroCard>
        <div class="h0 reg">My Account</div>
        <div class="h3 reg">View and update your details</div>
    </HeroCard>

    <div class="container">
        <RowDiv >
            <div class="col-3 left-menu" v-show="!isMobile || (isMobile && !showPanel)">
                <CardDiv>
                    <div class="h3 reg">Profile</div>
                    <hr />
                    <div :class="['menu-list', { 'selected': (panel === 'details') }]" @click="switchPanel('details')">
                        <div class="b2 reg">Personal Details</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <div :class="['menu-list', { 'selected': (panel === 'licence') }]" @click="switchPanel('licence')">
                        <div class="b2 reg">Driver Licence</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <div :class="['menu-list', { 'selected': (panel === 'billing') }]" @click="switchPanel('billing')">
                        <div class="b2 reg">Billing Options</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <div :class="['menu-list', { 'selected': (panel === 'access') }]" @click="switchPanel('access')">
                        <div class="b2 reg">Access Card</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <div :class="['menu-list', { 'selected': (panel === 'security') }]" @click="switchPanel('security')">
                        <div class="b2 reg">Security</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <hr />
                    <div :class="['menu-list', { 'selected': (panel === 'feedback') }]" @click="switchPanel('feedback')">
                        <div class="b2 reg">Feedback</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <hr />
                </CardDiv>
                <CardDiv v-show="false">
                    <div class="h3 reg">Help</div>
                    <hr />
                    <div :class="['menu-list', { 'selected': (panel === 'help') }]" @click="switchPanel('help')">
                        <div class="b2 reg">Help</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <div :class="['menu-list', { 'selected': (panel === 'terms') }]" @click="switchPanel('terms')">
                        <div class="b2 reg">Terms and agreements</div>
                        <SvgIcon name="icon/right" class="icon-small" />
                    </div>
                    <hr />
                </CardDiv>
            </div>
            <div class="col" v-show="!isMobile || showPanel">
                <CardDiv>
                    <div class="nav-back" v-show="isMobile">
                        <SelectableButton class="light borderless small" @click="this.showPanel = false">
                            <SvgIcon name="trip/arrow-left" class="icon-wide" />
                            <div class="bu1 semi">Back</div>
                        </SelectableButton>
                    </div>
                    <div class="panel" v-show="(panel === 'details')">
                        <PersonalDetails />
                    </div>
                    <div class="panel" v-show="(panel === 'licence')">
                        <DriverLicence />
                    </div>
                    <div class="panel" v-show="(panel === 'billing')">
                        <BillingDetails  />
                    </div>
                    <div class="panel" v-show="(panel === 'access')">
                        <AccessCard  />
                    </div>
                    <div class="panel" v-show="(panel === 'security')">
                        <SecurityDetails />
                    </div>
                    <div class="panel" v-show="(panel === 'feedback')">
                        <UserFeedback />
                    </div>
                </CardDiv>
            </div>
        </RowDiv>
    </div>
</template>
<script>
import { mapState } from 'vuex';

import AccessCard from './profile/AccessCard.vue';
import BillingDetails from './profile/BillingDetails.vue';
import DriverLicence from './profile/DriverLicence.vue';
import HeroCard from '@/components/HeroCard.vue';
import PersonalDetails from './profile/PersonalDetails.vue';
import SecurityDetails from './profile/SecurityDetails.vue';
import UserFeedback from './profile/UserFeedback.vue';

export default {
    components: {
        AccessCard,
        BillingDetails,
        DriverLicence,
        HeroCard,
        PersonalDetails,
        SecurityDetails,
        UserFeedback,
    },
    computed: {
        ...mapState(['isMobile', 'profile'])
    },
    data() {
        return {
            panel: 'details',
            showPanel: false,
        }
    },
    mounted() { 
        const sessionPanel = sessionStorage.getItem('feedback');
        if (sessionPanel) this.switchPanel(sessionPanel);
    },
    methods: {
        switchPanel(panel) {
            this.panel = panel;
            this.showPanel = true;
            sessionStorage.setItem('feedback', panel);
        },
    }
}
</script>
<style scoped>
.card .panel {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

hr {
    margin: .5rem 0;
}

.card .panel .card {
    padding-top: 1.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.left-menu {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.card.panel .row *[class^="col"] {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.menu-list {
    padding: 1.5rem;
    border-radius: 8px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.selected {
    background: var(--notification-colours-correct-light, #D6F6BC);
    border-color: var(--notification-colours-correct-light, #D6F6BC);
}


.payments-card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.modal-overlay .card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 0px;
}

.modal-overlay *[class^="col"] {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.modal-overlay .row>.col>.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;
}

.modal-overlay h3 {
    margin-bottom: auto;
}

.international-licence {
    width: 35%;
}

.billing-popup {
    width: 35%;
}

.billing-popup .card {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#card-element {
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
    background-color: #fafafa;
}

#card-element:hover {
    border-color: var(--color-primary);
}

.dl-image {
    display: contents;
    height: 85%;
}

.dl-image img {
    border-radius: 8px;
    align-self: stretch;
    flex: 1;
    position: relative;
    object-fit: cover;
}

.nav-back {
    display: flex;
    margin-bottom: 1rem
}

.form-field-value.typing,
input.form-field-value.typing {
    color: var(--font-color, #042B2F);
}
</style>