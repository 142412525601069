<template>
    <div :class="['card payment-card', { 'selected': selected }]">
        <RowDiv >
            <div class="col-3" v-show="!isMobile">
                <div :class="['logo', logoClass]">
                    <slot name="image"></slot>
                </div>
            </div>
            <div class="col details">
                <slot></slot>
            </div>
            <div class="col-2 buttons" v-if="$slots.buttons">
                <slot name="buttons"></slot>
            </div>
            <div class="col-3 buttons" v-show="selected">
                <SvgIcon name="feedback/tick-inverted"  class="icon-xlarge" />
            </div>
        </RowDiv>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState(['isMobile'])
    },
    props: {
        logoClass: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
<style scoped>
.card.payment-card {
    padding: 0px;
    background-color: var(--lite-gray-white, #FFFFFF);
    padding-left: 1rem;
    border: 1px solid transparent;
}

.card.payment-card.selected {
    border: 1px solid var(--bg-secondary-color, #042B2F);
}

.payment-card *[class^='col'] {
    margin: 0;
}

div.card .row {
    height: 12.3rem;
}

.logo {
    height: 100%;
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-card .details {
    padding-left: 1.5rem;
}

.details {
    align-content: center;
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem;
}

:deep(h3) {
    margin-bottom: .5rem;
    margin-top: auto;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
</style>
