<template>
    <div class="h2 reg">Driver Licence</div>
    <hr />
    <NoticeCard classes="info">
        <RowDiv>
            <div class="col">
                <div class="b1 semi">You need a valid driver licence to be able to unlock and drive
                    cars.</div>
                <div class="b1 reg">
                    If you update your driver licence, you may not be able to use cars
                    while we validate your licence in the background. Find out more about
                    <a href="#">driver licences</a>
                </div>
                <div class="notice-buttons">
                    <SelectableButton class="light small" @click="this.isLicenceModalOpen = true">
                        <div class="bu3-reg">Renew licence</div>
                    </SelectableButton>
                    <SelectableButton class="light small" @click="this.isIntlLicenceModalOpen = true">
                        <div class="bu3-reg">Add international driver licence</div>
                    </SelectableButton>
                </div>
            </div>
            <div class="col-1 notice-icon">
                <SvgIcon name='icon/help-avatar' class="icon-large" />
            </div>
        </RowDiv>
    </NoticeCard>
    <CardDiv>
        <RowDiv>
            <div class="col">
                <div class="h4 reg">New Zealand Driver Licence</div>
            </div>
            <div class="col-2">
                <PillButton :className="`small ${licenceStateClass}`">
                    <SvgIcon :name="licenceIcon" />
                    <div class="b1 semi">{{ this.licenceState }}</div>
                </PillButton>
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">First name</div>
                <InputComponent v-model="firstName" type="text" placeholder="First name" :readonly="true"
                    className="white" />
            </div>
            <div class="col field">
                <div class="b1 semi">Last name</div>
                <InputComponent v-model="lastName" type="text" placeholder="Last name" :readonly="true"
                    className="white" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col-6 field">
                <div class="b1 semi">Birth date</div>
                <InputComponent v-model="birthDate" type="text" placeholder="Birthdate" :readonly="true"
                    className="white" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col field">
                <div class="b1 semi">Licence number</div>
                <InputComponent v-model="licenceNumber" type="text" placeholder="Licence Number" :readonly="true"
                    className="white" />
            </div>
            <div class="col field">
                <div class="b1 semi">Licence version number</div>
                <InputComponent v-model="licenceVersion" type="text" placeholder="Licence Version" :readonly="true"
                    className="white" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col-6 field">
                <div class="b1 semi">Licence expiry</div>
                <InputComponent v-model="expiryDate" type="text" placeholder="Expiry" :readonly="true"
                    className="white" />
            </div>
        </RowDiv>
    </CardDiv>
    <div v-if="isLicenceModalOpen" class="modal-overlay">
        <PopUpModal @close="this.isLicenceModalOpen = false" classes="renewal-popup">
            <CardDiv>
                <RowDiv>
                    <div class="col">
                        <div class="h3 reg">Renew your New Zealand driver licence details</div>
                        <p class="b3 reg">You need a validated driver licence to be able to unlock and drive cars. This
                            means you may not be able to book cars while we validate your licence in the background.</p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <CardDiv>
                            <RowDiv>
                                <div class="col field">
                                    <div class="b1 semi">Surname</div>
                                    <div class="b2 reg">Enter your <span class="b2 semi">last</span> name from your
                                        driver licence</div>
                                    <InputComponent v-model="lastName" type="text" placeholder="Surname"
                                        className="white" />
                                </div>
                            </RowDiv>
                            <RowDiv>
                                <div class="col field">
                                    <div class="b1 semi">Birth date</div>
                                    <div class="b2 reg">
                                        Enter your birth date as displayed on your driver licence
                                    </div>
                                    <DatePicker v-model="date" format="dd/MM/yyyy" model-type="dd-MM-yyyy"
                                        :enable-time-picker="false" placeholder="Date (dd-mm-yyyy)" class="date-picker"
                                        @update:model-value="updateBirthDate" :text-input="!isMobile" :no-today="true"
                                        :auto-apply="true" :max-date="tenYearsAgo" :start-date="tenYearsAgo"
                                        :action-row="{ showNow: false, showPreview: true, showSelect: true, showCancel: true }" />
                                </div>
                            </RowDiv>
                            <RowDiv>
                                <div class="col field">
                                    <div class="b1 semi">Licence number</div>
                                    <InputComponent v-model="licenceNumber" type="text" placeholder="Licence Number"
                                        className="white" />
                                </div>
                            </RowDiv>
                            <RowDiv>
                                <div class="col field">
                                    <div class="b1 semi">Licence version number</div>
                                    <InputComponent v-model="licenceVersion" type="text" placeholder="Licence Version"
                                        className="white" />
                                </div>
                            </RowDiv>
                        </CardDiv>
                    </div>
                    <div class="col-5" v-show="!isMobile">
                        <div class="dl-image">
                            <img src="/images/resized/licence.png" alt="driver licence" />
                        </div>
                    </div>
                </RowDiv>
                <RowDiv>
                    <SelectableButton className="light small" @click="saveLicence">
                        <div class="button-light">Update licence details</div>
                    </SelectableButton>
                    <SelectableButton className="light small" @click="this.isLicenceModalOpen = false">
                        <div class="button-light">Cancel</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <div v-if="isIntlLicenceModalOpen" class="modal-overlay">
        <PopUpModal @close="this.isIntlLicenceModalOpen = false" classes="international-licence">
            <CardDiv>
                <div class="h3 reg">Add International Licence</div>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">Please <span class="b1 semi">email</span> a copy of your international driver
                            licence.<br />If it is not in English, please include an English translation.</p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <p class="b1 reg">Our team will be in touch with you within 24 hours.</p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <div class="forms-small-fields-default">
                            <input class="form-field-value b2 reg" type="text" :readonly="true"
                                value="support@zilch.nz">
                            <div v-show="isCopied" class="b3 reg"><i>Copied!</i></div>
                            <SvgIcon name="icon/copy" @click="copyToClipboard('support@zilch.nz')" />
                        </div>
                    </div>
                </RowDiv>
                <RowDiv>
                    <div class="col">
                        <p class="b3 reg">If you have questions about driving in New Zealand as a visitor, please visit
                            NZTA's <a href="#">Driving on New Zealand roads</a></p>
                    </div>
                </RowDiv>
                <RowDiv>
                    <SelectableButton className="light small" @click="sendMail">
                        <div class="button-light">Email Zilch</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </PopUpModal>
    </div>
    <div v-if="licenceSaved" class="modal-overlay">
        <AlertModal @close="licenceSaved = false" class="licence-saved">
            <div class="h3 reg">Drivers licence is being processed</div>
            <div class="b2 reg">
                <p>You need a validated drivers licence to be able to unlock and drive cars. This means you may not be
                    able to book cars while we validate your licence in the background. It should be done in a time
                    period of our choosing</p>
            </div>
        </AlertModal>
    </div>
</template>
<script>
import AlertModal from '@/components/AlertModal.vue';
import DatePicker from '@/components/DatePicker.vue';
import NoticeCard from '@/components/NoticeCard.vue';
import PopUpModal from '@/components/PopUpModal.vue';
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        AlertModal,
        DatePicker,
        NoticeCard,
        PopUpModal,
    },
    computed: {
        ...mapState(['profile', 'isMobile'])
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            birthDate: '',
            date: null,
            tenYearsAgo: '',
            licenceNumber: '',
            licenceVersion: '',
            licenceState: '',
            expiryDate: '',
            isLicenceModalOpen: false,
            isIntlLicenceModalOpen: false,
            licenceStateClass: '',
            licenceIcon: 'icon/refresh',
            isCopied: false,
            licenceSaved: false,
        }
    },
    mounted() {
        const today = new Date();
        const tenYearsAgo = new Date(today.getFullYear() - 15, today.getMonth(), today.getDate());
        this.tenYearsAgo = tenYearsAgo.toISOString().split('T')[0];
    },
    watch: {
        profile: {
            handler(newVal) {
                if (!newVal) return;
                this.firstName = newVal.first_name;
                this.lastName = newVal.licence_last_name ?? newVal.last_name;
                this.birthDate = newVal.date_of_birth;
                this.licenceNumber = newVal.licence_number;
                this.licenceVersion = newVal.licence_version;
                this.expiryDate = newVal.licence_expiry ?? '';
                this.licenceState = newVal.licence_state.charAt(0).toUpperCase() + newVal.licence_state.slice(1);
                if (this.licenceState === 'Invalid') {
                    this.licenceStateClass = 'alert';
                } else if (this.licenceState === 'Valid') {
                    this.licenceStateClass = 'primary';
                } else {
                    this.licenceStateClass = '';
                }
                if (newVal.licence_state === 'valid') this.licenceIcon = 'feedback/tick-circle';
                if (newVal.licence_state === 'invalid') this.licenceIcon = 'feedback/error';
                this.date = this.birthDate;
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        updateBirthDate(date) {
            this.birthDate = date;
        },
        saveLicence() {
            const accessToken = sessionStorage.getItem('accToken');
            const params = {
                date_of_birth: this.birthDate,
                licence_number: this.licenceNumber,
                licence_version: this.licenceVersion,
                licence_last_name: this.lastName,
            };
            const headers = { 'Authorization': `Bearer ${accessToken}` };
            this.$axios.post(`${this.$env.API_HOST}/user/licence/verify`, params, { headers }).then(() => {
                this.loadProfile();
                this.isLicenceModalOpen = false;
                this.licenceSaved = true;
            }).catch(e => {
                console.error('Error saving licence details:', e);
            });
        },
        copyToClipboard(text) {
            this.isCopied = true;
            navigator.clipboard.writeText(text);
            setTimeout(() => { this.isCopied = false }, 750);
        },
        sendMail() {
            window.location.href = 'mailto:support@zilch.nz';
        },
        ...mapActions(['loadProfile']),
    }
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.field,
.info .row .col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.notice-icon {
    justify-content: center;
    align-items: center;
}

.notice-buttons {
    display: flex;
    gap: 1.5rem;
}

.modal-overlay .card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 0px;
}

.modal-overlay *[class^="col"] {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.modal-overlay .row>.col>.card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 0;
}

.modal-overlay h3 {
    margin-bottom: auto;
}

.licence-saved,
.international-licence {
    width: 35%;
}

@media (max-width: 900px) {
    .licence-saved,
    .international-licence {
        width: auto;
    }
}

.licence-saved :deep(.modal-content) {
    gap: 3rem;
}

.forms-small-fields-default {
    background: var(--color-grey, #FAFAFA);
    border-radius: 0.8rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 3.6rem;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
    align-self: stretch;
}

.form-field-value,
input.form-field-value {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: left;
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    align-self: stretch;
}
</style>