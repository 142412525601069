<template>
    <CardDiv>
        <details v-bind="$attrs">
            <summary @click="toggle">
                <SvgIcon :name="arrowIcon" />
                <slot name="summary"></slot>
            </summary>
            <slot></slot>
        </details>
    </CardDiv>
</template>
<script>
export default {
    data() {
        return {
            arrowIcon: 'icon/down',
            isOpen: false,
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            this.arrowIcon = this.isOpen ? 'icon/up' : 'icon/down';
        }
    }
}
</script>
<style scoped>
summary::-webkit-details-marker,
summary::marker {
    display: none;
}

summary {
    display: flex;
    gap: 2rem;
    text-decoration: underline;
    align-items: center;
    cursor: pointer;
}

details :deep(div.card) {
    padding: 2.5rem 0px;
}
</style>