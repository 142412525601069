<template>
    <div class="text-card">
        <CardTopNavigation />
        <div :class="['onboarding-stack', className]">
            <slot></slot>
        </div>
        <CardFootnote v-if="this.$slots.footer" >
            <slot name="footer"></slot>
        </CardFootnote>
    </div>
    <ImagePanel :imgSrc="displayImage" :buttonText="nextButtonName" iconName="trip/arrow-right" color="FFFFFF"
        :imgExtraClasses="flexClass" :stepIndex="stepIndex" />

</template>

<script>
import CardTopNavigation from '@/components/CardTopNavigation.vue';
import CardFootnote from '@/components/CardFootnote.vue';
import ImagePanel from '../components/ImagePanel.vue';
export default {
    components: {
        CardTopNavigation,
        CardFootnote,
        ImagePanel
    },
    props: {
        className: String,
        nextButtonName: String,
        displayImage: {
            type: String,
            required: true,
        },
        flexClass: String,
        stepIndex: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>
div.onboarding-stack.small-gap {
    gap: 2.5rem;
    padding-bottom: 3.5rem;
}
</style>