<template>
    <AccordionCard class="full-width" :open="details?.booking?.car_pooling || $attrs.open">
        <template #summary>
            <div class="b1 semi">Booking details</div>
            <slot name="buttons"></slot>
        </template>
        <CardDiv>
            <RowDiv>
                <div class="col">
                    <div>
                        <div class="open-maps">
                            <div class="b1 semi">Pick up</div>
                            <SelectableButton class="light borderless small" @click="openMap(details?.pickup?.hub)">
                                <SvgIcon name="icon/export" />
                            </SelectableButton>
                        </div>
                    </div>
                    <div class="address-details">
                        <div class="b1 reg">
                            {{ details?.pickup?.hub?.name }}
                        </div>
                        <div class="street-address b2 reg">
                            {{ details?.pickup?.hub?.address }}
                        </div>
                    </div>
                    <div>
                        <SvgIcon name="calendar/calendar" />
                        {{ details?.pickup?.time }} {{ formatDate(details?.pickup?.date) }}
                    </div>
                    <div>
                        <div class="open-maps">
                            <div class="b1 semi">Drop off</div>
                            <SelectableButton class="light borderless small" @click="openMap(details?.dropOff?.hub)">
                                <SvgIcon name="icon/export" />
                            </SelectableButton>
                        </div>
                    </div>
                    <div class="address-details">
                        <div class="b1 reg">
                            {{ details?.dropOff?.hub?.name }}
                        </div>
                        <div class="street-address b2 reg">
                            {{ details?.dropOff?.hub?.address }}
                        </div>
                    </div>
                    <div>
                        <SvgIcon name="calendar/calendar" />
                        {{ details?.dropOff?.time }} {{ formatDate(details?.dropOff?.date) }}
                    </div>
                </div>
                <div class="col carpool-details">
                    <div>
                        <div class="b1 semi">Driver</div>
                    </div>
                    <div>
                        <div class="passenger">
                            <SvgIcon name="menu/profile" className="icon-small" />
                            <div v-if="this.$route.path === '/booking'" class="b2 reg">{{ this.profile?.first_name }} {{
                                this.profile?.last_name }} (you)</div>
                            <div v-else-if="details?.booking?.driver" class="b2 reg">
                                {{ details?.booking?.driver?.name }} ({{ details?.booking?.driver?.phone }}) {{
                                    isUserIsDriver(details?.booking?.driver.email) ? '(you)' : '' }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="b1 semi">Passengers</div>
                    </div>
                    <div v-if="this.$route.path !== '/booking'">
                        <div v-for="passenger in details?.booking?.passengers" :key="passenger.id" class="passenger">
                            <SvgIcon name="menu/profile" className="icon-small" />
                            <div class="b2 reg">{{ passenger.name }} ({{ passenger.phone }}) {{ isUserIsDriver(passenger.email) ?
                                '(you)' : '' }}</div>
                        </div>
                        <div class="passenger" v-if="this.$route.path.includes('booking/join')">
                            <SvgIcon name="menu/profile" className="icon-small" />
                            <div class="b2 reg">{{ this.profile?.first_name }} {{ this.profile?.last_name }} (you)</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="passenger">
                            <SvgIcon name="menu/profile" className="icon-small" />
                            <div class="b2 reg"><i>-- None --</i></div>
                        </div>
                    </div>
                    <div>
                        <div class="b1 semi">Via</div>
                    </div>
                    <div>
                        <div class="passenger" v-if="details?.booking?.via">
                            <SvgIcon name="location/gps" className="icon-small" />
                            <div class="b2 reg">{{ details?.booking?.via }}</div>
                        </div>
                    </div>
                </div>
                <div class="col vehicle-details">
                    <div>
                        <div class="b1 semi">Vehicle details</div>
                    </div>
                    <div class="b1 reg">
                        {{ details?.selectedCar?.brand }} {{ details?.selectedCar?.model }}
                    </div>
                    <RowDiv class=" b1 reg">
                        <div class="col">
                            <SvgIcon name="menu/trips" />
                            <div>
                                {{ details?.selectedCar?.range }} kms
                            </div>
                        </div>
                        <div class="col">
                            <SvgIcon name="battery/charge" v-show="details?.selectedCar?.charge" />
                            <div>
                                {{ details?.selectedCar?.charge }}
                            </div>
                        </div>
                    </RowDiv>
                    <RowDiv class=" b1 reg">
                        <div class="col">
                            <SvgIcon name="people/group" />
                            <div>
                                {{ details?.selectedCar?.passengers }} passengers
                            </div>
                        </div>
                        <div class="col">
                            <SvgIcon name="car/suitcase" />
                            <div>
                                {{ details?.selectedCar?.luggage }} suitcases
                            </div>
                        </div>
                    </RowDiv>
                </div>
            </RowDiv>
        </CardDiv>
    </AccordionCard>
</template>
<script>
import AccordionCard from '@/components/AccordionCard.vue';
import { openMap, parseDate } from '@/utils/functions';
import { mapState } from 'vuex';

export default {
    components: {
        AccordionCard,
    },
    props: {
        details: {
            type: [Object, null],
            required: true,
        }
    },
    computed: {
        myEmail() {
            const my_primary_email = this.profile?.email_addresses.find(email => email.primary === 'true')
            return my_primary_email?.email_address;
        },
        ...mapState(['profile']),
    },
    methods: {
        isUserIsDriver(email) {
            return email === this.myEmail;
        },
        openMap(hub) {
            openMap(hub);
        },
        formatDate(dateString) {
            if (!dateString) return;
            const date = parseDate(dateString);
            const day = date.toLocaleString('default', { weekday: 'long' })
            const month = date.toLocaleString('default', { month: 'long' });
            return day + ' ' + this.getOrdinal(date.getDate()) + ' ' + month + ' ' + date.getFullYear();
        },
        getOrdinal(date) {
            let j = date % 10,
                k = date % 100;
            if (j === 1 && k !== 11) {
                return date + "st";
            }
            if (j === 2 && k !== 12) {
                return date + "nd";
            }
            if (j === 3 && k !== 13) {
                return date + "rd";
            }
            return date + "th";
        }
    },
}
</script>
<style scoped>
details {
    background-color: var(--lite-gray-white, #FFFFFF);
    width: -webkit-fill-available;
}

:deep(.card.detail-card) {
    padding: 3rem 2rem;
}

.full-width {
    background-color: var(--lite-gray-white, #FFFFFF);
    margin: -1rem;
    padding: inherit;
}

:deep(summary) {
    padding: 1.5rem;
    text-decoration: none;
}

:deep(details > div:not(:first-child)) {
    background-color: rgb(255, 255, 255, .1);
}

.full-width .card {
    padding: 1rem 2rem;
}

.full-width .card .col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.full-width .card>.row>.col {
    padding: 1.5rem;
}

.address-details {
    display: flex;
    flex-direction: column;
    gap: .75rem
}

.street-address {
    color: rgb(51, 51, 51, .8);
}

.passenger {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-left: 2rem;
}

.vehicle-details>div:first-child {
    margin-bottom: 1rem;
}

.vehicle-details .row .col {
    margin-bottom: 0px;
    flex-direction: row;
    gap: .75rem;
    align-items: center;
}

.car-preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.car-preview .row {
    align-items: center;
    justify-content: space-evenly;
}

.pill-button span {
    display: contents;
}

.open-maps {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.open-maps>div[class^="col"] {
    justify-content: center;
    margin: 0.5rem;
}
</style>