<template>
    <div class="h2 reg">Access Card</div>
    <hr />
    <CardDiv>
        <RowDiv>
            <div class="col">
                <div class="b1 semi">Access Card Number</div>
                <InputComponent v-model="accessCard" type="text" placeholder="Access Card number" className="white"
                    @update:modelValue="validateCard" :valid="isValid" :validityMessage="validityMessage" />
            </div>
        </RowDiv>
        <RowDiv>
            <SelectableButton class="light borderless small" @click="saveCard">
                <div class="bu3-reg">Update access card</div>
            </SelectableButton>
        </RowDiv>
    </CardDiv>
</template>
<script>
import { getAuth } from '@/utils/functions';
import { mapState, mapActions } from 'vuex';

export default {
    computed: {
        ...mapState(['profile'])
    },
    data() {
        return {
            accessCard: '',
            isValid: true,
            validityMessage: '',
        }
    },
    watch: {
        profile: {
            handler(newVal) {
                if (!newVal) return;
                this.accessCard = newVal.access_card;
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        validateCard() {
            if (this.accessCard) {
                this.$axios.post(`${this.$env.API_HOST}/user/accesscard/verify`,
                    { access_card: this.accessCard },
                    { headers: { 'Authorization': `Bearer ${getAuth()}` } }
                ).then(response => {
                    if (response.status === 200 && response.data) {
                        this.isValid = (response.data.status === 'available');
                        this.validityMessage = response.data.message;
                    }
                })
            }
        },
        saveCard() {
            if (this.accessCard && this.isValid) {
                this.$axios.put(`${this.$env.API_HOST}/user/update`,
                    { access_card: this.accessCard },
                    { headers: { 'Authorization': `Bearer ${getAuth()}` } }
                ).then(() => {
                    this.loadProfile().catch(() => {
                        console.error('Something went wrong fetching the profile');
                    })
                }).catch(error => {
                    this.$bus.on('show-toast-message', {
                        message: 'An error occurred updating the access card.',
                        error: true,
                    });
                    console.error('Error updating the access card:', error);
                })
            }
        },
        ...mapActions(['loadProfile']),
    }
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.card>.row>.col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
</style>