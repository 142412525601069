<template>
    <HeroCard>
        <div class="h0 reg">Book Trip</div>
        <div class="h3 reg">Confirm your booking details</div>
        <template #right>
            <img src="/images/resized/Hero 1.png" alt="hero cars" />
        </template>
    </HeroCard>
    <div class="container">
        <BookingCard :details="bookingDetails" @edit="handleEditBooking" />
    </div>
    <div class="container">
        <CarpoolOptions v-if="profile?.carpooling" v-show="!isConfirmed" @confirm="handleConfirm" />
        <PaymentOptions v-show="isConfirmed || !profile?.carpooling" @payment-click="handlePayment"
            @back="this.isConfirmed = false" />
    </div>
    <div v-if="isModalOpen" class="modal-overlay">
        <div class="modal">
            <div class="modal-content">
                <CardDiv>
                    <RowDiv>
                        <SvgIcon name="icon/help-avatar" class="icon-xlarge" />
                    </RowDiv>
                    <div class="h3 reg">Are you sure you want to edit?</div>
                    <p>Editing your booking will release the car from reservation.</p>
                    <p>Do you want to proceed?</p>
                    <RowDiv>
                        <SelectableButton className="light small" @click="closeModal">
                            <div class="button-light">Cancel</div>
                        </SelectableButton>
                        <SelectableButton className="light small" @click="continueEdit">
                            <div class="button-light">Proceed</div>
                        </SelectableButton>
                    </RowDiv>
                </CardDiv>
            </div>
        </div>
    </div>
</template>
<script>
import BookingCard from './booking/BookingCard.vue';
import CarpoolOptions from './booking/CarpoolOptions.vue';
import HeroCard from '@/components/HeroCard.vue';
import PaymentOptions from '@/components/PaymentOptions.vue';
import { mapMutations, mapState } from 'vuex';
import { getAuth, parseDateTime } from '@/utils/functions';

export default {
    props: ['token'],
    components: {
        BookingCard,
        CarpoolOptions,
        HeroCard,
        PaymentOptions,
    },
    computed: {
        ...mapState(['searchCriteria', 'profile']),
    },
    data() {
        return {
            bookingDetails: {},
            isConfirmed: false,
            isModalOpen: false,
        }
    },
    mounted() {
        const searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
        this.bookingDetails = this.searchCriteria || searchParams;
    },
    methods: {
        closeModal() {
            this.isModalOpen = false
        },
        continueEdit() {
            this.$router.push('/search');
        },
        handleEditBooking() {
            this.isModalOpen = true;
        },
        handleConfirm(arg) {
            this.bookingDetails.carpoolOptions = arg;
            this.isConfirmed = true;
        },
        handlePayment(payment_token) {
            const startDateTime = parseDateTime(this.bookingDetails.pickup.date, this.bookingDetails.pickup.time);
            const endDateTime = parseDateTime(this.bookingDetails.dropOff.date, this.bookingDetails.dropOff.time);

            const postParams = {
                start: startDateTime.getTime() / 1000,
                end: endDateTime.getTime() / 1000,
                pickup: this.bookingDetails.pickup.hub.id,
                drop_off: this.bookingDetails.dropOff.hub.id,
                pool_booking: this.bookingDetails.carpoolOptions.allow,
                vehicle_type: this.bookingDetails.selectedCar.id,
                vehicle_id: this.bookingDetails.selectedCar.carId,
                payment_token: payment_token,
                stops: this.bookingDetails.carpoolOptions.stops,
                via: this.bookingDetails.carpoolOptions.stops,
                passengers: this.bookingDetails.carpoolOptions.passengers,
            }
            this.$axios.post(`${this.$env.API_HOST}/booking/create`, postParams,
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                this.clearSearchCriteria();
                this.$router.push({ name: 'Home' });
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'Booking was not created.',
                    error: true,
                });
                console.error('Did not create a booking!');
            })
        },
        ...mapMutations(['clearSearchCriteria'])
    }
}
</script>
