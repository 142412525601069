<template>
    <CardDiv>
        <RowDiv class="b1 reg">
            <ColDiv class="info">
                <RowDiv>
                    <SvgIcon :name="`trip/${directionIcon}`" />
                    {{ details?.pickup.hub.name }}
                </RowDiv>
                <RowDiv>
                    <SvgIcon name="calendar/clock" />
                    {{ details?.pickup.time }} - {{ details?.dropOff.time }}
                </RowDiv>
                <RowDiv v-if="details?.via">
                    <SvgIcon name="location/pin" />
                    {{ details?.via }}
                </RowDiv>
            </ColDiv>
            <ColDiv class="seats is-right" cols="3">
                <div class="bold" v-show="seatsAvailable">{{ seatsAvailable }} seats</div>
            </ColDiv>
            <ColDiv class="buttons" cols="2">
                <SelectableButton class="light small" @click="joinPool">
                    <div class="bu3 reg">View</div>
                </SelectableButton>
            </ColDiv>
        </RowDiv>
    </CardDiv>
</template>
<script>

export default {
    props: {
        details: {
            type: [Object, null],
            required: true
        }
    },
    computed: {
        directionIcon() {
            if (this.details?.booking.pickup === this.details?.booking.drop_off) return 'return';
            return 'both-ways';
        },
        seatsAvailable() {
            const seats = (+this.details?.selectedCar.passengers - 1) - +this.details?.booking.passenger_count;
            return isNaN(seats) ? 0 : seats;
        },
    },
    methods: {
        joinPool() {
            sessionStorage.setItem('searchParams', JSON.stringify(this.details));
            this.$router.push(`/booking/join/${this.details?.booking.id}`)
        }
    }
}
</script>
<style scoped>
.card {
    padding: 1rem;
}

.card>.row {
    align-items: center;
}

.info>.row {
    align-items: center;
    gap: .5rem;
}
</style>