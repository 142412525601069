<template>
    <OnBoardingCard displayImage="/images/resized/image2.png" flexClass="flex" nextButtonName="Next" :stepIndex="2">
        <div class="h1 reg">
            Add your business credit card
        </div>
        <div class="instructions-block b2 reg">
            Your work has credit cards that you may want to link up here so do it
        </div>
        <div class="list">
            <div class="instructions">
                <div class="instructions-block b2 reg">
                    To complete this step you will need:
                </div>
            </div>
            <div class="labels-default">
                <SvgIcon name="payment/card" />
                <div class="labels-text">Business card details</div>
                <div class="what-is-this">What is this?</div>
            </div>
        </div>
        <div class="buttons">
            <SelectableButton className="light" @click="handleSkipButton">
                <div class="btn-anchor bu1-semi">No thanks, skip this step</div>
            </SelectableButton>
        </div>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapMutations } from 'vuex';
export default {
    components: {
        OnBoardingCard,
    },
    mounted() {
        this.$bus.on('onboarding-next', this.handleSubmit);
        this.setNextButtonEnabled(true);
    },
    beforeRouteLeave() {
        this.$bus.off('onboarding-next', this.handleSubmit);
    },
    methods: {
        handleSkipButton() {
            this.$router.push('/onboarding/personal-use')
        },
        handleSubmit() {
            // This is a simple "INFO" page; skippable
            this.$router.push('/onboarding/add-cards');
        },
        ...mapMutations(['setNextButtonEnabled'])
    }
}
</script>
<style scoped>
.what-is-this {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: right;
    font-size: var(--links-link-1-font-size, 1.4rem);
    font-weight: var(--links-link-1-font-weight, 400);
    text-decoration: underline;
    position: relative;
    width: 18.05rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>