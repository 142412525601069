import { createRouter, createWebHistory } from 'vue-router';
import { getAuth } from './utils/functions';

import AccessCardDelivery from './views/onboarding/AccessCardDelivery.vue';
import AddAccessCard from './views/onboarding/AddAccessCard.vue';
import AddBusiness from './views/onboarding/AddBusiness.vue';
import AllDone from './views/onboarding/AllDone.vue';
import CardsBusiness from './views/onboarding/CardsBusiness.vue';
import CardsPersonal from './views/onboarding/CardsPersonal.vue';
import CarpoolBooking from './views/booking/CarpoolBooking.vue';
import CongratulationsView from './views/onboarding/CongratulationsView.vue';
import ContactView from './views/ContactView.vue';
import CustomBooking from './views/CustomBooking.vue';
import DocumentView from './views/DocumentView.vue';
import DriverDetails from './views/onboarding/DriverDetails.vue';
import HomeView from './views/HomeView.vue';
import JoinView from './views/onboarding/JoinView.vue';
import KnowingYou from './views/onboarding/KnowingYou.vue';
import LoginView from './views/LoginView.vue';
import PersonalUse from './views/onboarding/PersonalUse.vue';
import PersonalPolicy from './views/onboarding/PersonalPolicy.vue';
import PoliciesView from './views/onboarding/PoliciesView.vue';
import ProfileView from './views/ProfileView.vue';
import ResetPassword from './views/ResetPassword.vue';
import SearchBooking from './views/SearchBooking.vue';
import VerifiedView from './views/onboarding/VerifiedView.vue';
import VerifyEmail from './views/onboarding/VerifyEmail.vue';
import ViewBooking from './views/booking/ViewBooking.vue';
import WelcomeView from './views/onboarding/WelcomeView.vue';

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/home', name: 'Home', component: HomeView },
    { path: '/documents/:page', name: 'Document', component: DocumentView, props: route => ({ page: route.params.page }) },
    { path: '/contact', name: 'Contact', component: ContactView },
    { path: '/login', name: 'Login', component: LoginView },
    { path: '/reset', name: 'Reset Password', component: ResetPassword },
    { path: '/profile', name: 'Profile', component: ProfileView },
    { path: '/profile/verified', name: 'Email verification', component: ProfileView },
    { path: '/search', name: 'Search Booking', component: SearchBooking },
    { path: '/booking', name: 'Configure Booking', component: CustomBooking },
    { path: '/booking/view/:id', name: 'View Booking', component: ViewBooking, props: route => ({ id: route.params.id })},
    { path: '/booking/join/:id', name: 'Join Booking', component: CarpoolBooking, props: route => ({ id: route.params.id })},
    { path: '/onboarding/join', name: 'Join', component: JoinView },
    { path: '/onboarding/verify-email', name: 'Verify your Email', component: VerifyEmail },
    { path: '/onboarding/verified', name: 'Email verified', component: VerifiedView },
    { path: '/onboarding/welcome', name: 'Welcome', component: WelcomeView },
    { path: '/onboarding/getting-to-know', name: 'Getting to know you', component: KnowingYou },
    { path: '/onboarding/policies', name: 'Policies', component: PoliciesView },
    { path: '/onboarding/driver-details', name: 'Driver details', component: DriverDetails },
    { path: '/onboarding/cards', name: 'Cards - Business', component: AddBusiness },
    { path: '/onboarding/add-cards', name: 'Add cards - Business', component: CardsBusiness },
    { path: '/onboarding/personal-use', name: 'Personal usage', component: PersonalUse },
    { path: '/onboarding/personal-card', name: 'Personal card', component: CardsPersonal },
    { path: '/onboarding/personal-policy', name: 'Personal Policy', component: PersonalPolicy },
    { path: '/onboarding/add-access-card', name: 'Add Access Card', component: AddAccessCard },
    { path: '/onboarding/send-access-card', name: 'Send Access Card', component: AccessCardDelivery },
    { path: '/onboarding/all-done', name: 'Almost done', component: AllDone },
    { path: '/onboarding/congratulations', name: 'Congratulations', component: CongratulationsView },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0)
    }
});

router.beforeEach((to, from, next) => {
    const auth = getAuth();
    const titlePrefix = window.config.VUE_APP_TITLE_PREFIX || 'Zilch';
    document.title = titlePrefix + (to.name ? (' > ' + to.name) : '');
    if ((to.path === '/login') && auth) {
        next({ name: 'Home' });
    } else if ((to.path === '/login') ||
        (to.path === '/reset') ||
        (to.path === '/reset/') ||
        to.path.includes('onboarding') ||
        to.path.includes('documents')
    ) {
        next();
    } else if (!auth) {
        next({ path: '/login' })
    } else {
        next()
    }
});

export default router;
