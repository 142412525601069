<template>
    <OnBoardingCard displayImage="/images/resized/mail.png">
        <div class="h1 reg">
            Verify your account to get moving
        </div>
        <div class="h4 reg">
            We've sent you an email invitation. Click the link in the email to
            continue set up.
        </div>
        <div class="b1 reg">
            Didn't receive an email? <a href="#" @click="resendEmail">Resend verification email</a>.
        </div>
        <template v-slot:footer>Need help? <a href="mailto:support@zilch.nz">Contact us</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState } from 'vuex';
export default {
    components: {
        OnBoardingCard
    },
    data() {
        return {
            link: ''
        }
    },
    computed: {
        ...mapState(['onboarding']),
    },
    mounted() {
        if (this.onboarding && this.onboarding.guid) {
            this.link = '/onboarding/verified/' + this.onboarding.guid;
        }
    },
    methods: {
        resendEmail(event) {
            event.stopPropagation();
            event.preventDefault();
            this.$axios.post(`${this.$env.API_HOST}/user/email/verify`, {
                email_address: this.onboarding.email,
                url: `${window.location.host}/onboarding/verified/`
            }).catch(error => {
                console.error("An error occurred while resending email:", error);
            });
        }
    }
}
</script>