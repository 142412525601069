<template>
    <CardDiv>
        <div class="h3 reg">Upcoming bookings</div>
        <hr />
        <div class="content">
            <template v-if="bookings?.length > 0">
                <BookingList v-for="(booking, n) in bookings" :key="n" :booking="booking" @click="handleBookingSelect(booking)"/>
            </template>
            <RowDiv v-else>
                <div class="h4 reg">You have no upcoming bookings</div>
            </RowDiv>
        </div>
    </CardDiv>
</template>
<script>
import BookingList from '../booking/BookingList.vue';

export default {
    components: {
        BookingList,
    },
    props: {
        bookings: {
            type: Array,
            required: true
        }
    },
    methods: {
        handleBookingSelect(booking) {
            sessionStorage.setItem('currentBooking', JSON.stringify(booking));
            this.$router.push(`/booking/view/${booking.id}`);
        }
    },
}
</script>
<style scoped>
hr {
    background-color: #dfdfdf;
    margin-bottom: 2rem;
}

.spread {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.content {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.content :deep(.container):first-child .card {
    background-color: #dfdfdf;
}
</style>