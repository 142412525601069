<template>
    <div :class="['header-alerts', 'container', extraClass]">
        <div class="alert-content row">
            <div class="col-1 mobile-centered" v-if="!extraClass.includes('error')">
                <SvgIcon name="icon/close" class="icon-wide" @click="handleClose" />
            </div>
            <div class="col mobile-centered">
                <slot></slot>
            </div>
            <div class="col-1 mobile-centered" v-if="link">
                <SvgIcon name="icon/right" class="icon-wide pull-right" @click="handleClick" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        extraClass: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: ''
        },
    },
    methods: {
        handleClick() {
            console.log(this.link);
        },
        handleClose() {
            this.$emit('close');
        }
    }
}
</script>
<style scoped>
.header-alerts {
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;
    border-radius: 100px;
    color: #042B2F;
    width: -webkit-fill-available;
    max-width: 120rem;
    border: 1px solid var(--color-grey, #FAFAFA);
    background: var(--lite-gray-grey-1-50percent);
}

.header-alerts.error {
    border: 1px solid var(--notification-colours-error, #CA4D4D);
    background: var(--notification-colours-error-light, #FFECEC);
    color: #872121;
}

.header-alerts.success {
    border: 1px solid var(--notification-colours-correct, #0AAF6C);
    background: var(--notification-colours-correct-light, #D6F6BC);
}

.alert-content {
    display: flex;
    align-items: center;
    gap: 25px;
    align-self: stretch;
}

.row {
    margin: 0px;
}

.col,
[class*=" col-"],
[class*=col-] {
    margin: 0px;
}

@media (max-width: 600px) {
    .mobile-centered {
        display: contents;
    }
}
</style>