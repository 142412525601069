<template>
    <OnBoardingCard displayImage="/images/resized/car0.png">
        <div class="card-alerts" v-if="isTokenExpired">
            <SvgIcon name="alert/notification" class="icon-wide" />
            <div class="alert">
                Your verification token has expired. Please enter your email address and we
                will send you another one.
            </div>
        </div>
        <div class="h1 reg">
            Sign up to Zilch to
            <br />
            get moving
        </div>
        <div class="form-field">
            <div class="form-field-label b1 reg">Email address</div>
            <InputComponent icon-src="email/email" v-model="emailValue" type="text"
                placeholder="Enter your registered email" :validation="['required', 'email']"
                @validation="handleKeyPress" @enter="signUp"/>
        </div>
        <div class="buttons">
            <SelectableButton @click="signUp" :isLoading="isLoading">
                <div class="btn-anchor bu1-semi">Sign up</div>
            </SelectableButton>
        </div>
        <template v-slot:footer>Am I eligible for a Zilch account? Read our terms <a href="/documents/terms" target="_blank">here</a></template>
    </OnBoardingCard>
</template>

<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            emailValue: "",
            isValid: false,
            isLoading: false,
        };
    },
    components: {
        OnBoardingCard,
    },
    computed: {
        ...mapState(['isTokenExpired']),
    },
    methods: {
        signUp() {
            if (this.isValid && !this.isLoading) {
                this.isLoading = true;
                this.setOnboarding({ key: 'email', value: this.emailValue });
                this.$axios.post(`${this.$env.API_HOST}/user/email/verify`, {
                    email_address: this.emailValue,
                    url: `${window.location.host}/onboarding/verified/`
                }).then(resp => {
                    if (resp.status === 200) {
                        this.isLoading = false;
                        this.setTokenExpired(false);
                        this.$router.push('/onboarding/verify-email');
                    } else {
                        console.error("Login failed");
                    }
                }).catch(error => {
                    this.isLoading = false;
                    console.error("An error occurred while logging in:", error);
                });
            }
        },
        handleKeyPress(message) {
            this.isValid = !message;
        },
        ...mapMutations(['setOnboarding', 'setTokenExpired']),
    },
};
</script>

<style scoped>
.join-1-b-100-300-sign-up {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.onboarding-cards-log-in {
    background: var(--lite-gray-white, #FFFFFF);
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 46.125rem;
    position: relative;
    overflow: hidden;
}

.card-alerts {
    background: var(--notification-colours-error-light, #FFECEC);
    border-radius: 0.8rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.alert {
    color: var(--notification-colours-dark-error, #872121);
    text-align: left;
    font-size: var(--buttons-body-1-font-size, 1.6rem);
    line-height: var(--buttons-body-1-line-height, 2.2rem);
    font-weight: var(--buttons-body-1-font-weight, 400);
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>