<template>
    <CardDiv class="carpool-card">
        <div class="h3 reg">Customise carpool</div>
        <RowDiv >
            <div class="col-6">
                <CardDiv class="allow-carpool">
                    <div>
                        <div class="b1 semi">
                            <SvgIcon name="location/route" />
                            Carpool
                        </div>
                        <div class="b2 reg">Allow others from my company to book a seat</div>
                    </div>
                    <ToggleButton className="light small" @change="handleCarpoolToggle">
                        <template #left>Yes</template>
                        <template #right>No</template>
                    </ToggleButton>
                </CardDiv>
                <CardDiv v-show="isCarpoolEnabled">
                    <div class="card-header">
                        <div class="b1 semi">Invite passengers</div>
                        <SelectableButton class="light borderless small" v-show="false">
                            <div class="bu3-reg">
                                <SvgIcon name="icon/send" />
                                Invite link
                            </div>
                        </SelectableButton>
                    </div>
                    <div class="list">
                        <div class="passenger">
                            <SvgIcon name="people/user" />
                            {{ this.profile?.first_name }} {{ this.profile?.last_name }} (you)
                        </div>
                        <div class="passenger" v-for="n in passengerCount" :key="n">
                            <SvgIcon name="people/user" />
                            <InputComponent v-model="passengers[n-1]" type="text" placeholder="Email address"
                                className="white" icon-src="email/email" />
                        </div>
                    </div>
                </CardDiv>
            </div>
            <div class="col" v-show="isCarpoolEnabled">
                <CardDiv class="allow-carpool">
                    <div>
                        <div class="b1 semi">
                            <SvgIcon name="location/route" />
                            Drop offs
                        </div>
                        <div class="b2 reg">Allow carpool passengers to create a drop off point
                        </div>
                    </div>
                    <ToggleButton className="light small" @change="handleDropOffToggle">
                        <template #left>Yes</template>
                        <template #right>No</template>
                    </ToggleButton>
                </CardDiv>
                <CardDiv v-show="isCarpoolEnabled && isDropOffEnabled">
                    <div class="stops-header">
                        <div class="b1 semi">Via</div>
                        <div class="b2 reg">Add the location you'll be visiting (e.g. conference venue)</div>
                    </div>
                    <div>
                        <InputComponent v-model="newDropOff" type="text" placeholder="Drop off address"
                            className="white" icon-src="location/pin" />
                    </div>
                </CardDiv>
            </div>
        </RowDiv>
        <div class="col is-right">
            <SelectableButton @click="handleConfirm">
                <div class="bu1-semi">Confirm booking</div>
                <SvgIcon name="trip/arrow-right" />
            </SelectableButton>
        </div>
        <hr />
        <div class="b3 reg footer"><a href="#">How do Carpools work?</a></div>
    </CardDiv>
</template>
<script>
import ToggleButton from '@/components/ToggleButton.vue';
import { mapState } from 'vuex';

export default {
    components: {
        ToggleButton,
    },
    computed: {
        ...mapState(['searchCriteria', 'profile']),
    },
    data() {
        return {
            carpoolData: {},
            isCarpoolEnabled: true,
            isDropOffEnabled: true,
            passengers: [],
            passengerCount: 0,
            newPassenger: '',
            dropOffs: [],
            newDropOff: ''
        }
    },
    mounted() {
        const sessionCriteria = this.searchCriteria || JSON.parse(sessionStorage.getItem('searchParams'));
        if (sessionCriteria?.selectedCar) this.passengerCount = +sessionCriteria.selectedCar.passengers - 1;
        for (let i = 0; i < this.passengerCount; i++) {
            this.passengers.push('');
        }
    },
    emits: ['confirm'],
    methods: {
        handleConfirm() {
            const passengers = this.passengers.filter(passenger => passenger);
            this.carpoolData = {
                allow: this.isCarpoolEnabled,
                passengers,
                allowStops: this.isDropOffEnabled,
                stops: this.newDropOff,
             }
            this.$emit('confirm', this.carpoolData);
        },
        handleCarpoolToggle(arg) {
            this.isCarpoolEnabled = arg;
        },
        handleDropOffToggle(arg) {
            this.isDropOffEnabled = arg;
        },
    }
}
</script>
<style scoped>
.carpool-card {
    padding: 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

h3 {
    margin-bottom: 5rem;
}

div.card .card {
    background-color: #fafafa;
    border-radius: .8rem;
    margin-bottom: 1.5rem;
    padding: 2.5rem;
}

.allow-carpool {
    display: flex;
    justify-content: space-between;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 1.5rem;
}

.stops-header,
.b1 .semi {
    padding-bottom: 1.5rem;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.passenger {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

hr {
    background-color: #dfdfdf;
}

.footer {
    height: 5rem;
}
</style>