<template>
    <div>
        <div ref="qrcode"></div> <!-- QR code will be generated here -->
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
            default: 'https://account.zilch.nz'
        }
    },
    mounted() {
        this.generateQRCode();
    },
    methods: {
        generateQRCode() {
            // eslint-disable-next-line
            const QRImage = new QRCode(this.$refs.qrcode, {
                text: this.text,
                width: 128,
                height: 128,
                // eslint-disable-next-line
                correctLevel : QRCode.CorrectLevel.L
            });
        }
    }
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>