<template>
    <div class="buttons-cards-progress-onboard" v-if="buttonText">
        <div class="progress">
            <div class="step-1" :class="{ 'done': this.stepIndex > 0 }"></div>
            <div class="step-2" :class="{ 'done': this.stepIndex > 1 }"></div>
            <div class="step-3" :class="{ 'done': this.stepIndex > 2 }"></div>
            <div class="step-4" :class="{ 'done': this.stepIndex > 3 }"></div>
        </div>
        <div :class="['buttons-cards-onboard-next-d', { 'disabled': !this.nextButtonEnabled }]">
            <div class="labels-steps" v-if="(this.$route.path === '/onboarding/welcome')">
                <div class="frame-2">
                    <SvgIcon name="icon/arrow-right" class="icon-wide" />
                    <SvgIcon name="icon/arrow-right" class="icon-wide" />
                    <SvgIcon name="icon/arrow-right" class="icon-wide" />
                    <SvgIcon name="icon/arrow-right" class="icon-wide" />
                </div>
            </div>

            <LargeCardButton @click="handleClick" :buttonText="buttonText" :iconName="iconImage" />
        </div>
    </div>
</template>

<script>
import LargeCardButton from './LargeCardButton.vue';
import { mapState } from 'vuex';
export default {
    components: {
        LargeCardButton,
    },
    props: {
        buttonText: String,
        iconName: String,
        stepIndex: Number,
    },
    computed: {
        iconImage() {
            return this.nextButtonEnabled ? this.iconName : 'icon/lock';
        },
        ...mapState(['nextButtonEnabled'])
    },
    methods: {
        handleClick(eventData) {
            if (this.nextButtonEnabled) this.$bus.emit('onboarding-next', eventData);
        }
    }
};
</script>

<style scoped>
.buttons-cards-progress-onboard {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    color: var(--lite-gray-white, #FFFFFF);
}

.labels-steps {
    flex-shrink: 0;
    width: 12.6rem;
    height: 2.4rem;
    position: relative;
}

.frame-2 {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0rem;
    top: 0rem;
}

.progress {
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.step-1 {
    background: var(--dark-grey-grey-10, #3e4446);
    flex: 1;
    height: 1.1875rem;
    position: relative;
}

.step-2 {
    background: var(--dark-grey-grey-10, #3e4446);
    flex: 1;
    height: 1.1875rem;
    position: relative;
}

.step-3 {
    background: var(--dark-grey-grey-10, #3e4446);
    flex: 1;
    height: 1.1875rem;
    position: relative;
}

.step-4 {
    background: var(--dark-grey-grey-10, #3e4446);
    flex: 1;
    height: 1.1875rem;
    position: relative;
}

.done {
    background: var(--color-primary, #A4EA6E);
}

.buttons-cards-onboard-next-d {
    background: var(--bg-secondary-color, #042B2F);
    border-style: solid;
    border-color: var(--lite-gray-grey-2, #edefef);
    border-width: 0rem 0rem 0rem 0rem;
    padding: 5rem 4.5rem 4.5rem 4.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.buttons-cards-onboard-next-d :deep(a) {
    color: var(--lite-gray-white, #FFFFFF);
}

.buttons-cards-onboard-next-d:hover,
.buttons-cards-onboard-next-d:hover :deep(a) {
    color: var(--font-color, #042B2F);
    background: var(--color-primary, #A4EA6E);
}

:deep(.disabled),
:deep(.disabled a) {
    background: var(--lite-gray-grey-5, #dfdfdf);
    border-style: solid;
    border-color: transparent;
    color: var(--dark-grey-grey-9-links, #5C6163);
}
</style>