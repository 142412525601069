<template>
    <CardDiv>
        <RowDiv >
            <div class="col">
                <div class="b1 semi">Search criteria</div>
                <DetailCard v-if="searchCriteria?.pickup" :data="searchCriteria.pickup">
                    <template #pill-name>Pick up</template>
                </DetailCard>
                <DetailCard v-if="searchCriteria?.dropOff" :data="searchCriteria.dropOff">
                    <template #pill-name>Drop off</template>
                </DetailCard>
            </div>
        </RowDiv>
        <SelectableButton class="dark" v-show="displayFindButton && !isSearching" @click="handleFindClick">
            <div class="btn-anchor bu1-semi">Find available options</div>
        </SelectableButton>
        <SelectableButton class="light" v-show="isSearching" @click="handleEditClick">
            <div class="btn-anchor bu1-semi">Edit</div>
        </SelectableButton>
    </CardDiv>
</template>
<script>
import DetailCard from '@/components/DetailCard.vue';

import { mapState } from 'vuex';

export default {
    props: {
        isSearching: Boolean
    },
    components: {
        DetailCard,
    },
    computed: {
        displayFindButton() {
            return this.searchCriteria?.pickup?.date && this.searchCriteria?.pickup?.time &&
                this.searchCriteria?.dropOff?.date && this.searchCriteria?.dropOff?.time;
        },
        ...mapState(['searchCriteria']),
    },
    methods: {
        handleFindClick() {
            sessionStorage.setItem('searchParams', JSON.stringify(this.searchCriteria));
            this.$bus.emit('search-now');
        },
        handleEditClick() {
            this.$bus.emit('reset-search');
        }
    }
}
</script>
<style scoped>
.card {
    display: flex;
    padding: 45px;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--color-grey, #FAFAFA);
}

.card > .row > .col {
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
}
</style>