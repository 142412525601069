<template>
    <CardDiv>
        <RowDiv >
            <div class="h3 reg">Complete booking</div>
        </RowDiv>
        <RowDiv >
            <div class="col-6" v-show="businessPayments.paymentMethods?.length > 0">
                <CardDiv class="payment-panel">
                    <RowDiv >
                        <div class="col payment-header">
                            <div class="h3 reg">Business</div>
                        </div>
                    </RowDiv>
                    <PaymentCard v-for="card in businessPayments.paymentMethods" :key="card.id" logoClass="asb-logo"
                        @click="handleClick(card.id)" :selected="selected === card.id">
                        <template #image>
                            <img src="/images/payment_placeholder.png" alt="logo" />
                        </template>
                        <div class="h3 reg">{{ card.label }}</div>
                        <div class="b2 reg">{{ card.paymentProvider }}</div>
                    </PaymentCard>
                </CardDiv>
                <CardDiv class="confirm-panel" v-show="selected">
                    <SelectableButton class="dark" @click="handleConfirm">
                        <div class="bu1-reg">Confirm your booking</div>
                    </SelectableButton>
                </CardDiv>
            </div>
            <div class="col-6" v-show="personalPayments.paymentMethods?.length > 0">
                <CardDiv class="payment-panel">
                    <RowDiv >
                        <div class="col payment-header">
                            <div class="h3 reg">Personal</div>
                            <div class="b2 reg">Edit</div>
                        </div>
                    </RowDiv>
                    <PaymentCard v-for="card in personalPayments.paymentMethods" :key="card.id" @click="handleClick(card.id)"
                        :selected="selected === card.id">
                        <template #image>
                            <img src="/images/mastercard.png" alt="logo" />
                        </template>
                        <div class="h3 reg">{{ card.label }}</div>
                        <div class="b2 reg">{{ card.cardHolder }}</div>
                        <div class="b2 reg">{{ card.paymentProvider }} ****{{ card.cardSuffix }}</div>
                    </PaymentCard>
                </CardDiv>
                <CardDiv class="confirm-panel" v-show="selected">
                    <SelectableButton class="dark" @click="handleConfirm">
                        <div class="bu1-reg">Confirm your booking</div>
                    </SelectableButton>
                </CardDiv>
            </div>
        </RowDiv>
        <RowDiv  v-if="profile?.carpooling">
            <SelectableButton @click="this.$emit('back')">
                <SvgIcon name="trip/arrow-left" />
                <div class="bu1 reg">Back to carpool options</div>
            </SelectableButton>
        </RowDiv>

        <hr />
        <div class="b3 reg footer"><a href="/documents/terms" target="_blank">Terms and conditions</a></div>
    </CardDiv>
</template>
<script>
import PaymentCard from '@/components/PaymentCard.vue';
import { getAuth } from '@/utils/functions';
import { mapState } from 'vuex';

export default {
    components: {
        PaymentCard,
    },
    computed: {
        ...mapState(['profile'])
    },
    data() {
        return {
            selected: null,
            businessPayments: [],
            personalPayments: [],
        }
    },
    mounted() {
        this.$axios.get(`${this.$env.API_HOST}/user/paymentmethods`, {
            headers: { 'Authorization': `Bearer ${getAuth()}` }
        }).then(response => {
            [this.businessPayments, this.personalPayments] = response.data;
        }).catch(() => { })
    },
    emits: ['payment-click'],
    methods: {
        handleClick(method) {
            this.selected = method;
        },
        handleConfirm() {
            this.$emit('payment-click', this.selected);
        }
    }
}
</script>
<style scoped>
.container > .card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* :deep(.asb-logo) {
    background: var(--Company-Settings-Gradient, linear-gradient(243deg, #FFC20E 0%, #D4A10B 100%));
} */

hr {
    background-color: #dfdfdf;
    margin-top: 5rem;
}

.footer {
    height: 5rem;
}

.payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-panel {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

:deep(.card) {
    padding: 3.3rem 2.5rem 4.5rem;
    background-color: #fafafa;
}

.card.confirm-panel {
    padding-top: 0;
}
</style>