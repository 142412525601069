import './styles/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import eventBus from './eventBus';
import axios from 'axios';
import ENV from './env';
import stripe from 'stripe';
import GlobalComponents from './global-components';

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const app = createApp(App);

app.config.globalProperties.$bus = eventBus
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$env = ENV;
app.config.globalProperties.$stripe = stripe(ENV.STRIPE_SECRET_KEY);

window.addEventListener('resize', () => {
    store.dispatch('updateIsMobile');
});

app.use(router).use(store).use(GlobalComponents).mount('#app');

// Register service worker for update notifications
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);

            // Listen for updates to the service worker
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // New update is available
                            console.log('New content available; please refresh.');
                            showUpdateNotification();
                        } else {
                            // Content is now available offline
                            console.log('Content cached for offline use.');
                        }
                    }
                };
            };
        }).catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
    });
}

function showUpdateNotification() {
    app.config.globalProperties.$bus.emit('new-version-available');
}