<template>
    <HeroCard>
        <div class="h0 reg">Book Trip</div>
        <div class="h3 reg">Confirm your booking details</div>
        <template #right>
            <img src="/images/resized/Hero 1.png" alt="hero cars" />
        </template>
    </HeroCard>
    <div class="container">
        <BookingCard :details="bookingDetails" />
        <CardDiv>
            <RowDiv class="is-center">
                <SelectableButton className="light" @click="this.$router.go(-1)">
                    <div class="button-light">Cancel seat</div>
                </SelectableButton>
                <SelectableButton className="dark" @click="confirmBooking">
                    <div class="button-light">Confirm seat</div>
                </SelectableButton>
            </RowDiv>
        </CardDiv>
    </div>
</template>
<script>
import HeroCard from '@/components/HeroCard.vue';
import BookingCard from './BookingCard.vue';
import { mapState } from 'vuex';
import { getAuth } from '@/utils/functions';

export default {
    props: ['id'],
    components: {
        HeroCard,
        BookingCard,
    },
    computed: {
        ...mapState(['searchCriteria', 'profile']),
    },
    data() {
        return {
            bookingDetails: {},
            isConfirmed: false,
            isModalOpen: false,
        }
    },
    beforeMount() {
        this.fetchBooking(this.id);
    },
    mounted() {
        const searchParams = JSON.parse(sessionStorage.getItem('searchParams'));
        this.bookingDetails = this.searchCriteria || searchParams;
    },
    methods: {
        fetchBooking(booking_id) {
            this.$axios.get(`${this.$env.API_HOST}/booking/details`, {
                params: { booking_id },
                headers: { 'Authorization': `Bearer ${getAuth()}` }
            }).then(response => {
                console.log('fetched', response);
            }).catch(() => { console.error('Cannot fetch carpool details') });
        },
        confirmBooking() {
            this.$axios.post(`${this.$env.API_HOST}/booking/join`, { booking_id: this.bookingDetails.booking.id },
                { headers: { 'Authorization': `Bearer ${getAuth()}` } }
            ).then(() => {
                sessionStorage.removeItem('searchParams');
                this.$router.push({ name: 'Home' });
            }).catch(() => {
                this.$bus.on('show-toast-message', {
                    message: 'Booking was not created.',
                    error: true,
                });
                console.error('Did not create a booking!');
            })
        }
    }
}
</script>
<style scoped>
@media (max-width: 600px) {
    .button+.button {
        margin-left: auto;
    }
}
</style>