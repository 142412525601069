<template>
    <CardDiv>
        <div class="onboarding-stack print-body">
            <div class="heading">
                <SvgIcon name="icon/print" class="print-button" @click="handlePrint" />
                <SvgIcon name="icon/export" class="print-button" />
            </div>
            <div class="contents">
                <div ref="scrollingDiv" @scroll="handleScroll">
                    <p v-html="source"></p>
                </div>
            </div>
        </div>
    </CardDiv>
</template>

<script>
export default {
    props: ['page'],
    data() {
        return {
            source: '',
            atBottom: false,
        }
    },
    emits: ['accept', 'close'],
    mounted() {
        this.fetchDocument();
    },
    methods: {
        fetchDocument() {
            this.$axios.get(`${this.$env.API_HOST}/referenceData/legal/${this.page}`).then(response => {
                this.source = response.data
            })
        },
        handleScroll() {
            const scrollingDiv = this.$refs.scrollingDiv;
            if (scrollingDiv.scrollTop + scrollingDiv.clientHeight >= scrollingDiv.scrollHeight) {
                this.atBottom = true;
            }
        },
        handlePrint() {
            // Get the content of the onboarding-stack div
            const printContents = document.querySelector('.onboarding-stack.print-body').innerHTML;

            // Open a new window for printing
            const printWindow = window.open('', '', 'height=600,width=800');
            printWindow.document.write('<html><head>');
            // Optionally add your CSS here
            const globalStyles = Array.from(document.styleSheets)
                .map(sheet => {
                    try {
                        return Array.from(sheet.cssRules)
                            .map(rule => rule.cssText)
                            .join('\n');
                    } catch (err) {
                        return ''; // In case of CORS issues with external stylesheets
                    }
                })
                .join('\n');

            printWindow.document.write('<style>' + globalStyles + '</style>');

            printWindow.document.write(`
        <style>
            .heading {
                display: flex;
                flex-direction: row;
                gap: 0.625rem;
                align-items: center;
                justify-content: flex-start;
                align-self: stretch;
                flex-shrink: 0;
                position: relative;
            }
            .contents {
                background: var(--lite-gray-grey-2, #edefef);
                white-space: pre-wrap;
                color: var(--font-color, #042B2F);
                font-size: var(--default-font-size, 1.6rem);
                height: max-content !important;
                overflow: unset !important;

            }
            @media print {
                div.icon.print, div.icon.print-button {
                    display: none;
                }
            }
        </style>
    `);

            printWindow.document.write('</head><body>');
            printWindow.document.write(printContents);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },
    }
};
</script>
<style scoped>
.list,
.list2 {
    border-style: solid;
    border-color: var(--lite-gray-grey-5, #dfdfdf);
    border-width: 0.1rem 0rem 0rem 0rem;
}

.list2 {
    padding: 2.5rem 0rem 2.5rem 0rem;
    border-bottom-width: 0.1rem;
}

.onboarding-stack {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 75vh;
    max-width: 75vh;
}

.heading {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.contents {
    background: var(--lite-gray-grey-2, #edefef);
    align-self: stretch;
    flex: 1;
    position: relative;
    white-space: pre-wrap;
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--default-font-size, 1.6rem);
    overflow-y: auto;
    height: 45vh;
    padding: 2.5rem;
    padding-right: 0.5rem;
}

.contents>div {
    max-height: 100%;
    overflow-y: auto;
    padding-right: 2.5rem;
}

.print-button {
    padding-right: 1.625rem;
}

@media print {
    div.icon.print {
        display: none;
    }

    div.icon.print-button {
        display: none;
    }

}
</style>